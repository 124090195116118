import { ApiResponse } from "./generalResponse";

export interface iSearchResultsResponse extends ApiResponse {
  // You could add errName, errMsg, etc., if your server returns them
  data: ContextSearchResults;
}

export interface iSearchResultsState {
  searchResults: iSearchResultsResponse | null
}

export interface ContextSearchResults {
  searchedContext: ContextName,
  results: ContextSearchResult[]
}

interface ReferenceValue {
  amount: number;
  unit: string;
  systemBoundaries: string;
}

interface FullContext {
  contextName: string;
  fullName: string;
  shortName: string;
  className: string;
  centralProductClassification: string;
  hs2017Classification: string;
  isicClassification: string;
  group: string;
}

export interface ContextSearchResult {
  sid: string;
  name: string;
  contextName: string;
  database: string;
  type: string;
  description: string;
  referenceValue?: ReferenceValue;
  fullContext?: FullContext;
}

export enum ContextName {
    NONE = "_",
    GLOBAL = ".",
    ENERGY = ".energy",
    LOGISTICS = ".logistics",
    MATERIALS = ".materials",
    PRODUCTION = ".production",
    ELECTRONICS = ".electronics",
    PACKAGING = ".logistics.packaging",
    TRANSPORTATION = ".logistics.transportation",
    COMPOSITES = ".materials.composites",
    CONSTRUCTION = ".materials.construction",
    ELEMENTS = ".materials.elements",
    METALS = ".materials.metals",
    POLYMERS = ".materials.polymers",
    SINTERS = ".materials.sinters",
    EMPLOYEES = ".production.employees",
    EQUIPMENT = ".production.equipment",
    MANUFACTURING = ".production.manufacturing"
  }
  