import React, { FC, useState } from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mui/material'
import { useDispatch } from 'react-redux'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import { ModalYesNo } from './AssemblerModals'
import { sagaDeleteAssemblyAction } from 'saga/actions/assembler'

// Adjust the interface if necessary to match your shape of AssemblyListing
export interface AssemblyListing {
  id: string
  name: string
  customerId: string
  organizationId: number
  sharedWithOrganizations: string[]
  lifecycleStage: number
  assemblyModel?: any // or your actual type for CalculationProcessStep[]
  createdOn: Date
  lastUpdatedOn: Date
}

interface CollapsibleAssemblyListProps {
  data: AssemblyListing[]
}

export const CollapsibleAssemblyList: FC<CollapsibleAssemblyListProps> = ({
  data,
}) => {
  const dispatch = useDispatch()

  // Track whether the modal is open and the current item to delete
  const [isModalOpen, setModalOpen] = useState(false)
  const [assemblyIdToDelete, setAssemblyIdToDelete] = useState<string | null>(
    null
  )
  const navigate = useNavigate()
  const handleEditClick = (assemblyId: string) => {
    // Navigate with the ID as a query param
    navigate(`/toolbox/assembler?assemblyId=${assemblyId}`)
  }

  const handleOpenDeleteModal = (id: string) => {
    setAssemblyIdToDelete(id)
    setModalOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setAssemblyIdToDelete(null)
    setModalOpen(false)
  }

  const handleConfirmDelete = () => {
    if (assemblyIdToDelete) {
      dispatch(
        sagaDeleteAssemblyAction({
          uuid: assemblyIdToDelete,
        })
      )
    }
    // Close the modal either way
    handleCloseDeleteModal()
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Shared With Organizations</TableCell>
            <TableCell>Created On</TableCell>
            <TableCell>Last Updated On</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.sharedWithOrganizations.join(', ')}</TableCell>
              <TableCell>
                {new Date(item.createdOn).toLocaleDateString()}
              </TableCell>
              <TableCell>
                {new Date(item.lastUpdatedOn).toLocaleDateString()}
              </TableCell>
              <TableCell>
                <IconButton onClick={() => handleEditClick(item.id)}>
                  <EditIcon color="primary" />
                </IconButton>
                <IconButton
                  onClick={() => handleOpenDeleteModal(item.id)}
                  // onClick={() => { /* Alternatively: pass item into a function that sets up the modal state */ }}
                >
                  <DeleteIcon color="error" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/** ModalYesNo for confirmation */}
      <ModalYesNo
        title="assembler.deleteTitle" // or a direct string if you prefer
        message="assembler.deleteMessage"
        yesOption={handleConfirmDelete}
        open={isModalOpen}
        handleClose={handleCloseDeleteModal}
      />
    </>
  )
}
