import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { iAssemblyModel, iAssemblyModelState, CalculationProcessStep, Co2ReferenceObject, AssemblyCo2ReferenceObject } from 'store/types/assembler';
import assemblerBuild from '../../config/services/requests/mock/assemblerBuild.json'; // ✅ Import the mock JSON
import { ContextName } from 'store/types/searchResults';

export const initialState: iAssemblyModelState = {
  //assemblyModel: assemblerBuild as iAssemblyModel,
  assemblyModel: {
    id: '',
    name: '',
    category: '',
    assemblyModel: {
    calculationProcessChain: []
  }
  },
};

export const assemblyModelSlice = createSlice({
  name: 'assemblyModel',
  initialState,
  reducers: {
    // Replace the entire assembly state
    setassemblyModel: (state, action: PayloadAction<iAssemblyModel>) => {
      const incoming = action.payload;
      console.log('incoming', incoming)
      // 1) Set the top-level name
      state.assemblyModel.name = incoming.name ?? '';
      state.assemblyModel.id = incoming.id ?? '';
    
      // 2) Overwrite the existing chain
      state.assemblyModel.assemblyModel =
        incoming?.assemblyModel ?? [];

      console.log('model in the store', state.assemblyModel)
    },

    /**
     * 1) ADD a new calculation step
     * Payload can include name, or any data you want for the new step
     */
    addCalculationProcessStep: (state, action: PayloadAction<{ stepName: string }>) => {
      // Create a new step object
      const newStep: CalculationProcessStep = {
        name: action.payload.stepName,
        position: state.assemblyModel.assemblyModel.calculationProcessChain.length + 1,
        subAssemblies: [],
        sustamizeTargets: {
          materials: {},
          logistics: {},
          production: {},
        },
        customTargets: {
          materials: {},
          logistics: {},
          production: {},
        },
        // Because your CalculationProcessStep extends BaseEmission, 
        // you could optionally initialize emissions here, etc.
      };

      // Add the new step to the array
      state.assemblyModel.assemblyModel.calculationProcessChain.push(newStep);
    },

    renameCalculationProcessStep: (
      state,
      action: PayloadAction<{ stepIndex: number; newName: string }>
    ) => {
      const { stepIndex, newName } = action.payload
      const step = state.assemblyModel.assemblyModel.calculationProcessChain[stepIndex]
      if (step) {
        step.name = newName
      }
    },

    /**
     * 2) DELETE a calculation step by index or name
     * Here we do it by index. If you prefer by ID, 
     * adapt the payload and filter the array accordingly.
     */
    deleteCalculationProcessStep: (state, action: PayloadAction<number>) => {
      const stepIndex = action.payload;
      // Filter out the step at stepIndex
      state.assemblyModel.assemblyModel.calculationProcessChain = state.assemblyModel.assemblyModel.calculationProcessChain.filter(
        (_step, idx) => idx !== stepIndex
      );
      // If you need to reassign .position, do so here
      // e.g., re-index them 1..N
      state.assemblyModel.assemblyModel.calculationProcessChain.forEach((step, i) => {
        step.position = i + 1;
      });
    },
    addCategoryItem: (
      state,
      action: PayloadAction<{
        stepIndex: number;
        category: 'materials' | 'logistics' | 'production';
        subCategory: string;
        source: 'sustamize' | 'collector' | 'custom';
        item: AssemblyCo2ReferenceObject;
      }>
    ) => {
      const { stepIndex, category, subCategory, source, item } = action.payload;
      const step = state.assemblyModel.assemblyModel.calculationProcessChain[stepIndex];
      if (!step) return;
    
      // 1) Decide if we push into sustamizeTargets or customTargets:
      let targets =
        source === 'sustamize' || source === 'collector'
          ? step.sustamizeTargets[category]
          : step.customTargets[category];
    
      // 2) If `targets` is undefined, initialize it, **and reassign**:
      if (!targets) {
        if (source === 'sustamize' || source === 'collector') {
          step.sustamizeTargets[category] = {};
          targets = step.sustamizeTargets[category];
        } else {
          step.customTargets[category] = {};
          targets = step.customTargets[category];
        }
      }
    
      // 3) Now safely cast to a string->Co2ReferenceObject[] map
      const finalTargets = targets as Record<string, Co2ReferenceObject[]>;
      // 4) If subCategory array does not exist, create it
      if (!finalTargets[subCategory]) {
        finalTargets[subCategory] = [];
      }
      let finalItem: Partial<Co2ReferenceObject> = {}
      // 5) Build the final item (stripping any fields you don’t need)
      if (source === 'sustamize') {
        finalItem = {
        sid: item.sid,
        context: item.context,
        emissionType: item.emissionType,
        params: item.params,
        // etc. Add any fields if you want them stored
      };
    }
    else if (source === 'custom' && subCategory !== 'manufacturing') {
      // Example: remove `countryCode` from item.params
      const customParams = { ...item.params } as Record<string, any>
      delete customParams.countryCode // remove it if present; no error
      finalItem = {
        name: item.name,
        comment: item.comment,
        context: item.context,
        emissionType: item.emissionType,
        unit: item.unit,
        params: customParams,
      }
    } else if (source === 'custom' && subCategory === 'manufacturing'){
      item?.energy?.forEach((energyItem: any) => {

          delete energyItem.energyType

      })
      finalItem = {
        name: item.name,
        comment: item.comment,
        baseParams: item.baseParams,
        employees: item.employees,
        equipment: item.equipment,
        energy: item.energy
      }
    }
      // 6) Push the new item
      if (source === 'custom' && subCategory === 'manufacturing') {
        step.customTargets.production = finalItem as any;
      } else {
        finalTargets[subCategory].push(finalItem as Co2ReferenceObject);
      }

      console.log('itemToPush', finalItem);
      console.warn('finalTargets', finalTargets);
    },

    /**
     * REMOVE a category item from a given step
     */
    removeCategoryItem: (
      state,
      action: PayloadAction<{
        stepIndex: number;
        category: 'materials' | 'logistics' | 'production';
        subCategory: string;
        source: 'sustamize' | 'collector' | 'custom';
        itemIndex: number; // position in the subCategory array
      }>
    ) => {
      const { stepIndex, category, subCategory, source, itemIndex } = action.payload;
      const step = state.assemblyModel.assemblyModel.calculationProcessChain[stepIndex];
      if (!step) return;
      console.log(step, category, subCategory, source, itemIndex)
      const targets =
        source === 'sustamize' || source === 'collector'
          ? step.sustamizeTargets[category]
          : step.customTargets[category];

          if (category === 'production' && source === 'custom' && subCategory === 'productionItem') {
            // Clear the entire custom production object
            // (since we can't just splice from an array that doesn't exist)
            step.customTargets.production = {}
            return
          }

      const finalTargets = targets as Record<string, Co2ReferenceObject[]>;

          if (!finalTargets[subCategory]) {return};
          finalTargets[subCategory].splice(itemIndex, 1);
    },

    updateCategoryItem: (
      state,
      action: PayloadAction<{
        stepIndex: number;
        category: 'materials' | 'logistics' | 'production';
        subCategory: string;
        source: 'sustamize' | 'collector' | 'custom';
        itemIndex: number; // which item to update
        item: AssemblyCo2ReferenceObject; // the updated item
      }>
    ) => {
      const {
        stepIndex,
        category,
        subCategory,
        source,
        itemIndex,
        item,
      } = action.payload;

      const step = state.assemblyModel.assemblyModel.calculationProcessChain[stepIndex];
      if (!step) return;

      const targets =
        source === 'sustamize' || source === 'collector'
          ? step.sustamizeTargets[category]
          : step.customTargets[category];
          const finalTargets = targets as Record<string, Co2ReferenceObject[]>;
          if (!finalTargets[subCategory] && subCategory !== 'manufacturing') return;

      // Overwrite the existing item
      let finalItem: any = {}
      if (source === 'sustamize') {
        finalItem = {
        sid: item.sid,
        context: item.context,
        emissionType: item.emissionType,
        params: item.params,
        // etc. Add any fields if you want them stored
      };
    }
    else if (source === 'custom' && subCategory !== 'manufacturing') {
      // Example: remove `countryCode` from item.params
      const customParams = { ...item.params } as Record<string, any>
      delete customParams.countryCode // remove it if present; no error
      finalItem = {
        name: item.name,
        comment: item.comment,
        context: item.context,
        emissionType: item.emissionType,
        unit: item.unit,
        params: customParams,
      }
    } else if (source === 'custom' && subCategory === 'manufacturing'){
      item?.energy?.forEach((energyItem: any) => {

        delete energyItem.energyType

    })
      item?.employees?.forEach((employeeItem: any) => {
        delete employeeItem?.energyType
        delete employeeItem?.calculatedEntityEmission
        delete employeeItem?.entityEmissionReference
        delete employeeItem?.source
      })
      item?.equipment?.forEach((equipmentItem: any) => {
        delete equipmentItem?.energyType
        delete equipmentItem?.calculatedEntityEmission
        delete equipmentItem?.entityEmissionReference
        delete equipmentItem?.source
      })
      finalItem = {
        name: item.name,
        comment: item.comment,
        baseParams: item.baseParams,
        employees: item.employees,
        equipment: item.equipment,
        energy: item.energy
      }
    }
    if (source === 'custom' && subCategory === 'manufacturing') {
      step.customTargets.production = finalItem as any;
    } else {
      finalTargets[subCategory][itemIndex] = finalItem;
    }

    },
  },
});

// Export your new actions:
export const {
  setassemblyModel,
  addCalculationProcessStep,
  deleteCalculationProcessStep,
  addCategoryItem,
  removeCategoryItem,
  updateCategoryItem,
  renameCalculationProcessStep
} = assemblyModelSlice.actions;

export default assemblyModelSlice.reducer;

export const assemblyModelReducerState = (state: RootState) => state.assemblyModel;
