import { call, put, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import apiRequests from 'config/services'
import { setSearchResults } from 'store/slices/searchResultsSlice'
import { assemblerConstants, sagaGetSearchResultsAction } from 'saga/actions/assembler'
import { iSearchResultsResponse } from 'store/types/searchResults'
import { SagaIterator } from 'redux-saga'

export function* getSearchResultsSaga(action: PayloadAction<string>): SagaIterator {
  try {
    // Extract the contextName from the action payload
    const contextName = action.payload;
    
    // Pass it to apiRequests.getSearchResults(contextName)
    // Make sure getSearchResults is a normal function (returning a Promise),
    // or if it's a saga, we call it via yield* or rework. Typically it's a Promise-based function.
    const data: iSearchResultsResponse = yield call(apiRequests.getSearchResults, contextName);

    console.warn("getSearchResultsSaga called", data);

    // save search results to global state
    yield put(setSearchResults(data));

  } catch (e) {
    console.warn("Error in getSearchResultsSaga:", e);
  }
}

// Listener saga
export function* getSearchResultsHook() {
  // on SAGA_GET_SEARCH_RESULTS action, run getSearchResultsSaga
  yield takeLatest(assemblerConstants.SAGA_GET_SEARCH_RESULTS, getSearchResultsSaga)
  console.warn("getSearchResultsHook saga function called");
}
