import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { AssemblyListing, iAssemblyListingState, iAssemblyListingResponse } from 'store/types/assembler';

export const initialState: iAssemblyListingState = {
  assemblyListing: null,
};

export const assemblyListingSlice = createSlice({
  name: 'assemblyListing',
  initialState,
  reducers: {
    setAssemblyListing: (state, action: PayloadAction<iAssemblyListingResponse>) => {
        const assemblyListing: iAssemblyListingResponse = action.payload
        state.assemblyListing = assemblyListing
    }
  }
});

// The exported reducer
export default assemblyListingSlice.reducer;
// Export the action
export const { setAssemblyListing } = assemblyListingSlice.actions;
// Optional selector
export const assemblyListingReducerState = (state: RootState) => state.assemblyListing;
