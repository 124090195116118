import * as React from 'react'
import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { RoutePaths } from 'routes/paths'
import PaperWrapper from 'components/custom/PaperWrapper'
import ContentBodyWrapper from 'components/custom/ContentBodyWrapper'
import { CollapsibleTableCbam } from 'components/custom/CollapsibleTableCbam'
import LinkWrapper from 'components/custom/LinkWrapper'
import { eElementOnMouseOverEffect, eLinkTarget } from 'components/types'
import SummarizeIcon from '@mui/icons-material/Summarize'
import { Typography } from '@mui/material'

const CbamTable: FC = () => {
  const { t } = useTranslation()

  return (
    <ContentBodyWrapper title={t('dataHub.cbam')}>
      <PaperWrapper>
        <CollapsibleTableCbam></CollapsibleTableCbam>
      </PaperWrapper>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: 'auto' }}>
          {' '}
          <LinkWrapper
            className="px-2"
            target={eLinkTarget.blank}
            to={
              'https://taxation-customs.ec.europa.eu/system/files/2023-12/Default%20values%20transitional%20period.pdf'
            }
            underline={eElementOnMouseOverEffect.hover}
          >
            Source: Default values transitional period.pdf (europa.eu)
          </LinkWrapper>
          <br />
          <p>
            <i>*All emission values are displayed in ton CO2e/tons goods</i>
          </p>
        </span>
        <span>
          <a
            href="/templates/CBAM_Data_20240221.xlsx"
            target={eLinkTarget.blank}
            className={`text-gray-500 hover:text-orange-400`}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <SummarizeIcon className={`mr-1`} />
            <Typography variant={`caption`}>
              {t('dataHub.xlsxDownload')}
            </Typography>
          </a>
        </span>
      </div>
    </ContentBodyWrapper>
  )
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: [RoutePaths.dataHubCbam],
    title: 'dataHub.cbam',
    element: <CbamTable />,
    roles: componentPermissions[componentNames.pageDataHub],
    requiresAuthentication: true,
  },
  name: 'DataHubCbam',
}
export default exportObj
