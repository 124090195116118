import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import apiRequests from 'config/services';
// ^ or wherever your iSingleEntity interface is
import { assemblerConstants } from 'saga/actions/assembler';
import { takeLatest } from 'redux-saga/effects';
import { AssemblyListing, iAssemblyModel } from 'store/types/assembler';
import { sagaGetAssemblyListingAction } from 'saga/actions/assembler';

export function* deleteAssemblySaga(
  action: PayloadAction<{ uuid: string }>
): SagaIterator {
  try {
    // Destructure from the action payload
    const { uuid } = action.payload;

    // Call your API function with both arguments
    const data: iAssemblyModel = yield call(apiRequests.deleteAssembly, uuid);

    console.warn('deleteAssemblySaga called', data);

    yield put(sagaGetAssemblyListingAction())      // Refresh the list of assemblies
  } catch (e) {
    console.warn('Error in deleteAssemblySaga:', e);
  }
}

// Listener saga
export function* deleteAssemblyHook() {
  // on SAGA_GET_SEARCH_RESULTS action, run getSearchResultsSaga
  yield takeLatest(assemblerConstants.SAGA_DELETE_ASSEMBLY, deleteAssemblySaga)
}
