import { MaterialsProcessTargetParams, TransportationProcessTargetParams, PackagingProcessTargetParams, ProductionProcessTarget,  ManufacturingProcessTargetParams, EquipmentProcessTargetParams, EmployeeProcessTargetParams, EnergyProcessTargetParams, Co2ReferenceObject, ProductionProcessBaseTargetParams } from "store/types/assembler";

  
  export const calculateSusMaterialEmission = (
    p: MaterialsProcessTargetParams,
    emissionReferencePrimary: number,
    emissionReferenceRecycling?: number
  ): number => {
    const netWeight = p.netWeight ?? 0;
    const yield_ = p.yield || 0.95;
    const recyclingRatio = p.recyclingRatio || 0;
    const _emissionReferencePrimary = emissionReferencePrimary || 0;
    const _emissionReferenceRecycling = emissionReferenceRecycling || 0;
  
    const grossWeight = netWeight / yield_;
    console.log('calculating material emisssions', grossWeight, recyclingRatio, _emissionReferencePrimary, _emissionReferenceRecycling)
    return grossWeight * ((1 - recyclingRatio) * _emissionReferencePrimary + recyclingRatio * _emissionReferenceRecycling);
  };
  
  export const calculateSusTransportationFootprint = (
    p: TransportationProcessTargetParams, emissionReference: number
  ): number => {
    const distance = p.distance ?? 0;
    const grossWeight = p.transportGrossWeight ?? 0;
    const partDensity = p.partDensity || 5000;
    const _emissionReference = emissionReference || 0;
    console.log('calculatoing transportation', distance, partDensity, grossWeight, _emissionReference)
    const transportaionCo2ReferenceValue = _emissionReference/1000 * (grossWeight/1000) / (partDensity*1000) * distance;
  
    return transportaionCo2ReferenceValue;
  }
  
  export const calculateSusPackagingFootprint = (
    p: PackagingProcessTargetParams, emissionReference: number
  ): number => {
    const quantity = p.quantity ?? 0;
    const _emissionReference = emissionReference || 0;
    const packagingCo2ReferenceValue = _emissionReference * quantity;
  
    return packagingCo2ReferenceValue;
  }
  
  export const calculateSusManufacturingFootprint = (
    p: ManufacturingProcessTargetParams, emissionReference: number
  ): number => {
    const consumption = p.consumption ?? 0;
    const _emissionReference = emissionReference || 0;
    const manufacturingCo2ReferenceValue = _emissionReference * consumption;
  
    return manufacturingCo2ReferenceValue;
  }
  
  export const calculateSusEquipmentFootprint = (
    p: EquipmentProcessTargetParams, emissionReference: number
  ): number => {
    const equipmentFloorSpace = p.equipmentFloorSpace ?? 0;
    const _emissionReference = emissionReference || 0;
    const equipmentCo2ReferenceValue = _emissionReference * equipmentFloorSpace;
  
    return equipmentCo2ReferenceValue;
  }
  
  export const calculateSusEmployeeFootprint = (
    p: EmployeeProcessTargetParams, emissionReference: number
  ): number => {
    const numberOfWorkers = p.numberOfWorkers ?? 0;
    const _emissionReference = emissionReference || 0;
    const employeeCo2ReferenceValue = _emissionReference * numberOfWorkers;
  
    return employeeCo2ReferenceValue;
  }
  
  export const calculateEnergyFootprint = (
    p: EnergyProcessTargetParams, emissionReference: number
  ): number => {
    const consumption = p.consumption ?? 0;
    const _emissionReference = emissionReference/1000 || 0;
    const energyCo2ReferenceValue = _emissionReference * consumption;
  
    return energyCo2ReferenceValue;
  }
  
  export const calculateCustomProductionCo2Reference = (
    p: ProductionProcessTarget
  ): number => {
    // Use nullish coalescing to provide defaults for optional properties
    const params = p.baseParams ?? {};
    const equipment = p.equipment ?? [];
    const employees = p.employees ?? [];
    const energy = p.energy ?? [];
  
    const _cavities = params.cavities ??1;
    const _cycleTime = params.cycleTime ?? 3600;
    const _reject = params.reject ?? 0.0001;
  
    // Calculate total equipment footprint
    const equipmentFootprint = equipment.reduce((total: number, eq: Co2ReferenceObject) => {
      // Narrowing type before accessing `equipmentFloorSpace`
      const entityEmission = eq.calculatedEntityEmission ?? 0;
      return total + entityEmission;
    }, 0);
  
    // Calculate total employee footprint
    const employeeFootprint = employees.reduce((total: number, emp: Co2ReferenceObject) => {
  
      const entityEmission = emp.calculatedEntityEmission ?? 0;
      return total +  entityEmission;
    }, 0);
  
    // Calculate total energy footprint
    const energyFootprint = energy.reduce((total: number, en: Co2ReferenceObject) => {
      const entityEmission = en.calculatedEntityEmission ?? 0
  
      return total + entityEmission;
    }, 0);
  
    // Final manufacturing footprint calculation
    const manufacturingFootprint =
      (equipmentFootprint + energyFootprint + employeeFootprint) / 3600 *
      _cycleTime / _cavities *
      (1 + _reject) / 1000;
  
    return manufacturingFootprint;
  };
  

  export function calculateCo2eForSingleEntity(singleEntity: any): number {
    const { contextName } = singleEntity;
  
    // Some defaults or extracted values from your singleEntity
    // (You can rename these as needed to match your actual API response.)
    const netWeight = singleEntity.netWeight ?? 0;
    const yield_ = singleEntity.yield ?? 0.95;
    const recyclingRatio = singleEntity.recyclingRatio ?? 0;
    const emissionReferencePrimary = singleEntity.emissionReferencePrimary ?? 0;
    const emissionReferenceRecycling = singleEntity.emissionReferenceRecycling ?? 0;
    console.log('primary reference', emissionReferencePrimary)
    console.log('recycling reference', emissionReferenceRecycling)
    // For logistics
    const distance = singleEntity.distance ?? 0;
    const transportGrossWeight = singleEntity.transportGrossWeight ?? 0;
    const partDensity = singleEntity.partDensity ?? 5000;
    const emissionReference = singleEntity.emissionReferencePrimary ?? 0;
    const quantity = singleEntity.quantity ?? 0;
  
    // For production
    const consumption = singleEntity.consumption ?? 0;
    const equipmentFloorSpace = singleEntity.equipmentFloorSpace ?? 0;
    const numberOfWorkers = singleEntity.numberOfWorkers ?? 0;
  
    /**
     * Switch or if/else for all possible contexts:
     *   - .materials.metals
     *   - .materials.composites
     *   - .materials.elements
     *   - .materials.construction
     *   - .materials.polymers
     *   - .materials.sinters
     *   - .logistics.packaging
     *   - .logistics.transportation
     *   - .production.equipment
     *   - .production.employees
     *   - .production.manufacturing
     *   - .production.energy
     */
    if (
      contextName === ".materials.metals" ||
      contextName === ".materials.composites" ||
      contextName === ".materials.elements" ||
      contextName === ".materials.construction" ||
      contextName === ".materials.polymers" ||
      contextName === ".materials.sinters" ||
      contextName === ".materials.others"
    ) {
      // Materials
      return calculateSusMaterialEmission(
        {
          netWeight,
          yield: yield_,
          recyclingRatio
        },
        emissionReferencePrimary,
        emissionReferenceRecycling
      );
    } else if (contextName === ".logistics.transportation") {
      // Transportation
      return calculateSusTransportationFootprint(
        {
          distance,
          transportGrossWeight,
          partDensity
        },
        emissionReference
      );
    } else if (contextName === ".logistics.packaging") {
      // Packaging
      return calculateSusPackagingFootprint(
        {
          quantity
        },
        emissionReference
      );
    } else if (contextName === ".production.manufacturing") {
      // Manufacturing
      return calculateSusManufacturingFootprint(
        {
          consumption
        },
        emissionReference
      );
    } else if (contextName === ".production.equipment") {
      // Equipment
      return calculateSusEquipmentFootprint(
        {
          equipmentFloorSpace
        },
        emissionReference
      );
    } else if (contextName === ".production.employees") {
      // Employees
      return calculateSusEmployeeFootprint(
        {
          numberOfWorkers
        },
        emissionReference
      );
    } else if (contextName === ".energy" || contextName === ".energy.carriers") {
      // Energy
      return calculateEnergyFootprint(
        {
          consumption
        },
        emissionReference
      );
    }
  
    // If none of the contexts match, return 0 or handle error
    return 0;
  }