import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { iSearchResultsResponse, iSearchResultsState } from 'store/types/searchResults';

export const initialState: iSearchResultsState = {
  searchResults: null,
};

export const searchResultsSlice = createSlice({
  name: 'searchResults',
  initialState,
  reducers: {
    setSearchResults: (state, action: PayloadAction<iSearchResultsResponse>) => {
        const searchResults: iSearchResultsResponse = action.payload
        state.searchResults = searchResults
    }
  }
});

// The exported reducer
export default searchResultsSlice.reducer;
// Export the action
export const { setSearchResults } = searchResultsSlice.actions;
// Optional selector
export const searchResultsReducerState = (state: RootState) => state.searchResults;
