import { componentNames, Roles } from './roles'

const pagePermissions = {
  [componentNames.pageDashboard]: [Roles.ROLE_USER_ADMIN],
  [componentNames.pageDataHub]: [Roles.ROLE_USER_ADMIN],
  [componentNames.pageSingleEntity]: [
    Roles.ROLE_COMMERCIAL_DB_CONSUMER_PRODUCTS,
    Roles.ROLE_COMMERCIAL_DB_ENERGY,
    Roles.ROLE_COMMERCIAL_DB_LOGISTICS,
    Roles.ROLE_COMMERCIAL_DB_MATERIALS,
    Roles.ROLE_COMMERCIAL_DB_PRODUCTION,
    Roles.ROLE_COMMERCIAL_DB_SEARCH,
    Roles.ROLE_USER_ADMIN,
  ],
  [componentNames.pageManagementAutomationHub]: [
    Roles.ROLE_COMMERCIAL_FEATURE_API,
    Roles.ROLE_USER_ADMIN,
  ],
  [componentNames.pageAssembler]: [
    Roles.ROLE_COMMERCIAL_FEATURE_API,
    Roles.ROLE_USER_ADMIN,
  ],
  [componentNames.pageToolbox]: [
    Roles.ROLE_COMMERCIAL_FEATURE_API,
    Roles.ROLE_USER_ADMIN,
  ],
  [componentNames.pageManagementOrganizationManagement]: [
    Roles.ROLE_USER_ADMIN,
  ],
  [componentNames.pageManagementSubscriptionManagement]: [
    Roles.ROLE_USER_ADMIN,
  ],
  [componentNames.pageToolsDataHub]: [Roles.ROLE_USER_ADMIN],
  [componentNames.pageToolsProcurementHub]: [Roles.ROLE_USER_ADMIN],
  [componentNames.pageToolsScope12Calculator]: [Roles.ROLE_USER_ADMIN],
  [componentNames.pageToolsSimulationHub]: [Roles.ROLE_USER_ADMIN],
  [componentNames.pageHelpKnowledgeHub]: [Roles.ROLE_USER_ADMIN],
  [componentNames.pageKnowledgeHubDatabaseOverview]: [Roles.ROLE_USER_ADMIN],
  [componentNames.pageKnowledgeHubCalculationVariants]: [Roles.ROLE_USER_ADMIN],
  [componentNames.pageKnowledgeHubDataQualityAssurance]: [
    Roles.ROLE_USER_ADMIN,
  ],
  [componentNames.pageKnowledgeHubElectronics]: [Roles.ROLE_USER_ADMIN],
  [componentNames.pageKnowledgeHubEnergy]: [Roles.ROLE_USER_ADMIN],
  [componentNames.pageKnowledgeHubLogistics]: [Roles.ROLE_USER_ADMIN],
  [componentNames.pageKnowledgeHubMaterials]: [Roles.ROLE_USER_ADMIN],
  [componentNames.pageKnowledgeHubProduction]: [Roles.ROLE_USER_ADMIN],
  [componentNames.pageHelpSupport]: [Roles.ROLE_USER_ADMIN],
  [componentNames.pageUserSettings]: [Roles.ROLE_USER_ADMIN],
  [componentNames.page403]: [Roles.ROLE_USER_ADMIN],
  [componentNames.page404]: [Roles.ROLE_USER_ADMIN],
  [componentNames.page500]: [Roles.ROLE_USER_ADMIN],
  [componentNames.pageLogin]: [Roles.ROLE_USER_ADMIN],
  [componentNames.pageLogout]: [Roles.ROLE_USER_ADMIN],
  [componentNames.organizationProfile]: [
    Roles.ROLE_ORG_ADMIN,
  ],
  [componentNames.upgrade]: [Roles.ROLE_USER_ADMIN],
  [componentNames.comingSoon]: [Roles.ROLE_USER_ADMIN],
  [componentNames.pageSearchParameters]: [Roles.ROLE_USER_ADMIN],
  [componentNames.termsAndConditions]: [Roles.ROLE_USER_ADMIN],
  [componentNames.privacyPolicy]: [Roles.ROLE_USER_ADMIN],
  [componentNames.imprint]: [Roles.ROLE_USER_ADMIN],
  [componentNames.dataAsServiceAggrement]: [Roles.ROLE_USER_ADMIN],
  [componentNames.billing]: [Roles.ROLE_USER_ADMIN],
}
export const componentPermissions = {
  [componentNames.applicationBar]: [Roles.ROLE_USER_ADMIN],
  [componentNames.navigationDrawer]: [Roles.ROLE_USER_ADMIN],
  [componentNames.fullHero]: [Roles.ROLE_USER_ADMIN],
  [componentNames.featuredTable]: [Roles.ROLE_USER_ADMIN],
  [componentNames.contentBody]: [Roles.ROLE_USER_ADMIN],
  [componentNames.bomUpload]: [Roles.ROLE_USER_ADMIN],
  [componentNames.dataExplorer]: [Roles.ROLE_USER_ADMIN],
  [componentNames.applicationFooter]: [Roles.ROLE_USER_ADMIN],
  [componentNames.graph]: [Roles.ROLE_USER_ADMIN],
  [componentNames.lastUsed]: [Roles.ROLE_USER_ADMIN],
  [componentNames.pageMassUpload]: [Roles.ROLE_COMMERCIAL_FEATURE_MASS_UPLOAD],
  [componentNames.organizationProfileRestricted]: [Roles.ROLE_ORG_USER],
  [componentNames.billingRestricted]: [Roles.ROLE_ORG_ADMIN, Roles.ROLE_ORG_OWNER],
  ...pagePermissions,
}
