import { Checkbox, FormControlLabel } from '@mui/material'
import React, { Key, useCallback } from 'react'
import { iCustomField, iHubFilter } from 'store/types'

interface IProps {
  key?: Key
  name: string
  displayName: string
  filter: iHubFilter
  onChange: (value: iCustomField) => void
  defaultValue?: boolean
  className?: string
  disabled?: boolean
}
const CustomCheckbox = ({
  onChange,
  key,
  name,
  displayName,
  filter,
  defaultValue,
  className = 'min-w-[13.125rem] item-start',
  disabled,
}: IProps) => {
  const handleChange = useCallback(
    (e: any, newValue: boolean) => {
      onChange({
        name,
        displayName,
        value: newValue,
        info: filter,
      })
    },
    [onChange, name, displayName, filter]
  )
  return (
    <FormControlLabel
      key={key}
      className={className}
      control={
        <Checkbox
          key={`checkbox_${key}`}
          size="small"
          checked={defaultValue ? defaultValue : false}
          onChange={handleChange}
          color={'primary'}
          disabled={disabled}
        />
      }
      label={displayName}
    />
  )
}

export default CustomCheckbox
