import { call, put, takeEvery, takeLeading, takeLatest } from 'redux-saga/effects'
import apiRequests from 'config/services'
import { setAssemblyResult } from 'store/slices/assemblyResultSlice'
import { iAssemblerGroup, iAssemblerSliceState } from 'store/types/assemblerGroup'
import { iAssemblyResponse } from 'store/types/assembler'
import { assemblerConstants } from 'saga/actions/assembler'
import { sagaBuildAssemblyAction } from 'saga/actions/assembler'
import { PayloadAction } from '@reduxjs/toolkit'

export function* buildAssemblySaga() {
  try {
    let data: iAssemblyResponse = yield call(apiRequests.buildAssembly); // replace with the actual apiRequest.assemblerGetData, but doesn't work on json-server due to CORS policy - can't make a call from localhost to localhost
    console.warn("action is called", data)
    // save search results to global state
    yield put(setAssemblyResult(data))

  } catch (e) {
        //catchind errors
        console.warn(e)
  }
}

export function* buildAssemblyHook() {
  
  yield takeLatest(assemblerConstants.SAGA_BUILD_ASSEMBLY, buildAssemblySaga)
}
