import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import apiRequests from 'config/services';
import { iSingleEntity } from 'store/types/singleEntity'; 
// ^ or wherever your iSingleEntity interface is
import { setSingleEntity } from 'store/slices/singleEntity';
import { sagaGetSingleEntityAction } from 'saga/actions/assembler';
import { assemblerConstants } from 'saga/actions/assembler';
import { takeLatest } from 'redux-saga/effects';

export function* getSingleEntitySaga(
  action: PayloadAction<{ sid: string; country: string, deliveryShapes?: string }>
): SagaIterator {
  try {
    // Destructure from the action payload
    const { sid, country, deliveryShapes } = action.payload;

    // Call your API function with both arguments
    const data: iSingleEntity = yield call(apiRequests.getSingleEntity, sid, country, deliveryShapes);

    console.warn('getSingleEntitySaga called', data);

    // Save to global state
    yield put(setSingleEntity(data));
  } catch (e) {
    console.warn('Error in getSingleEntitySaga:', e);
  }
}

// Listener saga
export function* getSingleEntityHook() {
  // on SAGA_GET_SEARCH_RESULTS action, run getSearchResultsSaga
  yield takeLatest(assemblerConstants.SAGA_GET_SINGLE_ENTITY, getSingleEntitySaga)
  console.warn("getSingleEntityHook saga function called");
}
