import { createAction } from "@reduxjs/toolkit"

export const assemblerConstants = {
    SAGA_BUILD_ASSEMBLY: "SAGA_BUILD_ASSEMBLY",
    SAGA_GET_SEARCH_RESULTS: "SAGA_GET_SEARCH_RESULTS",
    SAGA_GET_SINGLE_ENTITY: "SAGA_GET_SINGLE_ENTITY",
    SAGA_GET_ASSEMBLY_LISTING: "SAGA_GET_ASSEMBLY_LISTING",
    SAGA_GET_ASSEMBLY: "SAGA_GET_ASSEMBLY",
    SAGA_CREATE_ASSEMBLY: "SAGA_CREATE_ASSEMBLY",
    SAGA_SAVE_ASSEMBLY: "SAGA_SAVE_ASSEMBLY",
    SAGA_DELETE_ASSEMBLY: "SAGA_DELETE_ASSEMBLY",
    SAGA_RENAME_ASSEMBLY: "SAGA_RENAME_ASSEMBLY"
    }

export const  sagaBuildAssemblyAction = () => {
    return { type: assemblerConstants.SAGA_BUILD_ASSEMBLY
    }
}


export const  sagaGetSearchResultsAction = () => {
    return { type: assemblerConstants.SAGA_GET_SEARCH_RESULTS
    }
}

export const  sagaGetAssemblyListingAction = () => {
    return { type: assemblerConstants.SAGA_GET_ASSEMBLY_LISTING
    }
}


// Example: typed action that includes sid & country
export const sagaGetSingleEntityAction = createAction<{
    sid: string;
    country?: string;
    deliveryShapes?: string;
  }>(assemblerConstants.SAGA_GET_SINGLE_ENTITY);

  export const sagaGetAssemblyAction = createAction<{
    uuid: string;
  }>(assemblerConstants.SAGA_GET_ASSEMBLY);

  export const sagaDeleteAssemblyAction = createAction<{
    uuid: string;
  }>(assemblerConstants.SAGA_DELETE_ASSEMBLY);


  
export const sagaCreateAssemblyAction = createAction<{
    name: string;
  }>(assemblerConstants.SAGA_CREATE_ASSEMBLY);

  export const sagaSaveAssemblyModelAction = createAction<{
    uuid: string;
  }>(assemblerConstants.SAGA_SAVE_ASSEMBLY);


  export const sagaRenameAssemblyAction = createAction<{
    uuid: string;
    name: string
  }>(assemblerConstants.SAGA_RENAME_ASSEMBLY);