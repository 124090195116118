import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { iAssemblyResponse, iAssemblyResponseState } from 'store/types/assembler';

export const initialState: iAssemblyResponseState = {
  assemblyResponse: null,
};

export const assemblyResultSlice = createSlice({
  name: 'assemblyResult',
  initialState,
  reducers: {
    setAssemblyResult: (state, action: PayloadAction<iAssemblyResponse>) => {
      // Replace the entire assembly state
        const assemblyResult: iAssemblyResponse = action.payload
        state.assemblyResponse = assemblyResult
    }
  }
});

// The exported reducer
export default assemblyResultSlice.reducer;
// Export the action
export const { setAssemblyResult } = assemblyResultSlice.actions;
// Optional selector
export const assemblyResultReducerState = (state: RootState) => state.assemblyResult;
