import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga'; // ✅ Import from 'redux-saga'
import { RootState } from 'store';
import { iAssemblyModel } from 'store/types/assembler';
import { AxiosRequestHeaders } from 'axios';
import reqProvider from '../coreRequests';
import { ASSEMBLER_API, SEARCH_API } from '../urlPaths';
import { ContextSearchResults } from 'store/types/searchResults';
import { iSingleEntityInstanceData} from 'store/types';
import { getToken } from 'config/lib'

export function* buildAssembly(): SagaIterator {
  const token = getToken()
  const requestPath = `${ASSEMBLER_API}/assembly/calculate`;
  const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
  };

  try {
    // 1) Retrieve assemblyModel from Redux store
    const assemblyModel = (yield select(
      (state: RootState) => state.assemblyModel.assemblyModel.assemblyModel
    )) as iAssemblyModel;

    // 2) Make the POST request
    const response = yield call(reqProvider.postReq, requestPath, headers, assemblyModel);

    // 3) Return or dispatch the response data
    return response.data;
  } catch (error) {
    console.error("Error in postAssembly:", error);
    return null;
  }
}

export function* saveAssemblyModel(uuid: string): SagaIterator {
  const token = getToken()
  const requestPath = `${ASSEMBLER_API}/assembly/${uuid}`;
  const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
  };

  try {
    // 1) Retrieve assemblyModel from Redux store
    const assemblyModel = (yield select(
      (state: RootState) => state.assemblyModel.assemblyModel.assemblyModel
    )) as iAssemblyModel;

    const requestBody = {
      assemblyModel: assemblyModel,
    }

    // 2) Make the POST request
    const response = yield call(reqProvider.patchReq, requestPath, headers, requestBody);
    console.log('repsonse save', response)
    // 3) Return or dispatch the response data
    return response.data.data;
  } catch (error) {
    console.error("Error in postAssembly:", error);
    return null;
  }
}

export function* renameAssembly(uuid: string, name: string): SagaIterator {
  const token = getToken()
  const requestPath = `${ASSEMBLER_API}/assembly/${uuid}`;
  const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
  };

  try {

    const requestBody = {
      name: name,
    }

    // 2) Make the POST request
    const response = yield call(reqProvider.patchReq, requestPath, headers, requestBody);
    console.log('repsonse save', response)
    // 3) Return or dispatch the response data
    return response.data.data;
  } catch (error) {
    console.error("Error in postAssembly:", error);
    return null;
  }
}

export function* createNewAssembly(name: string): SagaIterator {
  const token = getToken()
  const requestPath = `${ASSEMBLER_API}/assembly`;
  const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
  };

  const newAssembly = {
    name: name,
    model: {}
  }

  try {
    // 2) Make the POST request
    const response = yield call(reqProvider.postReq, requestPath, headers, newAssembly);

    // 3) Return or dispatch the response data
    return response.data;
  } catch (error) {
    console.error("Error in postAssembly:", error);
    return null;
  }
}

export function* getAssemblyListing(): SagaIterator {
  const token = getToken()
  const requestPath = `${ASSEMBLER_API}/assemblies`;
  const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
  };

  try {
    // 2) Make the POST request
    const response = yield call(reqProvider.getReq, requestPath, headers);

    // 3) Return or dispatch the response data
    return response;
  } catch (error) {
    console.error("Error in getAssemblies:", error);
    return null;
  }
}

export function* getAssembly(uuid: string): SagaIterator {
  const token = getToken()
  const requestPath = `${ASSEMBLER_API}/assembly/${uuid}`;
  const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
  };

  try {
    // 2) Make the POST request
    const response = yield call(reqProvider.getReq, requestPath, headers);

    // 3) Return or dispatch the response data
    return response.data;
  } catch (error) {
    console.error("Error in getAssemblies:", error);
    return null;
  }
}

export function* deleteAssembly(uuid: string): SagaIterator {
  const token = getToken()
  const requestPath = `${ASSEMBLER_API}/assembly/${uuid}`;
  const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
  };

  try {
    // 2) Make the POST request
    const response = yield call(reqProvider.deleteReq, requestPath, headers);
    // 3) Return or dispatch the response data
    return response.data;
  } catch (error) {
    console.error("Error in deleteAssembly:", error);
    return null;
  }
}

/**
 * New function to fetch search results via GET request.
 * Takes one query param: `contextName`.
 */
export function* getSearchResults(contextName: string): ContextSearchResults | any {
  const token = getToken()
  contextName = '.'
  const requestPath = `${SEARCH_API}/search/results?contextName=${encodeURIComponent(contextName)}`;
  const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
  };
  try {
    // 1) Make the GET request
    const response = yield call(reqProvider.getReq, requestPath, headers);
    console.warn('response', response)
    // 2) Return the response data
    return response;
  } catch (error) {
    console.error("Error in getSearchResults:", error);
    return null;
  }
}

export function* getSingleEntity(sid: string, countryCode?: string, deliveryShapes?: string): iSingleEntityInstanceData | any {
  let requestPath = `${SEARCH_API}/search/entity/${sid}?boost=true`
  if(deliveryShapes) {
    requestPath = requestPath + `&deliveryShapes=${deliveryShapes}`;
  }

  if(countryCode) {
    requestPath = requestPath + `&countryCode=${countryCode}`
  } else {
    requestPath = requestPath + `&countryCode=oecd1`
  }

  const token = getToken()

  const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
  };

  try {
    // 1) Make the GET request
    const response = yield call(reqProvider.getReq, requestPath, headers);
    // 2) Return the response data
    return response;
  } catch (error) {
    console.error("Error in getSingleEntity:", error);
    return null;
  }
}