import * as React from 'react'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  sagaBuildAssemblyAction,
  sagaGetSearchResultsAction,
  sagaGetAssemblyListingAction,
  sagaGetAssemblyAction, // <-- IMPORTANT: bring in the getAssembly action
} from 'saga/actions/assembler'
import { RootState } from 'store' // <-- Update if your RootState is exported differently
import { useTranslation } from 'react-i18next'
import { RoutePaths } from 'routes/paths'
import { Typography } from '@mui/material'
import PaperWrapper from 'components/custom/PaperWrapper'
import ContentBodyWrapper from 'components/custom/ContentBodyWrapper'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import SearchBar from 'components/custom/SearchBar'
import { CollapsibleAssemblyList } from './components/CollapsibleAssemblyList' // We'll keep the same name
import { Link } from 'react-router-dom'
import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'

const MyAssemblies: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // Fetch data on mount
  useEffect(() => {
    dispatch(sagaGetAssemblyListingAction())
  }, [dispatch])

  // Select assembly listing data from store
  const assemblyListing = useSelector(
    (state: RootState) => state.assemblyListing.assemblyListing?.data ?? []
  )

  return (
    <ContentBodyWrapper title={t('assembler.myAssemblies.title')}>
      <PaperWrapper>
        <Typography
          variant="h4"
          className="h-full px-4"
          fontSize="1.5rem"
          fontWeight="500"
        >
          {t('assembler.myAssemblies.summary')}
        </Typography>
        <div className="flex flex-wrap gap-y-2 gap-x-3 mt-12 mb-2 mr-5 justify-between">
          <div className="flex-grow">
            <SearchBar
              label={t('general.searchTopicInput', {
                topic: t('dataHub.title'),
              })}
              placeholderProp={t('general.search')}
              customValue=""
            />
          </div>
          <Link to="/toolbox/assembler">
            <ButtonWrapper>{t('assembler.builder')}</ButtonWrapper>
          </Link>
        </div>

        {/* Pass the data into the table component */}
        <CollapsibleAssemblyList data={assemblyListing} />
      </PaperWrapper>
    </ContentBodyWrapper>
  )
}

const exportObj = {
  routeProps: {
    path: RoutePaths.toolboxAssemblerMyAssemblies,
    title: 'assembler.myAssemblies.title',
    element: <MyAssemblies />,
    roles: componentPermissions[componentNames.pageAssembler],
    requiresAuthentication: true,
  },
  name: 'MyAssemblies',
}

export default exportObj
