import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import { IconsTypes } from 'config/services/requests'
import { RoutePaths } from 'routes/paths'
import {
  eMenuGroups,
  eMenusSortType,
  iMenuGroups,
  iMenusType,
} from 'store/types'

export const sortedMenuItemsGroups: iMenuGroups[] = [
  {
    name: eMenuGroups.knowledgeHubDataCategory,
    sort: eMenusSortType.asc,
  },
]

export const menuItems: iMenusType[] = [
  {
    routePath: RoutePaths.dashboard,
    label: 'pages.home',
    icon: IconsTypes.HomeIcon,
  },
  {
    routePath: RoutePaths.dataHub,
    label: 'dataHub.title',
    icon: IconsTypes.DataHub,
    childPages: [],
  },
  // {
  //   routePath: RoutePaths.comingSoon + '/automationHub-1',
  //   label: 'navbarSubHeader.automationHub',
  //   icon: IconsTypes.AutomationHub,
  //   childPages: [
  //     // {
  //     //   icon: IconsTypes.AutomationHubOverview,
  //     //   label: 'automationHub.overview',
  //     //   routePath: RoutePaths.comingSoon + '/automationHub-1',
  //     //   roles: componentPermissions[componentNames.comingSoon],
  //     // },
  //     // {
  //     //   icon: IconsTypes.ClientOrganizations,
  //     //   label: 'automationHub.clientOrganizations',
  //     //   routePath: RoutePaths.comingSoon + '/automationHub-2',
  //     //   roles: componentPermissions[componentNames.comingSoon],
  //     // },
  //     // {
  //     //   icon: IconsTypes.APIClientConnections,
  //     //   label: 'automationHub.aPIClientConnections',
  //     //   routePath: RoutePaths.comingSoon + '/automationHub-3',
  //     //   roles: componentPermissions[componentNames.comingSoon],
  //     // },
  //   ],
  // },
    {
    routePath: RoutePaths.toolbox,
    label: 'navbarSubHeader.toolbox',
    icon: IconsTypes.Assembler,
    childPages: [
      {
        icon: IconsTypes.KnowledgeHub,
        label: 'toolbox.overview',
        routePath: RoutePaths.toolbox,
        roles: componentPermissions[componentNames.pageAssembler],
      },
      {
        icon: IconsTypes.DatabaseOverview,
        label: 'assembler.myAssemblies.title',
        group: eMenuGroups.toolboxAssembler,
        routePath: RoutePaths.toolboxAssemblerMyAssemblies,
        roles: componentPermissions[componentNames.pageAssembler],
      },
      {
        icon: IconsTypes.Assembler,
        label: 'assembler.builder',
        group: eMenuGroups.toolboxAssembler,
        routePath: RoutePaths.toolboxAssembler,
        roles: componentPermissions[componentNames.pageAssembler],
      },
      {
        icon: IconsTypes.production,
        label: 'assembler.calculationSteps',
        group: eMenuGroups.toolboxAssembler,
        routePath: RoutePaths.toolboxAssemblerCalculationSteps,
        roles: componentPermissions[componentNames.pageAssembler],
      },
      {
        icon: IconsTypes.Assembler,
        label: 'assembler.compare',
        group: eMenuGroups.toolboxAssembler,
        routePath: RoutePaths.toolboxAssemblerCompare,
        roles: componentPermissions[componentNames.pageAssembler],
      },
      {
        icon: IconsTypes.CalculationVariants,
        label: 'collector.title',
        group: eMenuGroups.toolboxCollector,
        routePath: RoutePaths.toolboxCollector,
        roles: componentPermissions[componentNames.pageAssembler],
      }
    ],
  },
  {
    routePath: '',
    label: 'navbarSubHeader.knowledgeHub',
    icon: IconsTypes.KnowledgeHub,
    externalLink: 'https://docs.sustamizer.com/knowledge-hub/database-overview/overview'
    // childPages: [
    //   {
    //     icon: IconsTypes.DatabaseOverview,
    //     label: 'knowledgeHub.databaseOverview',
    //     group: eMenuGroups.knowledgeHubGeneral,
    //     routePath: RoutePaths.knowledgeHubDatabaseOverview,
    //     roles:
    //       componentPermissions[componentNames.pageKnowledgeHubDatabaseOverview],
    //   },
    //   // Hidden as per requested in SMZ-586 -> https://sustamize.atlassian.net/browse/SMZ-586
    //   // {
    //   //   icon: IconsTypes.CalculationVariants,
    //   //   label: 'knowledgeHub.calculationVariants',
    //   //   group: eMenuGroups.knowledgeHubGeneral,
    //   //   routePath: RoutePaths.knowledgeHubCalculationVariants,
    //   //   roles:
    //   //     componentPermissions[
    //   //       componentNames.pageKnowledgeHubCalculationVariants
    //   //     ],
    //   // },
    //   {
    //     icon: IconsTypes.DataQualityAssurance,
    //     label: 'knowledgeHub.dataQualityAssurance',
    //     group: eMenuGroups.knowledgeHubGeneral,
    //     routePath: RoutePaths.knowledgeHubDataQualityAssurance,
    //     roles:
    //       componentPermissions[
    //         componentNames.pageKnowledgeHubDataQualityAssurance
    //       ],
    //   },
    //   //data -category
    //   {
    //     icon: IconsTypes.electronics,
    //     label: 'knowledgeHub.electronics',
    //     group: eMenuGroups.knowledgeHubDataCategory,
    //     routePath: RoutePaths.knowledgeHubElectronics,
    //     roles: componentPermissions[componentNames.pageKnowledgeHubElectronics],
    //   },
    //   {
    //     icon: IconsTypes.energy,
    //     label: 'knowledgeHub.energy',
    //     group: eMenuGroups.knowledgeHubDataCategory,
    //     routePath: RoutePaths.knowledgeHubEnergy,
    //     roles: componentPermissions[componentNames.pageKnowledgeHubEnergy],
    //   },
    //   {
    //     icon: IconsTypes.logistics,
    //     label: 'knowledgeHub.logistics',
    //     group: eMenuGroups.knowledgeHubDataCategory,
    //     routePath: RoutePaths.knowledgeHub + '/logistics',
    //     roles: componentPermissions[componentNames.pageKnowledgeHubLogistics],
    //   },
    //   {
    //     icon: IconsTypes.materials,
    //     label: 'knowledgeHub.materials',
    //     group: eMenuGroups.knowledgeHubDataCategory,
    //     routePath: RoutePaths.knowledgeHub + '/materials',
    //     roles: componentPermissions[componentNames.pageKnowledgeHubMaterials],
    //   },
    //   {
    //     icon: IconsTypes.production,
    //     label: 'knowledgeHub.production',
    //     group: eMenuGroups.knowledgeHubDataCategory,
    //     routePath: RoutePaths.knowledgeHub + '/production',
    //     roles: componentPermissions[componentNames.pageKnowledgeHubProduction],
    //   },
      // {
      //   icon: IconsTypes.Sources,
      //   label: 'knowledgeHub.sources',
      //   group: eMenuGroups.knowledgeHubDocuments,
      //   routePath: RoutePaths.comingSoon + '/knowledgeHub-8',
      //   roles: componentPermissions[componentNames.comingSoon],
      // },
      // {
      //   icon: IconsTypes.Whitepapers,
      //   label: 'knowledgeHub.whitepapers',
      //   group: eMenuGroups.knowledgeHubDocuments,
      //   routePath: RoutePaths.comingSoon + '/knowledgeHub-9',
      //   roles: componentPermissions[componentNames.comingSoon],
      // },
    // ],
  },
  {
    routePath: RoutePaths.helpSupport,
    label: 'navbarSubHeader.help',
    icon: IconsTypes.HelpIcon,
    childPages: [
      {
        icon: IconsTypes.Support,
        label: 'support.title',
        routePath: RoutePaths.helpSupport,
        roles: componentPermissions[componentNames.pageHelpSupport],
      },
      // {
      //   icon: IconsTypes.FrequentlyAsked,
      //   label: 'help.frequentlyAsked',
      //   routePath: RoutePaths.comingSoon + '/help-1',
      //   roles: componentPermissions[componentNames.comingSoon],
      // },
     
    ],
  },
]
export const userMenuItems: iMenusType[] = [
  {
    routePath: RoutePaths.dashboard,
    label: 'pages.home',
    icon: IconsTypes.HomeIcon,
  },
  {
    routePath: RoutePaths.userSettings,
    label: 'pages.mySettings',
    icon: IconsTypes.Profile,
  },
  {
    routePath: RoutePaths.organizationProfile,
    label: 'pages.organization',
    icon: IconsTypes.Organization,
    roles: componentPermissions[componentNames.organizationProfile],
    restrictedRoles:
      componentPermissions[componentNames.organizationProfileRestricted],
  },
  {
    routePath: RoutePaths.billing,
    label: 'pages.billing',
    icon: IconsTypes.Wallet,
    roles: componentPermissions[componentNames.billing]
  },
]
export const menuItemsDataHubFavoritesGroup: iMenusType[] = [
  {
    icon: IconsTypes.SearchAll,
    label: 'dataHub.searchAll',
    routePath: RoutePaths.dataHub,
    roles: componentPermissions[componentNames.pageDataHub],
  },
  // {
  //   icon: IconsTypes.LastSearches,
  //   label: 'dataHub.lastSearches',
  //   routePath: RoutePaths.comingSoon + '/data-hub-1',
  //   roles: componentPermissions[componentNames.comingSoon],
  // },
  // {
  //   icon: IconsTypes.MyFavorites,
  //   label: 'dataHub.myFavorites',
  //   routePath: RoutePaths.comingSoon + '/data-hub-2',
  //   roles: componentPermissions[componentNames.comingSoon],
  // },
  // {
  //   icon: IconsTypes.MyFeedback,
  //   label: 'dataHub.myFeedback',
  //   routePath: RoutePaths.comingSoon + '/data-hub-3',
  //   roles: componentPermissions[componentNames.comingSoon],
  // },
]
export const menuItemsDataHubOptionGroup: iMenusType[] = [
  {
    icon: IconsTypes.Preferences,
    group: eMenuGroups.dataHubOptions,
    label: 'dataHub.searchParameters.title',
    routePath: RoutePaths.dataHubSearchParams,
    roles: componentPermissions[componentNames.pageSearchParameters],
  },
  // {
  //   icon: IconsTypes.RequestDataResearch,
  //   group: eMenuGroups.dataHubOptions,
  //   label: 'dataHub.optionsRequestDataResearch',
  //   routePath: RoutePaths.comingSoon + '/data-hub-5',
  //   roles: componentPermissions[componentNames.comingSoon],
  // },
  {
    icon: IconsTypes.Upload,
    group: eMenuGroups.dataHubTools,
    label: 'dataHub.toolsMassUpload',
    routePath: RoutePaths.massUpload,
    roles: componentPermissions[componentNames.pageMassUpload],
  },
  {
    icon: IconsTypes.Cbam,
    group: eMenuGroups.dataHubResources,
    label: 'dataHub.cbam',
    routePath: RoutePaths.dataHubCbam,
    roles: componentPermissions[componentNames.pageDataHub], // no special role established, might change this in the future
  },
]