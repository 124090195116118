import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import apiRequests from 'config/services';
import { iSingleEntity } from 'store/types/singleEntity'; 
// ^ or wherever your iSingleEntity interface is
import { setSingleEntity } from 'store/slices/singleEntity';
import { sagaGetAssemblyAction } from 'saga/actions/assembler';
import { assemblerConstants } from 'saga/actions/assembler';
import { takeLatest } from 'redux-saga/effects';
import { AssemblyListing, iAssemblyModel } from 'store/types/assembler';
import { setassemblyModel } from 'store/slices/assemblyModelSlice';
import { sagaBuildAssemblyAction } from 'saga/actions/assembler';

export function* saveAssemblyModelSaga(
  action: PayloadAction<{ uuid: string }>
): SagaIterator {
  try {
    // Destructure from the action payload
    const { uuid } = action.payload;

    // Call your API function with both arguments
    const data: iAssemblyModel = yield call(apiRequests.saveAssemblyModel, uuid);

    console.warn('saveAssemblySaga called', data);

    // Save to global state
    yield put(setassemblyModel(data));
    yield put(sagaBuildAssemblyAction())
  } catch (e) {
    console.warn('Error in getAssemblySaga:', e);
  }
}

// Listener saga
export function* saveAssemblyModelHook() {
  // on SAGA_GET_SEARCH_RESULTS action, run getSearchResultsSaga
  yield takeLatest(assemblerConstants.SAGA_SAVE_ASSEMBLY, saveAssemblyModelSaga)
}
