import React, { useEffect, useMemo } from 'react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { iNewMember, iOrganizationManageTeam } from 'pages/user/types'
import { iLooseObject } from 'pages/DataHub/filters/types'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'

import SearchBar from 'components/custom/SearchBar'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import {
  eButtonSize,
  eTypographyVariant,
  eButtonTypes,
  eColor,
} from 'components/types'
import ModalWrapper from 'components/custom/ModalWrapper'
import TitleWrapper from 'components/custom/TitleWrapper'
import CustomStringInput from 'components/custom/CustomStringInput'
import TextWrapper from 'components/custom/TextWrapper'
import ButtonMenuWrapper from 'components/custom/ButtonMenuWrapper'
import { Field, Form } from 'react-final-form'
import useFieldsErrors from 'config/hooks/useFieldsErrors'
import {
  sagaActivateTeamMemberAction,
  sagaAddMemberAction,
  sagaDeactivateTeamMemberAction,
  sagaDeleteTeamMemberAction,
  sagaGetOrganizationTeamMembersAction,
  sagaUpdateMemberAction,
} from 'saga/actions/user'
import { useDispatch, useSelector } from 'react-redux'
import {
  generalSettingsOrganizationState,
  toggleAddMemberModalVisibility,
} from 'store/slices/generalSettings'
import { capitalizeFirst } from 'config/services/MassUpload/helpers'
import { organizationProfileState } from 'store/slices/organizationProfile'
import { iOrganizationData } from 'store/types'
import { userReducerState } from 'store/slices/user'
import { Roles } from 'config/roles'
import {
  LicenseAssignment,
  getCurrentSubscription,
  getSubscriptionValue,
} from './LicenseAssignment'
import { sortByFieldName } from 'components/utils/sorting'
import { EmptyData } from 'components/common/EmptyData'
import { eProductKey } from 'store/types/subscriptionOrder'

interface iProps {
  children?: JSX.Element | JSX.Element[]
}

const OrganizationManageTeam = ({ children }: iProps) => {
  const { t } = useTranslation()
  const isModalOpen = useSelector(
    generalSettingsOrganizationState
  ).isAddMemberModalOpen
  const organizationData: iOrganizationData | any = useSelector(
    organizationProfileState
  )

  const userData: any = useSelector(userReducerState)

  const [newMember, setNewMember] = useState<iNewMember>({
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
  })
  const [searchText, setSearchText] = useState<string>('')
  const [filterValue, setFilterValue] = useState<string>('')
  const [customErrors, setCustomErrors] = useState<iLooseObject>({})
  const [currentRoles] = useState<string[]>([
    Roles.ROLE_ORG_USER,
    Roles.ROLE_ORG_ADMIN,
  ])
  const [deleteModalIRowId, setDeleteModalIRowId] = useState<
    number | undefined
  >(undefined)

  const dispatch = useDispatch()
  const { errorFormFields } = useFieldsErrors()
  const userRoles = useMemo(
    () => [Roles.ROLE_ORG_USER, Roles.ROLE_ORG_ADMIN],
    []
  )
  const [teamUsersRows, setTeamUsersData] = useState<iOrganizationData>()

  //'Most popular'
  const filerByValues = useMemo(
    () => ['all', 'active', 'inactive', 'pending'],
    []
  )
  // first load
  useEffect(() => {
    dispatch(
      sagaGetOrganizationTeamMembersAction(parseInt(userData.organization.id))
    )
  }, [dispatch, userData.organization.id])

  // organization team members state gets updated:
  useEffect(() => {
    if (organizationData && organizationData.team) {
      let tempTeam: iOrganizationData = JSON.parse(
        JSON.stringify(organizationData)
      )
      if (!tempTeam.team) {
        setTeamUsersData(organizationData)
      } else {
        tempTeam.team = tempTeam.team.map((item: iOrganizationManageTeam) => {
          return {
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
            roles: item.roles || [],
            subscriptionId: item.subscriptionId || null,
            addOns: item.addOns || '-',
            status: item.status || '-',
            actions: item.actions || '-',
          }
        })
        const ownerIndex = tempTeam.team.findIndex(
          (item: iLooseObject) => item.roles[0] === Roles.ROLE_ORG_OWNER
        )
        if (
          ownerIndex !== -1 &&
          tempTeam &&
          tempTeam?.team &&
          tempTeam.team[ownerIndex]
        ) {
          const ownerRow = JSON.parse(JSON.stringify(tempTeam.team[ownerIndex]))
          delete tempTeam.team[ownerIndex]
          tempTeam.team = sortByFieldName(tempTeam.team, 'email')
          tempTeam.team.unshift(ownerRow)
        }
        setTeamUsersData(tempTeam)
      }
    }
  }, [organizationData])

  useEffect(() => {
    setCustomErrors(errorFormFields)

    return () => {
      setCustomErrors(errorFormFields)
    }
  }, [errorFormFields])

  useEffect(() => {
    setCustomErrors(errorFormFields)
  }, [errorFormFields])

  const generateLabel = useCallback(
    (key: string) => t(`organization.edit.${key}`),
    [t]
  )
  const submitButton = useCallback(() => {
    setCustomErrors({})
  }, [])

  const generateTableHead = useCallback(
    (row: iOrganizationManageTeam) => {
      return (
        <TableHead>
          <TableRow>
            {row && row.team && row.team.length ? (
              Object.keys(row.team[0] || [])
                .filter((key) => key !== 'id')
                .map((key, index) => {
                  const positionLeftCenter =
                    key === 'actions' ? 'center' : 'left'
                  return (
                    <TableCell
                      align={positionLeftCenter}
                      className={`font-bold`}
                      key={`header-key-${key}`}
                    >
                      {generateLabel(key)}
                    </TableCell>
                  )
                })
            ) : (
              <></>
            )}
          </TableRow>
        </TableHead>
      )
    },
    [generateLabel]
  )
  useEffect(() => {
    if (isModalOpen === false) {
      setNewMember({
        firstName: '',
        lastName: '',
        email: '',
        roles: [],
      })
    }
  }, [isModalOpen, userRoles])

  useEffect(() => {
    let count = 0
    Object.values(newMember).map((value: string) =>
      value.length > 0 ? count++ : null
    )
  }, [newMember])

  const handleRoleFilterChange = useCallback(
    (newValue: iLooseObject) => {
      setNewMember({
        roles: newValue.length > 0 ? [newValue.value] : [],
      })
      const roleIndex = currentRoles.findIndex(
        (item) => t('organization.team.roles.' + item) === newValue.value
      )
      //send change request to BE:
      dispatch(
        sagaUpdateMemberAction({
          roles: [currentRoles[roleIndex]],
          userId: newValue.id,
        })
      )
    },
    [currentRoles, dispatch, t]
  )
  const handleModalCancel = useCallback(() => {
    dispatch(toggleAddMemberModalVisibility())
    setNewMember({})
    setCustomErrors({})
  }, [dispatch])

  const handleFilterBy = useCallback((newValue: string) => {
    setFilterValue(newValue)
  }, [])

  const onModalClose = useCallback(() => {
    setNewMember({
      firstName: '',
      lastName: '',
      email: '',
      roles: [],
    })
    setCustomErrors({})
    dispatch(toggleAddMemberModalVisibility())
  }, [dispatch])

  const generateTableCell = useCallback(
    (
      cellContent: string[],
      index: number,
      customClassName: string = `text-left justify-start flex-start items-start`,
      columnName?: string,
      currentRow?: iLooseObject | any
    ) => {
      const positionLeftCenter = columnName === 'actions' ? 'center' : 'left'
      if (columnName === 'roles') {
        if (
          cellContent[0] === Roles.ROLE_ORG_OWNER ||
          (currentRow.status === 'pending' && columnName === 'roles')
        ) {
          return (
            <TableCell
              align={positionLeftCenter}
              key={`table-cell-key-${index}`}
              className={` ${customClassName}`}
            >
              {t('organization.team.roles.' + cellContent[0])}
            </TableCell>
          )
        } else {
          return (
            <TableCell
              align={positionLeftCenter}
              key={`table-cell-key-${index}`}
              className={` ${customClassName}`}
            >
              <ButtonMenuWrapper
                menuValues={currentRoles?.map((item) => {
                  return {
                    label: `organization.team.roles.${item}`,
                    value: `${item}`,
                  }
                })}
                defaultValue={t('organization.team.roles.' + cellContent[0])}
                onClick={(newValue: string) => {
                  if (newValue && newValue !== cellContent[0]) {
                    handleRoleFilterChange({
                      id: currentRow.id,
                      value: newValue,
                    })
                  }
                }}
              />
            </TableCell>
          )
        }
      } else if (columnName === 'actions') {
        let actionList = []

        switch (currentRow.status) {
          case 'active':
            actionList = ['deactivateUser', 'deleteUser']
            break
          case 'inactive':
            actionList = ['activateUser', 'deleteUser']
            break
          case 'pending':
            actionList = ['deleteUser']
            break
          default:
            actionList = ['deactivateUser', 'deleteUser']
            break
        }

        const isOrgOwner = currentRow.roles.find(
          (r: string) => r === Roles.ROLE_ORG_OWNER
        )

        return (
          <TableCell
            align={positionLeftCenter}
            key={`table-cell-key-${index}`}
            className={` ${customClassName}`}
          >
            {!!isOrgOwner ? (
              '-'
            ) : (
              <ButtonMenuWrapper
                btnClass={` ${'h-7 bg-transparent text-black px-2 shadow-none text-lg font-bold'}`}
                changeValueOnClick={false}
                showIcon={false}
                menuValues={actionList.map((item) => {
                  return {
                    label: `organization.team.${item}`,
                    value: `${item}`,
                  }
                })}
                defaultValue={'...'}
                onClick={(value: string, selectedValue: string = '') =>
                  onActionsClick(selectedValue, currentRow)
                }
              />
            )}
          </TableCell>
        )
      } else if (columnName === 'subscriptionId') {
        const subscriptionId = Number(currentRow.subscriptionId)
        return (
          <TableCell
            align={positionLeftCenter}
            key={`table-cell-key-${index}`}
            className={` ${customClassName}`}
          >
            <LicenseAssignment
              subscriptionId={subscriptionId}
              userData={currentRow}
            />
          </TableCell>
        )
      } else {
        const getAddOnsDB = (
          packageKey: eProductKey,
          includedProductKeys: eProductKey[] | undefined
        ) => {
          const addOnsDataBases = includedProductKeys?.filter(
            (ipk) => ipk !== packageKey
          )
          let keys = ''
          addOnsDataBases?.forEach((db) => {
            keys = keys + t(getSubscriptionValue(db)) + ' '
          })
          return keys || '-'
        }
        let cellText =
          ['status'].indexOf(columnName || '') !== -1
            ? t('organization.team.' + cellContent)
            : cellContent

        switch (columnName) {
          case 'status':
            cellText = t('organization.team.' + cellContent)
            break
          case 'addOns':
            const currentSubscription = getCurrentSubscription(
              Number(currentRow.subscriptionId),
              organizationData.subscriptions
            )
            cellText = !!currentSubscription?.packageKey
              ? getAddOnsDB(
                  currentSubscription?.packageKey,
                  currentSubscription?.includedProductKeys
                )
              : '-'
            break
          default:
            cellText = cellContent
            break
        }
        return (
          <TableCell
            align={positionLeftCenter}
            key={`table-cell-key-${index}`}
            className={` ${customClassName}`}
          >
            {cellText}
          </TableCell>
        )
      }
    },
    [currentRoles, handleRoleFilterChange, t, organizationData]
  )

  const generateRow = useCallback(
    (userRowData: iOrganizationManageTeam, rowIndex: number) => {
      let result: JSX.Element[] = []

      Object.keys(userRowData)
        .filter((key) => key !== 'id')
        .forEach((key: string, index: number) => {
          const tableCellItem: iLooseObject | any =
            userRowData && userRowData[key]

          result.push(
            generateTableCell(tableCellItem, index, '', key, userRowData)
          )
        })

      return result
    },
    [generateTableCell]
  )
  const customValidate = useCallback(
    (values: any) => {
      return customErrors
    },
    [customErrors]
  )
  const onSubmit = useCallback(
    (newMember: iLooseObject) => {
      dispatch(sagaAddMemberAction(newMember))
    },
    [dispatch]
  )

  const onDeleteUser = () =>
    deleteModalIRowId && dispatch(sagaDeleteTeamMemberAction(deleteModalIRowId))
  const onActionsClick = useCallback(
    (value: string, rowData: any) => {
      switch (value) {
        case 'deleteUser':
          setDeleteModalIRowId(rowData.id)
          break
        case 'deactivateUser':
          dispatch(sagaDeactivateTeamMemberAction(rowData.id))
          break
        case 'activateUser':
          dispatch(sagaActivateTeamMemberAction(rowData.id))
          break
        default:
          break
      }
      // handleRoleFilterChange({ id: currentRow.id, value: newValue })
    },
    [dispatch]
  )

  const onAddMemberClick = useCallback(() => {
    dispatch(toggleAddMemberModalVisibility())
  }, [dispatch])
  const onSearch = useCallback((searchValue: any) => {
    setSearchText(searchValue)
  }, [])

  const filteredTeamUsersRows =
    teamUsersRows && teamUsersRows.team
      ? teamUsersRows.team
          .filter((item: iOrganizationManageTeam) => {
            return searchText.length > 0 && item
              ? item.email?.indexOf(searchText) !== -1 ||
                  item.firstName?.indexOf(searchText) !== -1 ||
                  item.lastName?.indexOf(searchText) !== -1
              : true
          })
          .filter((item: iOrganizationManageTeam) => {
            return filterValue !== 'All' && item
              ? t('organization.team.' + item.status) === filterValue ||
                  filterValue === ''
              : true
          })
          .filter(Boolean)
          .map((item: iOrganizationManageTeam) => {
            return {
              firstName: item.firstName,
              lastName: item.lastName,
              email: item.email,
              roles: item.roles,
              subscriptionId: item.subscriptionId,
              addOns: item.addOns,
              status: item.status,
              actions: item.actions,
              id: item.id,
            }
          })
      : []
  return (
    <TableContainer component={Paper} className="mt-6 mb-3 pb-2" elevation={2}>
      <div className="flex flex-wrap max-xl:justify-center gap-2 w-full pb-2 pt-1">
        <div className="pl-3 pr-0 max-md:pr-3 w-[auto] max-md:w-[100%]">
          <SearchBar
            className="w-[90%] max-md:w-[100%]"
            label={t('general.searchTopicInput', {
              topic: t('singleEntity.title'),
            })}
            placeholderProp={t('general.search')}
            onSearch={onSearch}
          />
        </div>
        <div className="flex sm:flex-unset xl:flex-1  max-md:flex-wrap justify-between max-xl:justify-center pb-1 gap-4">
          <div className="pt-2">
            <ButtonMenuWrapper
              menuValues={filerByValues.map((item) => {
                return { label: 'organization.team.' + item, value: item }
              })}
              btnText={t(
                'organization.team.' +
                  (filterValue && filterValue.length > 0
                    ? filterValue.toLocaleLowerCase()
                    : 'filterByStatus')
              )}
              onClick={(newValue: string) => {
                handleFilterBy(newValue)
              }}
            />
          </div>
          <div className="pt-2 pr-4">
            <ButtonWrapper
              size={eButtonSize.small}
              startIcon={<ManageAccountsIcon />}
              onClick={() => onAddMemberClick()}
            >
              {t('organization.edit.addMember')}
            </ButtonWrapper>
          </div>
        </div>
      </div>
      {!!filteredTeamUsersRows?.length ? (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          {generateTableHead(teamUsersRows ? teamUsersRows : [])}

          <TableBody>
            {filteredTeamUsersRows?.map(
              (userRowData: iOrganizationManageTeam, index: number) => {
                return (
                  <TableRow
                    key={`table-head-${index}`}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      height: '45px',
                    }}
                  >
                    {generateRow(userRowData, index)}
                  </TableRow>
                )
              }
            )}
          </TableBody>
        </Table>
      ) : (
        <EmptyData />
      )}
      <ModalWrapper
        onClose={() => onModalClose()}
        open={isModalOpen}
        hasCloseButton
      >
        <Form
          initialValues={newMember as iNewMember}
          validate={customValidate}
          onSubmit={onSubmit}
          render={({ handleSubmit, form, submitting }) => (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col px-[20%] py-6">
                <TitleWrapper
                  variant={eTypographyVariant.h2}
                  className="text-xl w-full -mt-2 mb-2"
                >
                  {t('organization.edit.addNewMember')}
                </TitleWrapper>
                <TextWrapper className="text-base">
                  {t('organization.edit.invitation')}
                </TextWrapper>

                <div className="mt-4">
                  <Field name="firstName">
                    {({ input, meta }) => (
                      <CustomStringInput
                        defaultValue={newMember.firstName}
                        name="firstName"
                        className="mb-2"
                        placeholder={t('organization.edit.firstName') + '*'}
                        onChange={input.onChange}
                        onBlur={() => {
                          input.onBlur()
                        }}
                        error={{
                          active: meta.error,
                          text: meta.error,
                        }}
                      />
                    )}
                  </Field>
                  <Field name="lastName">
                    {({ input, meta }) => (
                      <CustomStringInput
                        defaultValue={newMember.lastName}
                        name="lastName"
                        className="mb-2"
                        placeholder={t('organization.edit.lastName') + '*'}
                        onChange={input.onChange}
                        onBlur={() => {
                          input.onBlur()
                        }}
                        error={{
                          active: meta.error,
                          text: meta.error,
                        }}
                      />
                    )}
                  </Field>
                  <Field name="email">
                    {({ input, meta }) => (
                      <CustomStringInput
                        defaultValue={newMember.email}
                        name="email"
                        className="mb-2"
                        placeholder={t('organization.edit.email') + '*'}
                        onChange={input.onChange}
                        onBlur={() => {
                          input.onBlur()
                        }}
                        error={{
                          active: meta.error,
                          text: meta.error,
                        }}
                      />
                    )}
                  </Field>

                  <Field name="roles">
                    {({ input, meta }) => (
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {t('organization.team.chooseRole') + '*'}
                        </InputLabel>
                        <Select
                          className="w-full"
                          placeholder="role"
                          name="roles"
                          labelId="roleSelect"
                          onChange={input.onChange}
                          label={t('organization.team.chooseRole') + '*'}
                        >
                          {userRoles.map((role, index) => (
                            <MenuItem key={role + index} value={role}>
                              {capitalizeFirst(
                                t(`organization.team.roles.${role}`)
                              )}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error={true}>
                          {meta.error}
                        </FormHelperText>
                      </FormControl>
                    )}
                  </Field>
                </div>
              </div>
              <div className="flex justify-end space-x-5 px-[20%] pt-2 pb-4">
                <ButtonWrapper
                  size={eButtonSize.medium}
                  onClick={() => handleModalCancel()}
                  aria-label={t('general.cancel')}
                >
                  {t('general.cancel')}
                </ButtonWrapper>
                <ButtonWrapper
                  onClick={submitButton}
                  type={eButtonTypes.submit}
                  size={eButtonSize.medium}
                  startIcon={<ManageAccountsIcon />}
                  aria-label={t('organization.edit.addMember')}
                >
                  {t('organization.edit.addMember')}
                </ButtonWrapper>
              </div>
            </form>
          )}
        />
      </ModalWrapper>

      {/* MODAL START */}
      <ModalWrapper
        onClose={() => {
          setDeleteModalIRowId(undefined)
        }}
        open={!!deleteModalIRowId}
        footerSection={
          <div className="space-x-2 pt-2">
            <ButtonWrapper
              color={eColor.secondary}
              onClick={() => {
                onDeleteUser()
                setDeleteModalIRowId(undefined)
              }}
              aria-label={t('general.yes')}
            >
              {t('general.yes')}
            </ButtonWrapper>
            <ButtonWrapper
              onClick={() => {
                setDeleteModalIRowId(0)
              }}
              aria-label={t('general.no')}
            >
              {t('general.no')}
            </ButtonWrapper>
          </div>
        }
      >
        <>
          <h1 className="text-xl pb-4">
            {t('organization.edit.deleteModal.title')}
          </h1>
          <p className=" pb-2"> {t('organization.edit.deleteModal.message')}</p>
        </>
      </ModalWrapper>
      {/* MODAL END */}
    </TableContainer>
  )
}

export default OrganizationManageTeam
