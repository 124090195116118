import { Co2ReferenceObject,  MaterialsProcessTargetParams, TransportationProcessTargetParams, PackagingProcessTargetParams, EnergyProcessTargetParams, EmployeeProcessTargetParams, EquipmentProcessTargetParams, ManufacturingProcessTargetParams, ProductionProcessBaseTargetParams } from "store/types/assembler"

/**
 * Returns the numeric quantity depending on category & subCategory.
 */
export function getQuantity(item: Co2ReferenceObject): number {
    // If no context is given, it must be other materials
    if (!item.context) {
        const matParams = item.params as MaterialsProcessTargetParams
        return matParams.netWeight ?? 1
    }
  
    // Remove leading '.' if it exists
    const ctx = item.context.startsWith('.') ? item.context.slice(1) : item.context
    // Example: ".materials.metals" => "materials.metals"
  
    const parts = ctx.split('.') // e.g. ["materials", "metals"]
  
    // Top-level context
    const top = parts[0] // e.g. 'materials', 'logistics', 'energy', etc.
  
    // 1) Materials -> netWeight
    if (top === 'materials') {
      // Cast to MaterialsProcessTargetParams or MetalsProcessTargetParams
      const matParams = item.params as MaterialsProcessTargetParams
      return matParams.netWeight ?? 0
    }
  
    // 2) Logistics -> e.g. .logistics.transportation => distance, .logistics.packaging => quantity
    if (top === 'logistics') {
      const sub = parts[1] // e.g. 'transportation' or 'packaging'
  
      if (sub === 'transportation') {
        const transParams = item.params as TransportationProcessTargetParams
        return transParams.distance ?? 0
      } else if (sub === 'packaging') {
        const packParams = item.params as PackagingProcessTargetParams
        return packParams.quantity ?? 0
      }
    }
  
    // 3) Production / Energy / etc.
    // If you had ".production.manufacturing" or ".energy"
    // add more branches as needed:
    if (top === 'production') {
        const sub = parts[1]
        if (sub === 'employees') {
            const employeeParams = item.params as EmployeeProcessTargetParams
            return employeeParams.numberOfWorkers ?? 0
        }
        if (sub === 'equipment') {
            const equipmentParams = item.params as EquipmentProcessTargetParams
            return equipmentParams.equipmentFloorSpace ?? 0
        }
        if (sub === 'manufacturing') {
            const manufacturingParams = item.params as ManufacturingProcessTargetParams
            return manufacturingParams.consumption ?? 0
        }
      // Sub might be "manufacturing", "employees", etc.
      // Return something meaningful or default to 1
      return 1
    }
    if (top === 'energy') {
      // Cast to e.g. EnergyProcessTargetParams if you have it
      // Return something relevant or default
      const energyParams = item.params as EnergyProcessTargetParams
      return energyParams.consumption ?? 0
    }
  
    // Fallback
    return 0
  }
  
  /**
   * Returns the display unit, if you want to override it by category/subCategory.
   */
  export function getUnit(item: Co2ReferenceObject): string {
    if (!item.unit) {
      return 'N/A';
    }
  
    const slashIndex = item.unit.indexOf('/');
    if (slashIndex === -1) {
      // No slash found
      return item.unit;
    }
  
    // Return everything after the slash
    return item.unit.slice(slashIndex + 1).trim() || 'N/A';
  }
  
  /**
   * Returns a comma-separated string of additional info
   * based on category / subCategory. Extend this as you wish.
   */
 export function getAdditionalInfo(item: Co2ReferenceObject): string {
    const infoParts: string[] = []

    // Always include the comment if present
    if (item.comment) {
      infoParts.push(item.comment)
    }
  
    if (!item.context) {
      // No context => just return whatever comment we had
      return infoParts.join(', ')
    }
  
    const ctx = item.context.startsWith('.') ? item.context.slice(1) : item.context
    const parts = ctx.split('.') // e.g. ["materials","metals"] or ["logistics","packaging"]
  
    const top = parts[0]
    const sub = parts[1] // might be undefined for e.g. ".energy"
  
    if (top === 'materials') {
      // metals, elements, etc.
      const matParams = item.params as MaterialsProcessTargetParams
  
      // "shape" might only apply to metals, so be cautious
      if ((matParams as any).shape) {
        infoParts.push(`Shape: ${(matParams as any).shape}`)
      }
  
      if (matParams.countryName) {
        infoParts.push(`Country: ${matParams.countryName}`)
      }
  
      if (matParams.recyclingRatio != null) {
        infoParts.push(`Recycling Ratio: ${matParams.recyclingRatio*100} %`)
      }

      if (matParams.yield != null) {
        infoParts.push(`Yield: ${matParams.yield*100} %`)
      }
    }
  
    if (top === 'logistics') {
      if (sub === 'transportation') {
        const transParams = item.params as TransportationProcessTargetParams
        if (transParams.transportGrossWeight != null) {
          infoParts.push(`Gross Weight: ${transParams.transportGrossWeight}`)
        }
        if (transParams.partDensity != null) {
          infoParts.push(`Density: ${transParams.partDensity}`)
        }
      } else if (sub === 'packaging') {
        const packParams = item.params as PackagingProcessTargetParams
        if (packParams.quantity != null) {
          infoParts.push(``)
        }
      }
    }
  
    if (top === 'energy') {
      // If there's more info to display for energy, do it here
      // e.g. (item.params as EnergyProcessTargetParams).consumption
    }

    if (item.source === 'custom' && top === 'production') {
      const prodParams = item.baseParams as ProductionProcessBaseTargetParams
      if (prodParams.cavities != null) {
        infoParts.push(`Cavities: ${prodParams.cavities}`)
      }
      if (prodParams.cycleTime != null) {
        infoParts.push(`Cycle Time: ${prodParams.cycleTime}`)
      }
      if (prodParams.cavities != null) {
        infoParts.push(`Reject: ${prodParams.reject}`)
      }
    }
  
    return infoParts.join(', ')
  }

  export function getDatabaseName(item: Co2ReferenceObject): string {
    // If no context, fallback to item.unit
    if (!item.context) return item.context ?? 'N/A'
  
    const ctx = item.context.startsWith('.') ? item.context.slice(1) : item.context
    const parts = ctx.split('.') // e.g. ["materials","metals"] or ["logistics","transportation"]
  
    const databaseName = parts[0]
  
    // Otherwise fallback
    return databaseName ?? 'materials'
  }

  export function getDatabaseType(item: Co2ReferenceObject): string {
    // If no context, fallback to item.unit
    if (!item.context) return item.context ?? 'N/A'
  
    const ctx = item.context.startsWith('.') ? item.context.slice(1) : item.context
    const parts = ctx.split('.') // e.g. ["materials","metals"] or ["logistics","transportation"]
    let databaseType = parts[1]
    if (databaseType === undefined && parts[0] === 'production'){ 
      databaseType = 'manufacturing'
    }
    if (databaseType === undefined && parts[0] === 'energy'){ 
      databaseType = 'Electricity Grid'
    }

    if (databaseType === 'carriers' && parts[0] === 'energy'){ 
      databaseType = 'Direct Energy'
    }
    
    console.log('type', databaseType)
    // Otherwise fallback
    return databaseType ?? 'elements'
  }