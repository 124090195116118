import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RoutePaths } from 'routes/paths'
import {
  Typography,
  TextField,
  MenuItem,
  Tabs,
  Box,
  Tab,
  IconButton,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import PaperWrapper from 'components/custom/PaperWrapper'
import ContentBodyWrapper from 'components/custom/ContentBodyWrapper'
import { CollapsibleTableAssembler } from './components/CollapsibleTableAssembler/CollapsibleTableAssembler'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import { eColor } from 'components/types'
import { ModalYesNo, ModalNewName } from './components/AssemblerModals'
import { ModalRename } from './components/AssemblerModals'
import {
  sagaCreateAssemblyAction,
  sagaSaveAssemblyModelAction,
  sagaBuildAssemblyAction,
  sagaGetSearchResultsAction,
  sagaGetAssemblyListingAction,
  sagaGetAssemblyAction,
  sagaRenameAssemblyAction, // <--- import rename saga
} from 'saga/actions/assembler'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import LinkWrapper from 'components/custom/LinkWrapper'

import { AssemblerOverview } from './components/AssemblerOverview/AssemblerOverview'
import { useSearchParams } from 'react-router-dom'

// versionList
const versionList = [{ value: 'V3.0.0', label: 'V3.0.0' }]

const Assembler: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // -- Read the query param "assemblyId" if present
  const [searchParams] = useSearchParams()
  const assemblyIdFromQuery = searchParams.get('assemblyId') // e.g. "abc123"

  // --- Local State ---
  const [openNewAssemblyModal, setOpenNewAssemblyModal] = useState(false)
  const [openModalYesNo, setOpenModalYesNo] = useState(false)
  // State to open/close rename modal
  const [openRenameModal, setOpenRenameModal] = useState(false)

  const [selectedAssembly, setSelectedAssembly] = useState('')
  const [selectedVersion, setSelectedVersion] = useState(versionList[0].value)

  // Tabs
  const [activeTab, setActiveTab] = useState(0)
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  // States for saving animation
  const [isSaving, setIsSaving] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [oldAssemblyCount, setOldAssemblyCount] = useState(0)
  // --- Redux Selectors ---
  const currentAssemblyData = useSelector(
    (state: RootState) => state.assemblyResult.assemblyResponse?.data
  )
  const assemblyListing = useSelector(
    (state: RootState) => state.assemblyListing.assemblyListing?.data ?? []
  )
  const currentAssemblyModel = useSelector(
    (state: RootState) => state.assemblyModel?.assemblyModel
  )

  // Debug
  console.log('assembly listing', assemblyListing)
  console.log('currentAssemblyModel', currentAssemblyModel)
  console.log('current assembly data', currentAssemblyData)

  // If you want the chain from the newly fetched assembly:
  const calculationProcessChain =
    currentAssemblyData?.result?.calculationProcessChain || []
  const totalEmissions = currentAssemblyData?.result?.emissions || 0

  // If your assembly model has a "name"
  const assemblyName = currentAssemblyModel?.name || ''

  // --- On mount, fetch data ---
  useEffect(() => {
    dispatch(sagaGetSearchResultsAction())
    dispatch(sagaGetAssemblyListingAction())
  }, [dispatch])

  // If "assemblyId" is present in the URL, auto-load that assembly.
  useEffect(() => {
    if (assemblyIdFromQuery) {
      setSelectedAssembly(assemblyIdFromQuery)
      dispatch(sagaGetAssemblyAction({ uuid: assemblyIdFromQuery }))
      dispatch(sagaBuildAssemblyAction())
    }
  }, [assemblyIdFromQuery, dispatch])

  useEffect(() => {
    if (!assemblyIdFromQuery) {
      if (assemblyListing.length > oldAssemblyCount) {
        // A new assembly was created, pick the last
        const lastAsm = assemblyListing[assemblyListing.length - 1]
        setSelectedAssembly(lastAsm.id)
        dispatch(sagaGetAssemblyAction({ uuid: lastAsm.id }))
        dispatch(sagaBuildAssemblyAction())
      } else if (!selectedAssembly && assemblyListing.length > 0) {
        // No selection, no query param, pick last item
        const lastAsm = assemblyListing[assemblyListing.length - 1]
        setSelectedAssembly(lastAsm.id)
        dispatch(sagaGetAssemblyAction({ uuid: lastAsm.id }))
        dispatch(sagaBuildAssemblyAction())
      }
      setOldAssemblyCount(assemblyListing.length)
    }
  }, [
    assemblyListing,
    assemblyIdFromQuery,
    dispatch,
    oldAssemblyCount,
    selectedAssembly,
  ])

  // --- Handlers ---
  const handleAssemblyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAssemblyId = e.target.value
    setSelectedAssembly(newAssemblyId)

    dispatch(sagaGetAssemblyAction({ uuid: newAssemblyId }))
    dispatch(sagaBuildAssemblyAction())
  }

  const handleSaveAssembly = () => {
    if (!currentAssemblyModel?.id) return

    setIsSaving(true)
    setIsSaved(false)

    dispatch(
      sagaSaveAssemblyModelAction({
        uuid: currentAssemblyModel.id,
      })
    )

    // Simulated success
    setTimeout(() => {
      setIsSaving(false)
      setIsSaved(true)
      setTimeout(() => setIsSaved(false), 1500)
    }, 1000)
  }

  // Handle rename
  const handleRenameAssembly = (newName: string) => {
    if (!currentAssemblyModel?.id) return

    dispatch(
      sagaRenameAssemblyAction({
        uuid: currentAssemblyModel.id,
        name: newName,
      })
    )
  }

  const existingAssemblyNames = assemblyListing.map((asm) =>
    asm.name.toLowerCase()
  )

  return (
    <ContentBodyWrapper title={t('assembler.title')}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Builder" />
          <Tab label="Overview" />
        </Tabs>
      </Box>

      {activeTab === 0 ? (
        <PaperWrapper>
          {/* ---- BUILDER UI ---- */}
          <div className="flex justify-between gap-x-3 mb-4">
            <div className="flex gap-x-3 items-center">
              <TextField
                id="outlined-select-assembly"
                select
                label="Select an Assembly"
                value={selectedAssembly}
                onChange={handleAssemblyChange}
              >
                {assemblyListing.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>

              {/* Edit icon to rename the currently selected assembly */}
              <IconButton
                onClick={() => setOpenRenameModal(true)}
                disabled={!selectedAssembly}
                sx={{ marginLeft: '0.5rem' }}
              >
                <EditIcon color="primary" />
              </IconButton>

              <ButtonWrapper onClick={() => setOpenNewAssemblyModal(true)}>
                {t('assembler.create')}
              </ButtonWrapper>
            </div>

            <div>
              <TextField
                id="outlined-select-version"
                select
                label="Version"
                value={selectedVersion}
                onChange={(e) => setSelectedVersion(e.target.value)}
              >
                {versionList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>

          <Typography
            variant="h4"
            className="h-full px-4"
            fontSize="1.5rem"
            fontWeight="500"
          >
            {t('assembler.summary')}
            {assemblyName ? `: ${assemblyName}` : ''}
          </Typography>

          <CollapsibleTableAssembler
            data={calculationProcessChain}
            totalCo2={totalEmissions}
          />

          <div className="flex flex-wrap gap-y-2 gap-x-3 mt-12 mb-2 justify-end">
            {/* Reset */}
            <ButtonWrapper
              onClick={() => setOpenModalYesNo(true)}
              color={eColor.secondary}
            >
              {t('assembler.reset')}
            </ButtonWrapper>

            <ModalYesNo
              title="assembler.reset"
              message="assembler.modal.resetMessage"
              yesOption={() => dispatch(sagaBuildAssemblyAction())}
              open={openModalYesNo}
              handleClose={() => setOpenModalYesNo(false)}
            />

            <ModalNewName
              title="Create Assembly"
              message="Create a New Assembly"
              open={openNewAssemblyModal}
              handleClose={() => setOpenNewAssemblyModal(false)}
              yesOption={(nameEntered) => {
                dispatch(sagaCreateAssemblyAction({ name: nameEntered }))
                setOpenNewAssemblyModal(false)
              }}
              existingNames={existingAssemblyNames}
            />

            {/* Save with checkmark feedback */}
            <ButtonWrapper onClick={handleSaveAssembly} disabled={isSaving}>
              {isSaving
                ? t('assembler.saving')
                : isSaved
                ? '✔ ' + t('assembler.saved')
                : t('assembler.save')}
            </ButtonWrapper>
          </div>
        </PaperWrapper>
      ) : (
        <div></div>
      )}

      {/* Overview Tab */}
      {activeTab === 1 ? (
        <AssemblerOverview
          currentAssemblyData={currentAssemblyData}
          totalEmissions={totalEmissions}
        />
      ) : (
        <div></div>
      )}

      {/* ModalRename for changing the currently selected assembly's name */}
      <ModalRename
        title="Rename Assembly"
        message="Enter a new name for this assembly."
        currentName={assemblyName}
        open={openRenameModal}
        handleClose={() => setOpenRenameModal(false)}
        yesOption={(newName: string) => {
          handleRenameAssembly(newName)
          setOpenRenameModal(false) // close after dispatch
        }}
        existingNames={existingAssemblyNames}
      />
    </ContentBodyWrapper>
  )
}

// Exactly like your existing route pattern
const exportObj = {
  routeProps: {
    path: RoutePaths.toolboxAssembler,
    title: 'assembler.title',
    element: <Assembler />,
    roles: componentPermissions[componentNames.pageAssembler],
    requiresAuthentication: true,
  },
  name: 'Assembler',
}

export default exportObj
