import {
  getUserData,
  getUserCurrency,
  setUserCurrency,
  setUserLanguage,
} from './requests/user/userProfileRequest'
import { logoutUser } from './requests/user/logoutUserRequest'
import {
  getOrganizationProfileData,
  setOrganizationProfileData,
  updateOrganizationProfileData,
} from './requests/user/organisationProfileData'
import { deleteUser } from './requests/user/deleteUser'
import { getLatestExchangeRates } from './requests/currency/currencyRequests'
import { getDataHubData } from './requests/dataHub/dataHubRequests'
import { getDataHubCategories } from './requests/dataHub/getDataHubCategories'
import { dataHubSearch } from './requests/dataHub/search'
import { saveUserSearchParameters } from './requests/user/saveUserSearchSettings'
import { getSingleEntityRequest } from './requests/dataHub/singleEntityRequests'
import { getUserProfileUrls } from './requests/user/userProfileRequest'
import {
  deleteSearchParametersData,
  getSearchParametersData,
  UpdateSearchParameterStatusData,
} from './requests/user/searchParameters'
import { getCountryList } from './requests/country/countryRequests'
import { addNewOrganizationMember } from './requests/user/addNewOrganizationMember'
import { updateOrganizationMember } from './requests/user/updateOrganizationMember'
import { getOrganizationTeamMembersDataService } from './requests/user/getOrganizationTeamMembersData'
import { deleteTeamMember } from './requests/user/teamMember'
import { activateDeactivateTeamMember } from './requests/user/teamMember'
import { cancelSubscriptionRenewal } from './requests/subscription/cancelSubscriptionRenewal'
import { buildAssembly, getAssemblyListing, getSearchResults, getSingleEntity, getAssembly, createNewAssembly, saveAssemblyModel, renameAssembly, deleteAssembly } from './requests/assembler/assemblerRequests'

const apiRequests = {
  getUserData,
  getUserCurrency,
  setUserCurrency,
  setUserLanguage,
  logoutUser,
  getOrganizationProfileData,
  setOrganizationProfileData,
  updateOrganizationProfileData,
  getOrganizationTeamMembersDataService,
  deleteUser,
  saveUserSearchParameters,
  getLatestExchangeRates,
  getCountryList,
  getDataHubData,
  getDataHubCategories,
  dataHubSearch,
  getSingleEntityRequest,
  getUserProfileUrls,
  getSearchParametersData,
  deleteSearchParametersData,
  UpdateSearchParameterStatusData,
  addNewOrganizationMember,
  updateOrganizationMember,
  deleteTeamMember,
  activateDeactivateTeamMember,
  cancelSubscriptionRenewal,
  buildAssembly,
  getAssembly,
  deleteAssembly,
  renameAssembly,
  saveAssemblyModel,
  createNewAssembly,
  getSearchResults,
  getAssemblyListing,
  getSingleEntity
}

export default apiRequests
