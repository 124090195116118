import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { iSingleEntity, iSingleEntityState } from 'store/types'

// for parametered slices
// import type { PayloadAction } from '@reduxjs/toolkit'

//get current reducer state
export const singleEntityState = (state: RootState) => state.singleEntityData

export const initialState: iSingleEntityState = {
  singleEntity: null,
}

//reducer Object
const singleEntitySlice = createSlice({
  name: 'singleEntity',
  initialState,
  reducers: {
    setSingleEntity: (state, action: PayloadAction<iSingleEntity | null>) => {
      const newEntity: iSingleEntity | null = action.payload
      state.singleEntity = newEntity
    },
  },
})

// Action creators are generated for each case reducer function
export const { setSingleEntity } = singleEntitySlice.actions

export default singleEntitySlice.reducer
