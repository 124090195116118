import { call, put, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import apiRequests from 'config/services'
import { setAssemblyListing } from 'store/slices/assemblyListingSlice'
import { assemblerConstants, sagaGetSearchResultsAction } from 'saga/actions/assembler'
import { iAssemblyListingResponse } from 'store/types/assembler'
import { SagaIterator } from 'redux-saga'

export function* getAssemblyListingSaga(action: PayloadAction<string>): SagaIterator {
  try {

    // Make sure getSearchResults is a normal function (returning a Promise),
    // or if it's a saga, we call it via yield* or rework. Typically it's a Promise-based function.
    const data: iAssemblyListingResponse = yield call(apiRequests.getAssemblyListing);

    console.warn("getAssemblyListing called", data);

    // save search results to global state
    yield put(setAssemblyListing(data));

  } catch (e) {
    console.warn("Error in getSearchResultsSaga:", e);
  }
}

// Listener saga
export function* getAssemblyListingHook() {
  // on SAGA_GET_SEARCH_RESULTS action, run getSearchResultsSaga
  yield takeLatest(assemblerConstants.SAGA_GET_ASSEMBLY_LISTING, getAssemblyListingSaga)
}
