// File: components/AssemblerOverview.tsx

import React, { FC, useRef } from 'react'
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@mui/material'
import {
  Cloud,
  LocalGasStation,
  Forest,
  EmojiNature,
  FlightTakeoff,
  CallMade,
  CallReceived,
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'
import PaperWrapper from 'components/custom/PaperWrapper'
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts'

interface AssemblerOverviewProps {
  currentAssemblyData: any
  totalEmissions: number
}

export const AssemblerOverview: FC<AssemblerOverviewProps> = ({
  currentAssemblyData,
  totalEmissions,
}) => {
  const { t } = useTranslation()

  // Example fields
  const assemblyName =
    currentAssemblyData?.result?.assemblyName || 'My Assembly'
  const assemblyId = currentAssemblyData?.result?.assemblyId || '12345-ABC'
  const standard = 'ISO 14067:2018, GHG Protocol'
  const systemBoundaries = 'Cradle to Gate'
  const databaseVersion = 'sustamize v3.1.0'

  // Emissions breakdown
  const fuelValue = totalEmissions * 0.975
  const lulucValue = totalEmissions * 0.01
  const biogenicValue = totalEmissions * 0.015
  const airplaneValue = 0

  // Category breakdown (vertical)
  const categoryData = [
    { name: 'Fuel', value: fuelValue },
    { name: 'LULUC', value: lulucValue },
    { name: 'Biogenic', value: biogenicValue },
  ]
  const categoryColors = ['#4caf50', '#ff9800', '#2196f3']

  // Direct vs. Indirect (also vertical, same size 220×220)
  const directEmissions = totalEmissions * 0.1
  const indirectEmissions = totalEmissions * 0.9
  const directIndirectData = [
    { name: 'Direct', value: directEmissions },
    { name: 'Indirect', value: indirectEmissions },
  ]
  const directIndirectColors = ['#9c27b0', '#3f51b5']

  // Printing
  const componentRef = useRef<HTMLDivElement>(null)
  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    documentTitle: 'AssemblerOverview',
  })

  return (
    <PaperWrapper>
      <Box ref={componentRef} sx={{ p: 2 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {t('assembler.overview')}
        </Typography>

        {/* Table of Name/ID/etc. */}
        <Table size="small" sx={{ mb: 3 }}>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
              <TableCell>{assemblyName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>ID</TableCell>
              <TableCell>{assemblyId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Image</TableCell>
              <TableCell>
                <Box
                  sx={{
                    border: '2px dashed #fa951d',
                    color: '#fa951d',
                    width: 200,
                    height: 120,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    borderRadius: '4px',
                    ':hover': {
                      backgroundColor: 'rgba(250,149,29, 0.1)',
                    },
                  }}
                  onClick={() => alert('TODO: File upload logic')}
                >
                  + Upload Image
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>
                Accounting Standard
              </TableCell>
              <TableCell>{standard}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>
                System Boundaries
              </TableCell>
              <TableCell>{systemBoundaries}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>
                Database Version
              </TableCell>
              <TableCell>{databaseVersion}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {/* 
          Row with 4 segments:
          (A) Category chart (220x220)
          (B) Fuel breakdown table
          (C) Direct vs Indirect chart (220x220 vertical)
          (D) Direct/Indirect table
        */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center', // center horizontally
            alignItems: 'flex-start',
            gap: 3,
            flexWrap: 'nowrap',
            // wrap on print so content isn't clipped
            '@media print': {
              flexWrap: 'wrap',
              overflow: 'visible',
            },
          }}
        >
          {/* (A) Category Breakdown */}
          <Box sx={{ width: 280, height: 220, flex: '0 0 auto' }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Category Breakdown
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={categoryData}
                margin={{ top: 10, right: 15, left: 10, bottom: 10 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  label={{
                    value: 'kgCO2e',
                    angle: -90,
                    position: 'insideLeft',
                    offset: -15,
                  }}
                />
                <Tooltip />
                <Bar dataKey="value">
                  {categoryData.map((entry, idx) => (
                    <Cell
                      key={`catCell-${idx}`}
                      fill={categoryColors[idx] ?? '#8884d8'}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>

          {/* (B) Fuel / LULUC / Biogenic / Airplane + total table */}
          <Box sx={{ minWidth: 280, flex: '0 0 auto' }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Emissions Breakdown
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'auto auto',
                columnGap: 2,
                borderBottom: '1px solid #ccc',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Cloud fontSize="medium" sx={{ mr: 1 }} />
                <Typography variant="body1" fontWeight="bold" align="left">
                  Total
                </Typography>
              </Box>

              <Typography variant="body1" fontWeight="bold" align="right">
                {totalEmissions.toFixed(2)} kgCO2e
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'auto auto',
                columnGap: 2,
                rowGap: 1,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LocalGasStation fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="body2">Fuel</Typography>
              </Box>
              <Typography variant="body2" align="right">
                {fuelValue.toFixed(2)}
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Forest fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="body2">LULUC</Typography>
              </Box>
              <Typography variant="body2" align="right">
                {lulucValue.toFixed(2)}
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <EmojiNature fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="body2">Biogenic</Typography>
              </Box>
              <Typography variant="body2" align="right">
                {biogenicValue.toFixed(2)}
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FlightTakeoff fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="body2">Airplane</Typography>
              </Box>
              <Typography variant="body2" align="right">
                {airplaneValue.toFixed(2)}
              </Typography>
            </Box>
          </Box>

          {/* (C) Direct vs Indirect (vertical) */}
          <Box sx={{ width: 280, height: 220, flex: '0 0 auto' }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Direct vs. Indirect
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={directIndirectData}
                margin={{ top: 10, right: 15, left: 10, bottom: 10 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  label={{
                    value: 'kgCO2e',
                    angle: -90,
                    position: 'insideLeft',
                    offset: -15,
                  }}
                />
                <Tooltip />
                <Bar dataKey="value">
                  {directIndirectData.map((entry, idx) => (
                    <Cell
                      key={`diCell-${idx}`}
                      fill={directIndirectColors[idx] ?? '#82ca9d'}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>

          {/* (D) Direct/Indirect numeric table */}
          <Box sx={{ width: 280, flex: '0 0 auto' }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Totals
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'auto auto',
                columnGap: 1,
                rowGap: 1,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CallMade fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="body2">Direct</Typography>
              </Box>
              <Typography variant="body2" align="right">
                {directEmissions.toFixed(2)}
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CallReceived fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="body2">Indirect</Typography>
              </Box>
              <Typography variant="body2" align="right">
                {indirectEmissions.toFixed(2)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Export PDF button (hidden when printing) */}
      <Box sx={{ mt: 2, '@media print': { display: 'none' } }}>
        <Button variant="contained" onClick={() => handlePrint()}>
          Export PDF
        </Button>
      </Box>
    </PaperWrapper>
  )
}
