import { iLooseObject } from 'pages/DataHub/filters/types'
import { eScope } from 'store/types'
import GlobeSVG from 'assets/icons/globe.svg'
import RecycleSVG from 'assets/icons/recycle.svg'
import PrimarySVG from 'assets/icons/solution.svg'
import TimetableSVG from 'assets/icons/timetable.svg'
import CubeSVG from 'assets/icons/cube.svg'
import CarbonDioxideSVG from 'assets/icons/carbon-dioxide.svg'
import BoxSVG from 'assets/icons/box.svg'

import {
  iCo2AccordionEntity,
  iCo2CompositesInstanceData,
  iCo2ConstructionInstanceData,
  iCo2ElementsInstanceData,
  iCo2Emission,
  iCo2EmployeesInstanceData,
  iCo2EnergyInstanceData,
  iCo2EquipmentInstanceData,
  iCo2ManufacturingInstanceData,
  iCo2MetalInstanceData,
  iCo2PackagingInstanceData,
  iCo2PolymersInstanceData,
  iCo2SingleEntity,
  iCo2SintersInstanceData,
  iEnergyProductionShare,
  // iCo2TransportationInstanceData,
  iCustomField,
} from 'store/types'

export const getCookie = (name: string): string => {
  const nameLenPlus = name.length + 1
  return (
    document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus))
      })[0] || ''
  )
}
export const deleteCookie = (name: string, path?: string, domain?: string) => {
  if (getCookie(name)) {
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT'
  }
}
//
// local storage methods
//
export const setLocal = (name: string, value: string) => {
  window.localStorage.setItem(name, value)
}
export const getLocal = (name: string) => {
  const value = window.localStorage.getItem(name)
  return value
}
export const clearLocal = (name: string) => {
  window.localStorage.removeItem(name)
}

export const getToken = (): string => {
  return getLocal('jwt') || ''
}

export const removeDash = (item: string) => {
  return item.split('-').map(capitaliseString).join(' ')
}

export const capitaliseString = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const capitaliseEveryWordInString = (string: string): string => {
  let newString = string
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
  return newString
}

export const addSpaceInCommaSeparatedString = (string: string): string => {
  return string.replace(/,/g, ', ')
}

export const urlStringParamsToObject = (
  urlParamsPath: string,
  removeFirstCharacter: boolean = true
) => {
  if (urlParamsPath.length > 0)
    return JSON.parse(
      '{"' +
        urlParamsPath.substring(1).replace(/&/g, '","').replace(/=/g, '":"') +
        '"}',
      function (key, value) {
        return key === '' ? value : decodeURIComponent(value)
      }
    )
  else return {}
}
export const serializeUrlAttributes = (obj: Object | any | undefined) => {
  var str = []

  /** Loop the object */
  /** if value is regular string */
  for (const param in obj)
    if (obj.hasOwnProperty(param) && !Array.isArray(obj[param])) {
      str.push(`${encodeURIComponent(param)}=${encodeURIComponent(obj[param])}`)

      /** if value is array */
    } else if (obj.hasOwnProperty(param) && Array.isArray(obj[param])) {
      /** Loop the array */
      ;(obj[param] as string[]).forEach((value) => {
        str.push(`${encodeURIComponent(param)}=${encodeURIComponent(value)}`)
      })
    }
  return str.join('&')
}
export const appendContext = (
  currentContextValue: string,
  newValue: string,
  filterName: string
): string => {
  let contextArr = currentContextValue ? currentContextValue.split('.') : [] // 0 is empty, 1 is base and 2 is sub context
  if (filterName === 'type') {
    contextArr = contextArr[1] ? ['', contextArr[1], newValue] : ['', newValue]
  } else {
    contextArr = ['', newValue]
  }
  return contextArr ? contextArr.join('') : '.'
}

export const filterStateToUrlParams = (
  params: iLooseObject,
  filterData: iCustomField[], // redux filters
  filterToRemove: iCustomField | null = null // remove this filter from returned url params
): iLooseObject => {
  const newParams: iLooseObject = JSON.parse(JSON.stringify(params))
  filterData.forEach((element) => {
    if (element.value) {
      newParams[element.name] = element.value
    }
    if (element.name === filterToRemove?.name) {
      delete newParams[element.name]
    }
  })

  for (const [key] of Object.entries(params)) {
    if (key === 'contextName') {
      continue
    }
    const paramExistsInState =
      filterData.findIndex((item) => item.name === key) !== -1
    if (!paramExistsInState) {
      newParams[key] = undefined
    }
  }
  return newParams
}

/**
 * Function for converting URI search params into a loose object
 * @param urlPath The URI path segment
 * @returns A loose object, containing all URI search params
 * !!! IMPORTANT!!! the data it returns, only to be used with getUrlParamValue
 */
export const urlToObject = (urlPath: string): iLooseObject => {
  let regexPattern = /(?<=[?|&])[a-zA-Z0-9!@$%^*+%=.-]+/gi
  let match = urlPath.match(regexPattern)

  if (match === null) return {}

  let urlParams: iLooseObject = {}

  /** Loop all URI matches and set object */
  match.forEach((m) => {
    let keyValueArray = m.split(`=`)
    const [key, value] = keyValueArray

    if (value === '') return // ignore empty

    if (Object.keys(urlParams).includes(key)) {
      urlParams[key].push(ustringURLDecode(value))
    } else {
      urlParams[key] = [ustringURLDecode(value)]
    }
  })
  return urlParams
}
// check to see if a string array is in another string arrayß
export const isListInList = (arr: string[], target: string[]) =>
  target.every((v) => arr.includes(v))

export const getUrlParamValue = (
  params: iLooseObject,
  paramName: string,
  isStringWhenReturned: boolean = true
) => {
  if (
    params &&
    params[paramName] &&
    Array.isArray(params[paramName]) &&
    params[paramName].length > 0
  ) {
    return isStringWhenReturned ? params[paramName][0] : params[paramName]
  }

  return ''
}

export const ustringURLDecode = (str: string) => {
  return str ? decodeURIComponent(str.replace(/\+/g, ' ')) : ''
}
export const camelCaseToSpaces = (value: string) => {
  return (
    value
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase()
      })
  )
}

export const inArray = (terms: string[], searchTerms: string[]): boolean => {
  return (
    searchTerms.filter((item: string, index: number) => {
      for (var i = 0; i < terms.length; i++) {
        if (terms[i] && terms[i].toString().indexOf(item) !== -1) return true
      }

      return false
    }).length > 0
  )
}

export const booleanify = (value: string): boolean => {
  const truthy: string[] = ['true', 'True', '1']

  return truthy.includes(value)
}

export const createAccordionEntity = (
  co2Emission: iCo2Emission,
  category: string
): iCo2AccordionEntity | undefined => {
  const unit = co2Emission?.emissionFactorUnitSymbol
  // const formatDate = (timestamp: string) => {
  //   const date = new Date(timestamp);
  //   return date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit' });
  // };
  const validity = co2Emission?.year
  //const validTo = formatDate(co2Emission?.co2Base?.co2ValueEntries?.[0]?.validity?.validTo)
  const toTitleCase = (str: string) => {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  switch (category) {
    case '.energy.countries':
      const energyCo2Accordion: iCo2AccordionEntity = {
        emissionPrimary: {
          icon: PrimarySVG,
          value: co2Emission?.emissionFactorPrimary?.toString(),
          title: 'singleEntity.emissionFactor',
          unit,
        },

        validityPeriod: {
          icon: TimetableSVG,
          title: 'singleEntity.period',
          value: `${(validity)}`
        },
      }
      return energyCo2Accordion

    case '.logistics.packaging':
      const packagingCo2Acccordion: iCo2AccordionEntity = {
        emissionPerUnit: {
          icon: BoxSVG,
          value: co2Emission?.emissionFactorPrimary?.toString(),
          title: 'singleEntity.emissionFactorPerUnit',
          unit,
        },
        emissionPrimary: {
          icon: PrimarySVG,
          value: (co2Emission?.emissionFactorPrimary || 0).toString(),
          title: 'singleEntity.emissionFactor',
          unit: co2Emission?.emissionFactorUnitSymbol,
        },
        validityPeriod: {
          icon: TimetableSVG,
          title: 'singleEntity.period',
          value:
 `${(validity)}`
        },
      }
      return packagingCo2Acccordion

    case '.logistics.transportation':
      const transportationCo2Acccordion: iCo2AccordionEntity = {
        emissionPrimary: {
          icon: PrimarySVG,
          value: co2Emission?.emissionFactorPrimary?.toString(),
          title: 'singleEntity.emissionFactor',
          unit,
        },
        validityPeriod: {
          icon: TimetableSVG,
          title: 'singleEntity.period',
          value:
 `${(validity)}`
        },
      }

      return transportationCo2Acccordion

    case '.materials.composites':
      const compositeCo2Acccordion: iCo2AccordionEntity = {
        country: {
          icon: GlobeSVG,
          value: co2Emission?.country,
          title: 'singleEntity.country',
        },
        emissionPrimary: {
          icon: PrimarySVG,
          value: co2Emission?.emissionFactorPrimary?.toString(),
          title: 'singleEntity.emissionFactorPrimary',
          unit,
        },
        emissionRecycling: {
          icon: RecycleSVG,
          value: co2Emission?.emissionFactorRecycling?.toString(),
          title: 'singleEntity.emissionFactorRecycling',
          unit,
        },

        validityPeriod: {
          icon: TimetableSVG,
          title: 'singleEntity.period',
          value:
 `${(validity)}`
        },
      }
      return compositeCo2Acccordion

    case '.materials.metals':
      const metalCo2Acccordion: iCo2AccordionEntity = {
        country: {
          icon: GlobeSVG,
          value: co2Emission?.country,
          title: 'singleEntity.country',
        },
        emissionPrimary: {
          icon: PrimarySVG,
          value: co2Emission?.emissionFactorPrimary?.toString(),
          title: 'singleEntity.common',
          unit,
        },
        emissionRecycling: {
          icon: RecycleSVG,
          value: co2Emission?.emissionFactorRecycling?.toString(),
          title: 'singleEntity.commonRecycling',
          unit,
        },
        deliveryShape: {
          icon: CubeSVG,
          value: toTitleCase(co2Emission?.deliveryShape || ''), // Fallback to an empty string if deliveryShape is undefined
          title: 'singleEntity.deliveryShape',
        },
        validityPeriod: {
          icon: TimetableSVG,
          title: 'singleEntity.period',
          value:
 `${(validity)}`
        },
      }
      return metalCo2Acccordion

    case '.materials.construction':
      const constructionCo2Acccordion: iCo2AccordionEntity = {
        emissionPrimary: {
          icon: PrimarySVG,
          value: co2Emission?.emissionFactorPrimary?.toString(),
          title: 'singleEntity.emissionFactorTotal',
          unit,
        },
        co2PerUnit: {
          icon: CarbonDioxideSVG,
          value: co2Emission?.emissionFactorPrimary?.toString(),
          title: 'singleEntity.co2PerUnit',
          unit,
        },
        validityPeriod: {
          icon: TimetableSVG,
          title: 'singleEntity.period',
          value:
 `${(validity)}`
        },
      }
      return constructionCo2Acccordion

      case '.materials.elements': {
        const elementsCo2Acccordion: iCo2AccordionEntity = {
          country: {
            icon: GlobeSVG,
            value: co2Emission?.country,
            title: 'singleEntity.country',
          },
          emissionPrimary: {
            icon: PrimarySVG,
            value: co2Emission?.emissionFactorPrimary?.toString(),
            title: 'singleEntity.emissionFactorPrimary',
            unit,
          },
          // Only include emissionRecycling if emissionFactorRecycling exists
          ...(co2Emission?.emissionFactorRecycling && {
            emissionRecycling: {
              icon: RecycleSVG,
              value: co2Emission?.emissionFactorRecycling?.toString(),
              title: 'singleEntity.emissionFactorRecycling',
              unit,
            },
          }),
          validityPeriod: {
            icon: TimetableSVG,
            title: 'singleEntity.period',
            value: `${validity}`,
          },
        };
        return elementsCo2Acccordion;
      }

    case '.materials.polymers':
      const polymersCo2Acccordion: iCo2AccordionEntity = {
        country: {
          icon: GlobeSVG,
          value: co2Emission?.country,
          title: 'singleEntity.country',
        },
        emissionPrimary: {
          icon: PrimarySVG,
          value: co2Emission?.emissionFactorPrimary?.toString(),
          title: 'singleEntity.emissionFactorPrimaryCommon',
          unit,
        },
        emissionRecycling: {
          icon: RecycleSVG,
          value: co2Emission?.emissionFactorRecycling?.toString(),
          title: 'singleEntity.emissionFactorRecyclingCommon',
          unit,
        },
        validityPeriod: {
          icon: TimetableSVG,
          title: 'singleEntity.period',
          value:
 `${validity}`
        },
      }
      return polymersCo2Acccordion

    case '.materials.sinters':
      const sintersCo2Acccordion: iCo2AccordionEntity = {
        country: {
          icon: GlobeSVG,
          value: co2Emission?.country,
          title: 'singleEntity.country',
        },
        emissionPrimary: {
          icon: PrimarySVG,
          value: co2Emission?.emissionFactorPrimary?.toString(),
          title: 'singleEntity.emissionFactor',
          unit,
        },
        validityPeriod: {
          icon: TimetableSVG,
          title: 'singleEntity.period',
          value:
 `${validity}`
        },
      }
      return sintersCo2Acccordion

    case '.production.equipment':
      const equipmentCo2Acccordion: iCo2AccordionEntity = {
        emissionPrimary: {
          icon: PrimarySVG,
          value: co2Emission?.emissionFactorPrimary?.toString(),
          title: 'singleEntity.emissionFactor',
          unit,
        },

        validityPeriod: {
          icon: TimetableSVG,
          title: 'singleEntity.period',
          value:
 `${validity}`
        },
      }
      return equipmentCo2Acccordion

    case '.production.employees':
      const employeesCo2Acccordion: iCo2AccordionEntity = {
        emissionPrimary: {
          icon: PrimarySVG,
          value: co2Emission?.emissionFactorPrimary?.toString(),
          title: 'singleEntity.emissionFactor',
          unit,
        },

        validityPeriod: {
          icon: TimetableSVG,
          title: 'singleEntity.period',
          value:
 `${validity}`
        },
      }
      return employeesCo2Acccordion

    case '.production.manufacturing':
      const manufacturingCo2Acccordion: iCo2AccordionEntity = {
        country: {
          icon: GlobeSVG,
          value: co2Emission?.country,
          title: 'singleEntity.period',
        },
        emissionPrimary: {
          icon: PrimarySVG,
          title: 'singleEntity.emissionFactor',
          value: co2Emission?.emissionFactorPrimary?.toString(),
          unit,
        },

        validityPeriod: {
          icon: TimetableSVG,
          title: 'singleEntity.period',
          value:
 `${validity}`
        },
      }
      return manufacturingCo2Acccordion
    case '.electronics':
      const electroniocsCo2Accordion: iCo2AccordionEntity = {
        emissionPrimary: {
          icon: PrimarySVG,
          value: co2Emission?.emissionFactorPrimary?.toString(),
          title: 'singleEntity.emissionFactor',
          unit,
        },
        validityPeriod: {
          icon: TimetableSVG,
          title: 'singleEntity.period',
          value:
 `${validity}`
        },
      }
      return electroniocsCo2Accordion
  }
  return undefined
}


export const createSingleEntityObj = (
  entityData: any,
  category: string,
  index: number
): iCo2SingleEntity | undefined => {
  const unitForTitle: string | undefined =
    entityData.co2Emission[index]?.emissionFactorUnitSymbol
    const mapEnergyShares = (energyShares: iEnergyProductionShare[], types: string[]) => {
      return types?.reduce((acc, type) => {
        const camelCaseType = type
          .toLowerCase()
          .split(' ')
          .map((word, index) => index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1))
          .join('');
        const share = energyShares?.find(share => share.productionTypeEn === type);
        acc[camelCaseType] = share ? `${(share.shareAmount * 100).toFixed(2)}%` : '0.00%';
        return acc;
      }, {} as Record<string, string>);
    };
  
    const sumEnergyShares = (energyShares: iEnergyProductionShare[]) => {
      const total = energyShares?.reduce((sum, share) => sum + share.shareAmount, 0);
      return `${(total * 100).toFixed(2)}%`;
    };

   switch (category) {
  //   case '.energy':
  //     const co2Energy: iCo2EnergyInstanceData = entityData;

      // const { 
      //   nonRenewable = [], 
      //   conventionalThermal = [], 
      //   renewable = [] 
      // } = co2Energy?.co2Emission?.[0]?.co2Base?.co2ValueEntries?.[0]?.calculationProcessModel?.energyProductionShares || {};
      
      // const energyCo2Obj: iCo2SingleEntity = {
      //   primaryColumn: {
      //     title: { name: '' },
      //     primary: {
      //       totalNonRenewableEnergy: sumEnergyShares(nonRenewable),
      //       ...mapEnergyShares(nonRenewable, ['Coal', 'Oil', 'Natural Gas']),
      //     },
      //   },
      //   recyclingColumn: {
      //     title: { name: '' },
      //     recycling: {
      //       totalConventionalThermal: sumEnergyShares(conventionalThermal),
      //       ...mapEnergyShares(conventionalThermal, ['Nuclear']),
      //     },
      //   },
      //   infoColumn: {
      //     title: '',
      //     information: {
      //       totalRenewables: sumEnergyShares(renewable),
      //       ...mapEnergyShares(renewable, ['Hydro', 'Wind', 'Solar', 'Other Renewables']),
      //     },
      //   },
      // };
      
      // return energyCo2Obj;

    case '.logistics.packaging':
      const co2Packaging: iCo2PackagingInstanceData = entityData

      const packagingCo2Obj: iCo2SingleEntity = {
        primaryColumn: {
          title: { name: 'singleEntity.unitReferences' },
          primary: {
            thickness: co2Packaging?.information?.technical?.materialShares?.[0]?.measurement?.default + ' ' + co2Packaging?.information?.technical?.materialShares?.[0]?.measurement?.unit,
            emissionPerUnit: `${co2Packaging.co2Emission[
              index
            ]?.emissionFactorPrimary?.toString()} ${
              co2Packaging.co2Emission[index]?.emissionFactorUnitSymbol
            }`,
          },
        },
      }
      return packagingCo2Obj

    case '.logistics.transportation':
      // const co2Transportation: iCo2TransportationInstanceData = entityData

      // const transportationCo2Obj: iCo2SingleEntity = {
      //   primaryColumn: {
      //     title: { name: 'singleEntity.primary', unit: unitForTitle },
      //     primary: {
      //       common: co2Transportation.co2Emission[index]?.co2Base.common,
      //       min: co2Transportation.co2Emission[index]?.co2Base.min,
      //       max: co2Transportation.co2Emission[index]?.co2Base.max,
      //       mean: co2Transportation.co2Emission[index]?.co2Base.mean,
      //       median: co2Transportation.co2Emission[index]?.co2Base.median,
      //     },
      //   },
      // }

      return undefined

    case '.materials.composites':
      const co2Composite: iCo2CompositesInstanceData = entityData

      const compositeCo2Obj: iCo2SingleEntity = {
        primaryColumn: {
          title: { name: 'singleEntity.primary', unit: unitForTitle },
          primary: {
            common: co2Composite.co2Emission[index]?.co2Base?.common,
            min: co2Composite.co2Emission[index]?.co2Base?.min,
            max: co2Composite.co2Emission[index]?.co2Base?.max,
            mean: co2Composite.co2Emission[index]?.co2Base?.mean,
            median: co2Composite.co2Emission[index]?.co2Base?.median,
          },
        },
        recyclingColumn: {
          title: {
            name: 'singleEntity.recycling',
            unit: unitForTitle,
          },
          recycling: {
            common: co2Composite.co2Emission[index]?.co2Recycling?.common,
            min: co2Composite.co2Emission[index]?.co2Recycling?.min,
            max: co2Composite.co2Emission[index]?.co2Recycling?.max,
            mean: co2Composite.co2Emission[index]?.co2Recycling?.mean,
            median: co2Composite.co2Emission[index]?.co2Recycling?.median,
          },
        },
      }
      return compositeCo2Obj

    case '.materials.metals':
      const co2Metal: iCo2MetalInstanceData = entityData
      const { 
        common = 0, 
        min = 0, 
        max = 0, 
        mean = 0, 
        median = 0 
      } = co2Metal.co2Emission[index]?.co2Recycling || {};
      const isRecyclingVisible: boolean =
        [common, min, max, mean, median].filter((item) => item !== null)
          .length > 0

      const recyclingCustomColumnData = {
        common,
        min,
        max,
        mean,
        median,
      }
      const metalCo2Obj: iCo2SingleEntity = {
        primaryColumn: {
          title: { name: 'singleEntity.primary', unit: unitForTitle },
          primary: {
            common: co2Metal.co2Emission[index]?.co2Base.common,
            min: co2Metal.co2Emission[index]?.co2Base.min,
            max: co2Metal.co2Emission[index]?.co2Base.max,
            mean: co2Metal.co2Emission[index]?.co2Base.mean,
            median: co2Metal.co2Emission[index]?.co2Base.median,
          },
        },
        recyclingColumn: {
          title: {
            name: 'singleEntity.recycling',
            unit: unitForTitle,
          },
          recycling:
            isRecyclingVisible === true ? { ...recyclingCustomColumnData } : {},
        },
        infoColumn: {
          title: 'singleEntity.information',
          information: {
            //oldestRecord: co2Metal.co2Emission[index]?.oldestRecord,
            //newestRecord: co2Metal.co2Emission[index]?.newestRecord,
            totalRecords: co2Metal.co2Emission[index]?.totalRecords,
            //averageSourceQuality:
              //co2Metal.co2Emission[index]?.averageRecordQuality,
          },
        },
      }
      return metalCo2Obj

    case '.materials.construction':
      //main Data
      const co2Construction: iCo2ConstructionInstanceData = entityData
      // emissionFactorUptake
      const isPrimaryCo2UptakeValueAvailable =
        co2Construction &&
        co2Construction.co2Emission[index] &&
        co2Construction.co2Emission[index]?.emissionFactorPrimary &&
        co2Construction.co2Emission[index]?.emissionFactorPrimary
      const primaryCo2UptakeValue = isPrimaryCo2UptakeValueAvailable
        ? co2Construction.co2Emission[index]?.emissionFactorPrimary?.toString() +
          ' ' +
          co2Construction.co2Emission[index]?.emissionFactorUnitSymbol
        : 'general.na'
      // emissionWithoutUptake
      const isEmissionFactorUptakeValueAvailable =
        co2Construction &&
        co2Construction.co2Emission[index] &&
        co2Construction.co2Emission[index]?.emissionFactorPrimary &&
        co2Construction.co2Emission[index]?.emissionFactorPrimary

      const primaryEmissionWithoutUptake = isEmissionFactorUptakeValueAvailable
        ? co2Construction.co2Emission[
            index
          ]?.emissionFactorPrimary?.toString() +
          ' ' +
          co2Construction.co2Emission[index]?.emissionFactorUnitSymbol
        : 'general.na'

      const constructionCo2Obj: iCo2SingleEntity = {
        primaryColumn: {
          title: { name: 'singleEntity.information' },
          primary: {
            co2Uptake: primaryCo2UptakeValue,
            emissionsWithoutUptake: primaryEmissionWithoutUptake,
            referenceUnit: co2Construction.co2Emission[index]?.emissionFactorUnitSymbol,
          },
        },
      }
      return constructionCo2Obj

      case '.materials.elements': {
        const co2Elements: iCo2ElementsInstanceData = entityData;
      
        const elementsCo2Obj: iCo2SingleEntity = {
          primaryColumn: {
            title: { name: 'singleEntity.primary', unit: unitForTitle },
            primary: {
              common: co2Elements.co2Emission[index]?.co2Base?.common,
              min: co2Elements.co2Emission[index]?.co2Base?.min,
              max: co2Elements.co2Emission[index]?.co2Base?.max,
              mean: co2Elements.co2Emission[index]?.co2Base?.mean,
              median: co2Elements.co2Emission[index]?.co2Base?.median,
            },
          },
          // Only include recyclingColumn if co2Recycling exists
          ...(co2Elements.co2Emission[index]?.co2Recycling && {
            recyclingColumn: {
              title: {
                name: 'singleEntity.recycling',
                unit: unitForTitle,
              },
              recycling: {
                common: co2Elements.co2Emission[index]?.co2Recycling?.common,
                min: co2Elements.co2Emission[index]?.co2Recycling?.min,
                max: co2Elements.co2Emission[index]?.co2Recycling?.max,
                mean: co2Elements.co2Emission[index]?.co2Recycling?.mean,
                median: co2Elements.co2Emission[index]?.co2Recycling?.median,
              },
            },
          }),
        };
      
        return elementsCo2Obj;
      }

    case '.materials.polymers':
      const co2Polymers: iCo2PolymersInstanceData = entityData

      const polymersCo2Obj: iCo2SingleEntity = {
        primaryColumn: {
          title: { name: 'singleEntity.primary', unit: unitForTitle },
          primary: {
            common: co2Polymers.co2Emission[index]?.co2Base.common,
            min: co2Polymers.co2Emission[index]?.co2Base.min,
            max: co2Polymers.co2Emission[index]?.co2Base.max,
            mean: co2Polymers.co2Emission[index]?.co2Base.mean,
            median: co2Polymers.co2Emission[index]?.co2Base.median,
          },
        },
        recyclingColumn: {
          title: { name: 'singleEntity.recycling', unit: unitForTitle },
          recycling: {
            common: co2Polymers.co2Emission[index]?.co2Recycling?.common,
            min: co2Polymers.co2Emission[index]?.co2Recycling?.min,
            max: co2Polymers.co2Emission[index]?.co2Recycling?.max,
            mean: co2Polymers.co2Emission[index]?.co2Recycling?.mean,
            median: co2Polymers.co2Emission[index]?.co2Recycling?.median,
          },
        },
      }
      return polymersCo2Obj

    case '.materials.sinters':
      const co2Sinters: iCo2SintersInstanceData = entityData

      const sintersCo2Obj: iCo2SingleEntity = {
        primaryColumn: {
          title: { name: 'singleEntity.primary', unit: unitForTitle },
          primary: {
            min: co2Sinters.co2Emission[index]?.co2Base.min,
            max: co2Sinters.co2Emission[index]?.co2Base.max,
            mean: co2Sinters.co2Emission[index]?.co2Base.mean,
            median: co2Sinters.co2Emission[index]?.co2Base.median,
          },
        },
      }
      return sintersCo2Obj

    case '.production.equipment':
      const co2Equipment: iCo2EquipmentInstanceData = entityData
      const investmentCurrencyUnit = co2Equipment.information?.technical?.investment?.currency
      const electricConsumptionUnit = co2Equipment.information?.technical?.electricConsumption?.[0]?.unit
      const floorSizeUnit = co2Equipment.information?.technical?.dimensions?.floorSize?.equipment?.unit
      const equipmentCo2Obj: iCo2SingleEntity = {
        primaryColumn: {
          title: { name: 'singleEntity.investment' },
          primary: {
            equipmentInvestment:
              co2Equipment.information?.technical?.investment?.equipmentInvestment?.toString() +
              ' ' +
              investmentCurrencyUnit,
            fundamentInvestment:
              co2Equipment.information?.technical?.investment?.fundamentInvestment?.toString() +
              ' ' +
              investmentCurrencyUnit,
            setupInvestment:
              co2Equipment.information?.technical?.investment?.setupInvestment?.toString() +
              ' ' +
              investmentCurrencyUnit,
            additionalInvestment:
              co2Equipment.information?.technical?.investment?.additionalInvestment?.toString() +
              ' ' +
              investmentCurrencyUnit,
          },
        },
        recyclingColumn: {
          title: { name: 'singleEntity.workspace' },
          recycling: {
            equipmentFloorsize:
              co2Equipment.information?.technical?.dimensions?.floorSize?.equipment?.size?.toString() +
              ' ' +
              floorSizeUnit,
            workspaceFloorsize:
              co2Equipment.information?.technical?.dimensions?.floorSize?.workspace?.size?.toString() +
              ' ' +
              floorSizeUnit,
            electricConsumption:
              co2Equipment.information?.technical?.electricConsumption?.[0]?.amount?.toString() +
              ' ' +
              electricConsumptionUnit,
          },
        },
      }
      return equipmentCo2Obj

    case '.production.employees':
      const co2Employees: iCo2EmployeesInstanceData = entityData

      const employeesCo2Obj: iCo2SingleEntity = {
        primaryColumn: {
          title: { name: 'singleEntity.information' },
          primary: {
            //oldestRecord: co2Employees.co2Emission[index]?.oldestRecord,
            //newestRecord: co2Employees.co2Emission[index]?.newestRecord,
            totalRecords: co2Employees.co2Emission[index]?.totalRecords,
          },
        },
      }
      return employeesCo2Obj

    case '.production.manufacturing':
      const co2Manufacturing: iCo2ManufacturingInstanceData = entityData
      // const efficiencyCoefficientUnit = co2Manufacturing.information?.technical?.efficiency?.unit

      const manufacturingCo2Obj: iCo2SingleEntity = {
        primaryColumn: {
          title: { name: 'singleEntity.information' },
          primary: {
            min: co2Manufacturing.co2Emission[index]?.co2Base.min,
            max: co2Manufacturing.co2Emission[index]?.co2Base.max,
            mean: co2Manufacturing.co2Emission[index]?.co2Base.mean,
            median: co2Manufacturing.co2Emission[index]?.co2Base.median,
          },
        },
        recyclingColumn: {
          title: { name: 'singleEntity.energyInformation' },
          recycling: {
            // efficiencyCoefficient:
            //   co2Manufacturing.information?.technical?.efficiency?.efficiencyCoefficient?.toString() +
            //   ' ' +
            //   efficiencyCoefficientUnit,
            energyConsumption:
               co2Manufacturing.information?.technical?.energyConsumption?.value?.toString() +
               ' ' +
               co2Manufacturing.information?.technical?.energyConsumption?.unit
          },
        },
      }
      return manufacturingCo2Obj
  }
  return entityData
}

export const urlToIcon = (pathName: string) => {
  switch (pathName) {
    case '':
      return ''
    default:
      return ''
  }
}

export const extractNumbersFromString = (value: string): number[] => {
  const regex = /(\d+(?:\.\d+)?)(?:\s|$)/g
  const numbers = []
  let match

  while ((match = regex.exec(value)) !== null) {
    numbers.push(parseFloat(match[1]))
  }
  return numbers
}

export const oScopeLabels: {[key in eScope]: string} = {
    'tank-to-wheel': 'Tank-to-wheel',
    'well-to-wheel': 'Well-to-wheel',
    'well-to-tank' : 'Well-to-tank',
    'cradle-to-cradle' : 'Cradle-to-cradle',
    'cradle-to-gate' : 'Cradle-to-gate',
    'cradle-to-grave' : 'Cradle-to-grave',
    'gate-to-gate' : 'Gate-to-gate'
};

export const getScopeLabel = (scope: eScope): string => oScopeLabels[scope];