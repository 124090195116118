import * as React from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import CbamSourceData from '../../pages/DataHub/AdditionalResources/CbamSourceData.json'

interface Detail {
  cn_code: string
  description: string
  direct_emissions: number
  indirect_emissions: number
  total_emissions: number
  children: any[]
}

function createData(agg_goods_category: string, details: Detail[]) {
  return {
    agg_goods_category,
    details,
  }
}

function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props
  const [open_1, setOpen_1] = React.useState(false)
  const [open_2, setOpen_2] = React.useState<{ [key: string]: boolean }>({})

  const handleToggle = (code: string) => {
    setOpen_2((prevState) => ({
      ...prevState,
      [code]: !prevState[code],
    }))
  }

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' },
          '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }, // Change color on hover
          cursor: 'pointer', // Change cursor to pointer
          overflow: 'hidden',
          backgroundColor: open_1 ? 'rgba(0, 0, 0, 0.04)' : 'inherit',
        }}
        onClick={() => setOpen_1(!open_1)}
      >
        <TableCell component="th" scope="row">
          {row.agg_goods_category}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{ '&:hover': { backgroundColor: 'transparent' } }}
          >
            {open_1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
          }}
          colSpan={5}
        >
          <Collapse in={open_1} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 0 }}>
              <Table aria-label="cn_details">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: '#fa951d',
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      CN Code
                    </TableCell>
                    <TableCell sx={{ color: '#fa951d', fontWeight: 'bold' }}>
                      Description
                    </TableCell>
                    <TableCell
                      sx={{
                        color: '#fa951d',
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                      }}
                      align="right"
                    >
                      Direct Emissions
                    </TableCell>
                    <TableCell
                      sx={{
                        color: '#fa951d',
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                      }}
                      align="right"
                    >
                      Indirect Emissions
                    </TableCell>
                    <TableCell
                      sx={{
                        color: '#fa951d',
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                      }}
                      align="right"
                    >
                      Total Emissions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.details.map((detailsRow) => (
                    <React.Fragment key={detailsRow.cn_code}>
                      {detailsRow.children.length === 0 ? (
                        <>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ whiteSpace: 'nowrap', width: '15%' }}
                            >
                              {detailsRow.cn_code}
                            </TableCell>
                            <TableCell align="left" sx={{ width: '55%' }}>
                              {detailsRow.description}
                            </TableCell>
                            <TableCell align="right" sx={{ width: '10%' }}>
                              {detailsRow.direct_emissions.toFixed(2)}
                            </TableCell>
                            <TableCell align="right" sx={{ width: '10%' }}>
                              {detailsRow.indirect_emissions.toFixed(2)}
                            </TableCell>
                            <TableCell align="right" sx={{ width: '10%' }}>
                              {detailsRow.total_emissions.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <>
                          <TableRow
                            sx={{
                              '& > *': { borderBottom: 'unset' },
                              '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                              }, // Change color on hover
                              cursor: 'pointer', // Change cursor to pointer
                              overflow: 'hidden',
                              backgroundColor: open_2[detailsRow.cn_code]
                                ? 'rgba(0, 0, 0, 0.04)'
                                : 'inherit',
                            }}
                            onClick={() => handleToggle(detailsRow.cn_code)}
                          >
                            <TableCell component="th" sx={{ width: '15%' }}>
                              {detailsRow.cn_code}
                            </TableCell>
                            <TableCell sx={{ width: '55%' }}>
                              {detailsRow.description}
                            </TableCell>
                            <TableCell
                              colSpan={3}
                              align="right"
                              sx={{ width: '30%' }}
                            >
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                sx={{
                                  '&:hover': {
                                    backgroundColor: 'transparent',
                                  },
                                }}
                              >
                                {open_2[detailsRow.cn_code] ? (
                                  <KeyboardArrowUpIcon
                                    sx={{ fontSize: '1rem' }}
                                  />
                                ) : (
                                  <KeyboardArrowDownIcon
                                    sx={{ fontSize: '1rem' }}
                                  />
                                )}
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{
                                paddingBottom: 0,
                                paddingTop: 0,
                                paddingLeft: 0,
                                paddingRight: 0,
                              }}
                              colSpan={5}
                            >
                              <Collapse
                                in={open_2[detailsRow.cn_code] || false}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box sx={{ margin: 0 }}>
                                  <Table aria-label="cn_details_2">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            color: '#fa951d',
                                            whiteSpace: 'nowrap',
                                            width: '15%',
                                          }}
                                        >
                                          CN Code
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            color: '#fa951d',
                                            width: '55%',
                                          }}
                                        >
                                          Description
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            color: '#fa951d',
                                            whiteSpace: 'nowrap',
                                            width: '10%',
                                          }}
                                          align="right"
                                        >
                                          Direct Emissions
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            color: '#fa951d',
                                            whiteSpace: 'nowrap',
                                            width: '10%',
                                          }}
                                          align="right"
                                        >
                                          Indirect Emissions
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            color: '#fa951d',
                                            whiteSpace: 'nowrap',
                                            width: '10%',
                                          }}
                                          align="right"
                                        >
                                          Total Emissions
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {detailsRow.children.map((child) => (
                                        <TableRow key={child.cn_code}>
                                          <TableCell
                                            sx={{ whiteSpace: 'nowrap' }}
                                          >
                                            {child.cn_code}
                                          </TableCell>
                                          <TableCell align="left">
                                            {child.description}
                                          </TableCell>
                                          <TableCell align="right">
                                            {child.direct_emissions.toFixed(2)}
                                          </TableCell>
                                          <TableCell align="right">
                                            {child.indirect_emissions.toFixed(
                                              2
                                            )}
                                          </TableCell>
                                          <TableCell align="right">
                                            {child.total_emissions.toFixed(2)}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

const rows: ReturnType<typeof createData>[] = []

for (const item of CbamSourceData) {
  const { agg_goods_category, details } = item
  rows.push(createData(agg_goods_category, details))
}

export const CollapsibleTableCbam = () => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: '#4fa500' }}>
              {<strong>Aggregated Goods Categories</strong>}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.agg_goods_category} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
