import * as React from 'react'
import { eColor } from 'components/types'
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Collapse,
  Box,
  IconButton,
  TableContainer,
  TableHead,
  LinearProgress,
} from '@mui/material'
import {
  Cloud, // For Total
  LocalGasStation, // For Fuel
  Forest, // For LULUC
  EmojiNature, // For Biogenic
  FlightTakeoff, // For Airplane
} from '@mui/icons-material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { SaveAs } from '@mui/icons-material'
import { EmptyData } from 'components/common/EmptyData'
import { susIcons } from 'config/services/requests'
import { useTranslation } from 'react-i18next'
import {
  AssemblyCo2ReferenceObject,
  CalculationProcessStep,
} from 'store/types/assembler'
import { AssemblerCo2Calculator } from '../Co2Calculator/Co2Calculator'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  addCalculationProcessStep,
  deleteCalculationProcessStep,
  addCategoryItem,
  removeCategoryItem,
  updateCategoryItem,
  renameCalculationProcessStep,
} from 'store/slices/assemblyModelSlice'
import { sagaBuildAssemblyAction } from 'saga/actions/assembler'
import { ModalRename, ModalYesNo } from '../AssemblerModals'
import { getAdditionalInfo, getQuantity, getUnit } from './helpers'
import { capitaliseString } from 'config/lib'

// ----- PROPS
interface CollapsibleTableProps {
  data: CalculationProcessStep[]
  totalCo2: number
}

// Icons mapping
const CATEGORY_ICONS: Record<string, React.ElementType> = {
  materials: susIcons['Materials'] || (() => <></>),
  logistics: susIcons['Logistics'] || (() => <></>),
  production: susIcons['Production'] || (() => <></>),
}

// ----- UTILS
function combineCategoryItems(
  row: CalculationProcessStep,
  category: 'materials' | 'logistics' | 'production'
) {
  const sustamizeData =
    (row.sustamizeTargets && row.sustamizeTargets[category]) || {}
  const customData = (row.customTargets && row.customTargets[category]) || {}

  // We return a record that, for each subCatKey, is an array of "wrapped" items
  // Each element = { item, source, originalIndex }
  const mergedData: Record<
    string,
    {
      item: AssemblyCo2ReferenceObject
      source: 'sustamize' | 'custom'
      originalIndex: number
    }[]
  > = {}

  function accumulateItems(sourceObj: any, source: 'sustamize' | 'custom') {
    Object.entries(sourceObj).forEach(([subCatKey, items]) => {
      if (!Array.isArray(items)) return
      if (!mergedData[subCatKey]) mergedData[subCatKey] = []

      items.forEach((item: AssemblyCo2ReferenceObject, index: number) => {
        mergedData[subCatKey].push({
          item,
          source,
          originalIndex: index,
        })
      })
    })
  }

  if (category !== 'production') {
    accumulateItems(customData, 'custom')
    accumulateItems(sustamizeData, 'sustamize')
  } else {
    // For 'production' specifically, only accumulate SUSTAMIZE arrays here
    // so that custom arrays (energy, employees, equipment) are NOT shown at top level.
    accumulateItems(sustamizeData, 'sustamize')

    // -- A: Create single "productionItem" for the main custom production object
    const customProd = row.customTargets?.production
    if (
      customProd &&
      typeof customProd === 'object' &&
      (customProd.name || customProd.calculatedEntityEmission)
    ) {
      mergedData['productionItem'] = [
        {
          item: {
            name: customProd.name || 'N/A',
            comment: customProd.comment || '',
            baseParams: customProd.baseParams || {},
            calculatedEntityEmission: customProd.calculatedEntityEmission || 0,
            unit: customProd.unit || '',
            // We'll store the child arrays here too, so we can show them in child expansion
            energy: customProd?.energy,
            employees: customProd.employees || [],
            equipment: customProd.equipment || [],
            subCategory: 'manufacturing',
            category: 'production',
            source: 'custom',
            context: '.production',
            sid: '',
            params: {},
          },
          source: 'custom',
          originalIndex: -1,
        },
      ]
    }
  }

  return mergedData
}

// ----- ITEM ROW
function ItemRow({
  item,
  source,
  originalIndex,
  categoryTotal,
  stepIndex,
  categoryName,
  subCategory,
  onModify,
}: {
  item: AssemblyCo2ReferenceObject
  source: 'sustamize' | 'custom'
  originalIndex: number
  categoryTotal: number
  stepIndex: number
  categoryName: 'materials' | 'logistics' | 'production'
  subCategory: string
  onModify?: (
    item: AssemblyCo2ReferenceObject,
    index: number,
    subCat: string,
    source: 'sustamize' | 'custom'
  ) => void
}) {
  const dispatch = useDispatch()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const itemCo2 = item.calculatedEntityEmission || 0
  const itemPercent = (itemCo2 / (categoryTotal || 1)) * 100

  const quantity = getQuantity(item)
  const unit = getUnit(item)
  const addInfo = getAdditionalInfo(item)

  return (
    <TableRow>
      <TableCell sx={{ width: 360 }}>{item.name || 'N/A'}</TableCell>
      <TableCell>{quantity.toFixed(2)}</TableCell>
      <TableCell>{unit}</TableCell>
      <TableCell sx={{ width: 160 }}>
        {item.entityEmissionReference?.toFixed(2)} {item.unit}
      </TableCell>
      <TableCell>{addInfo}</TableCell>

      {/* Split CO2 into 3 columns: value, bar, percent */}
      <TableCell style={{ textAlign: 'right' }}>{itemCo2.toFixed(2)}</TableCell>
      <TableCell sx={{ width: 240 }}>
        <LinearProgress
          value={itemPercent}
          variant="determinate"
          sx={{ height: 10 }}
        />
      </TableCell>
      <TableCell>{itemPercent.toFixed(2)} %</TableCell>

      <TableCell align="center">
        {/* Modify */}
        <IconButton
          onClick={() => {
            if (onModify) {
              onModify(item, originalIndex, subCategory, source)
            }
          }}
          color={eColor.primary}
        >
          <EditIcon />
        </IconButton>

        {/* Delete */}
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            // Instead of deleting immediately, open the confirmation modal
            setDeleteModalOpen(true)
          }}
          color="error"
        >
          <DeleteIcon />
        </IconButton>

        {/* Confirm Delete Modal */}
        <ModalYesNo
          title="Remove item"
          message={`Are you sure you want to remove this item?`}
          yesOption={() => {
            // 1) Dispatch your remove action
            dispatch(
              removeCategoryItem({
                stepIndex,
                category: categoryName,
                subCategory,
                source,
                itemIndex: originalIndex,
              })
            )
            dispatch(sagaBuildAssemblyAction())

            // 2) Close the modal
            setDeleteModalOpen(false)
          }}
          open={deleteModalOpen}
          handleClose={() => setDeleteModalOpen(false)}
        />
      </TableCell>
    </TableRow>
  )
}

// ----- CATEGORY DETAILS TABLE
function CategoryDetailsTable({
  categoryItems,
  categoryTotal,
  stepIndex,
  categoryName,
  handleModify,
}: {
  categoryItems: Record<
    string,
    {
      item: AssemblyCo2ReferenceObject
      source: 'sustamize' | 'custom'
      originalIndex: number
    }[]
  >
  categoryTotal: number
  stepIndex: number
  categoryName: 'materials' | 'logistics' | 'production'
  handleModify: (
    item: AssemblyCo2ReferenceObject,
    index: number,
    subcat: string,
    source: 'sustamize' | 'custom'
  ) => void
}) {
  const hasNoItems =
    Object.values(categoryItems).reduce(
      (acc, val) => acc.concat(val),
      [] as any[]
    ).length === 0

  if (hasNoItems) {
    return (
      <Typography sx={{ p: 2 }}>No items found for this category.</Typography>
    )
  }

  return (
    <Table size="small" sx={{ mt: 1 }}>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Quantity</TableCell>
          <TableCell>Unit</TableCell>
          <TableCell>Emission Ref</TableCell>
          <TableCell>Add. Info</TableCell>

          {/* We now split CO2 into 3 columns */}
          <TableCell></TableCell>
          <TableCell sx={{ textAlign: 'center' }}>CO2e [kgCO2e]</TableCell>
          <TableCell></TableCell>

          <TableCell align="center">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(categoryItems).map(([subCategory, wrappedItems]) => {
          // --- NEW LOGIC: if subCategory is 'productionItem', show a special row.
          if (subCategory === 'productionItem') {
            return (
              <ProductionItemRow
                key="productionItem"
                wrappedItems={wrappedItems}
                categoryTotal={categoryTotal}
                stepIndex={stepIndex}
                categoryName={categoryName}
                handleModify={handleModify}
              />
            )
          } else {
            // For everything else, fallback to normal mapping:
            return wrappedItems.map((wrapped, i) => {
              const { item, source, originalIndex } = wrapped
              return (
                <ItemRow
                  key={`${subCategory}-${i}`}
                  item={item}
                  source={source}
                  originalIndex={originalIndex}
                  categoryTotal={categoryTotal}
                  stepIndex={stepIndex}
                  categoryName={categoryName}
                  subCategory={subCategory}
                  onModify={(itm, idx, sc, src) =>
                    handleModify(itm, idx, sc, src)
                  }
                />
              )
            })
          }
        })}
      </TableBody>
    </Table>
  )
}

function ProductionItemRow({
  wrappedItems,
  categoryTotal,
  stepIndex,
  categoryName,
  handleModify,
}: {
  wrappedItems: {
    item: {
      name?: string | undefined
      comment?: string | null | undefined
      baseParams?: any
      calculatedEntityEmission?: number
      unit?: string
      energy?: any[]
      employees?: any[]
      equipment?: any[]
    }
    source: 'sustamize' | 'custom'
    originalIndex: number
  }[]
  categoryTotal: number
  stepIndex: number
  categoryName: 'materials' | 'logistics' | 'production'
  handleModify: Function
}) {
  const dispatch = useDispatch()
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  if (!wrappedItems || !wrappedItems[0]) return null
  const { item, source, originalIndex } = wrappedItems[0]

  const itemCo2 = item.calculatedEntityEmission || 0
  const itemPercent = (itemCo2 / (categoryTotal || 1)) * 100
  const addInfo = getAdditionalInfo(item as any)
  // The child arrays we want to show upon expansion
  const { energy = [], employees = [], equipment = [] } = item

  return (
    <>
      {/* The main production row */}
      <TableRow
        onClick={() => setOpen(!open)}
        sx={{
          cursor: 'pointer',
          '&:hover': { backgroundColor: 'rgba(0,0,0,0.04)' },
        }}
      >
        <TableCell>{item.name || 'N/A'}</TableCell>
        <TableCell>{1 || 'N/A'}</TableCell>
        <TableCell>{getUnit(item as any) || '-'}</TableCell>
        <TableCell>
          {item.calculatedEntityEmission?.toFixed(2) || 0} {item.unit}
        </TableCell>
        <TableCell>{addInfo}</TableCell>
        <TableCell align="right">{itemCo2.toFixed(2)}</TableCell>
        <TableCell sx={{ width: 240 }}>
          <LinearProgress
            value={itemPercent}
            variant="determinate"
            sx={{ height: 10 }}
          />
        </TableCell>
        <TableCell>{itemPercent.toFixed(2)}%</TableCell>
        <TableCell align="center">
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              // Reuse your existing 'onModify' logic
              handleModify(item, originalIndex, 'productionItem', source)
            }}
            color={eColor.primary}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              setDeleteModalOpen(true)
            }}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
          <ModalYesNo
            title="Remove item"
            message="Are you sure you want to remove this custom production item?"
            yesOption={() => {
              // This dispatch triggers your reducer logic to clear the custom production object.
              dispatch(
                removeCategoryItem({
                  stepIndex,
                  category: 'production',
                  subCategory: 'productionItem', // or whatever key you decided on
                  source: 'custom',
                  itemIndex: originalIndex, // typically -1 for "productionItem"
                })
              )
              dispatch(sagaBuildAssemblyAction())
              setDeleteModalOpen(false)
            }}
            open={deleteModalOpen}
            handleClose={() => setDeleteModalOpen(false)}
          />
        </TableCell>
      </TableRow>

      {/* Collapsible child items */}
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ m: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Unit</TableCell>
                    <TableCell>Emission Ref</TableCell>
                    <TableCell>Add. Info</TableCell>
                    <TableCell>CO2e [kgCO2e]</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* ENERGY array */}
                  {energy.map((child, idx) => {
                    const co2 = child.calculatedEntityEmission || 0
                    return (
                      <TableRow key={`energy-${idx}`}>
                        <TableCell>{child.name}</TableCell>
                        <TableCell>
                          {child.params?.consumption ?? '-'}
                        </TableCell>
                        <TableCell>{getUnit(child)}</TableCell>
                        <TableCell>
                          {child.entityEmissionReference} {child.unit}
                        </TableCell>
                        <TableCell>{getAdditionalInfo(child)}</TableCell>
                        <TableCell>{co2.toFixed(2)}</TableCell>
                      </TableRow>
                    )
                  })}

                  {/* EMPLOYEES array */}
                  {employees.map((child, idx) => {
                    const co2 = child.calculatedEntityEmission || 0
                    return (
                      <TableRow key={`employees-${idx}`}>
                        <TableCell>{child.name}</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>{getUnit(child)}</TableCell>
                        <TableCell>{co2.toFixed(2)}</TableCell>
                      </TableRow>
                    )
                  })}

                  {/* EQUIPMENT array */}
                  {equipment.map((child, idx) => {
                    const co2 = child.calculatedEntityEmission || 0
                    return (
                      <TableRow key={`equipment-${idx}`}>
                        <TableCell>{child.name}</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>{getUnit(child)}</TableCell>
                        <TableCell>{co2.toFixed(2)}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

// ----- CATEGORY ROW
function CategoryRow({
  categoryName,
  categoryItems,
  stepTotalEmissions,
  stepIndex,
  handleModify,
}: {
  categoryName: 'materials' | 'logistics' | 'production'
  categoryItems: Record<string, any[]>
  stepTotalEmissions: number
  stepIndex: number
  handleModify: (
    item: AssemblyCo2ReferenceObject,
    index: number,
    subcat: string,
    source: 'sustamize' | 'custom'
  ) => void
}) {
  const [open, setOpen] = useState(false)
  const IconComponent = CATEGORY_ICONS[categoryName] || (() => <></>)

  let categoryTotal = 0
  Object.values(categoryItems).forEach((arr) => {
    ;(arr as any[]).forEach((obj) => {
      categoryTotal += obj.item?.calculatedEntityEmission || 0
    })
  })

  const categoryPercent = (categoryTotal / (stepTotalEmissions || 1)) * 100

  return (
    <>
      <TableRow
        onClick={() => setOpen(!open)}
        sx={{
          backgroundColor: open ? 'rgba(0,0,0,0.05)' : 'inherit',
          cursor: 'pointer',
          '&:hover': { backgroundColor: 'rgba(0,0,0,0.1)' },
        }}
      >
        {/* Icon */}
        <TableCell width="5%">
          <IconComponent style={{ width: '24px', height: '24px' }} />
        </TableCell>

        {/* Category Name */}
        <TableCell width="25%">
          <Typography fontWeight="bold">
            {capitaliseString(categoryName)}
          </Typography>
        </TableCell>

        {/* CO2 total */}
        <TableCell style={{ textAlign: 'right' }}>
          {categoryTotal.toFixed(2)} kgCO2e
        </TableCell>

        {/* Progress Bar */}
        <TableCell sx={{ width: 360 }}>
          <LinearProgress
            value={categoryPercent}
            variant="determinate"
            sx={{ height: 10 }} // thicker bar
          />
        </TableCell>

        {/* % */}
        <TableCell>{categoryPercent.toFixed(2)}%</TableCell>

        {/* Expand/Collapse toggle */}
        <TableCell align="right">
          <IconButton size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      {/* Collapsed details */}
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ mx: 2, my: 1 }}>
              <CategoryDetailsTable
                categoryItems={categoryItems}
                categoryTotal={categoryTotal}
                stepIndex={stepIndex}
                categoryName={categoryName}
                handleModify={handleModify}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

// ----- PROCESS STEP ROW
function ProcessStepRow({
  step,
  grandTotalEmissions,
  index,
}: {
  step: CalculationProcessStep
  grandTotalEmissions: number
  index: number
}) {
  const [open, setOpen] = useState(false)
  const [co2CalculatorOpen, setCo2CalculatorOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [renameOpen, setRenameOpen] = useState(false)
  const [calculatorMode, setCalculatorMode] = useState<'add' | 'edit'>('add')
  const [editItemSource, setEditItemSource] = useState<'sustamize' | 'custom'>(
    'sustamize'
  )
  const [modalKey, setModalKey] = useState(0)
  const [editItem, setEditItem] = useState<
    AssemblyCo2ReferenceObject | undefined
  >()
  const [editItemIndex, setEditItemIndex] = useState<number>(0)

  const dispatch = useDispatch()

  // Combine
  const materialsData = combineCategoryItems(step, 'materials')
  const logisticsData = combineCategoryItems(step, 'logistics')
  const productionData = combineCategoryItems(step, 'production')

  function getCategoryTotal(categoryData: Record<string, any[]>) {
    let total = 0
    Object.values(categoryData).forEach((arr) => {
      ;(arr as any[]).forEach((wrapped) => {
        total += wrapped.item?.calculatedEntityEmission || 0
      })
    })
    return total
  }

  const materialsTotal = getCategoryTotal(materialsData)
  const logisticsTotal = getCategoryTotal(logisticsData)
  const productionTotal = getCategoryTotal(productionData)
  const stepTotalEmissions = materialsTotal + logisticsTotal + productionTotal
  const stepPercent = (stepTotalEmissions / (grandTotalEmissions || 1)) * 100

  // ----- Modify callback
  function handleModify(
    item: AssemblyCo2ReferenceObject,
    originalIndex: number,
    subCategory: string,
    source: 'sustamize' | 'custom'
  ) {
    // set "edit" mode
    setCalculatorMode('edit')
    setModalKey((prev) => prev + 1)
    // store the item being edited
    setEditItem(item)
    // store the actual index (the 'originalIndex' in the store)
    setEditItemIndex(originalIndex)
    setEditItemSource(source)

    // open the modal
    setCo2CalculatorOpen(true)
  }

  return (
    <>
      {/* Top-level row for the process step */}
      <TableRow
        sx={{
          backgroundColor: open ? 'rgba(0,0,0,0.04)' : 'inherit',
          cursor: 'pointer',
          '&:hover': { backgroundColor: 'rgba(0,0,0,0.08)' },
        }}
        onClick={() => setOpen(!open)}
      >
        {/* Name */}
        <TableCell width="20%">
          <Typography variant="h6">{step.name}</Typography>
        </TableCell>

        {/* CO2 total (numeric) */}
        <TableCell style={{ textAlign: 'right' }}>
          {(stepTotalEmissions ?? 0).toFixed(2)} kgCO2e
        </TableCell>

        {/* Progress bar */}
        <TableCell sx={{ width: 480 }}>
          <LinearProgress
            value={stepPercent}
            variant="determinate"
            sx={{ height: 10 }}
          />
        </TableCell>

        {/* % */}
        <TableCell>{stepPercent.toFixed(2)}%</TableCell>

        {/* Step actions */}
        <TableCell align="right">
          {/* Rename */}
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              setRenameOpen(true)
            }}
            color={eColor.primary}
          >
            <EditIcon />
          </IconButton>

          {/* SaveAs */}
          <IconButton onClick={() => {}} color={eColor.secondary}>
            <SaveAs />
          </IconButton>

          {/* Delete */}
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              setDeleteModalOpen(true)
            }}
            color="error"
          >
            <DeleteIcon />
          </IconButton>

          {/* Confirm delete */}
          <ModalYesNo
            title="Remove item"
            message={`Are you sure you want to remove this process step?`}
            yesOption={() => {
              dispatch(deleteCalculationProcessStep(index))
              dispatch(sagaBuildAssemblyAction())
              setDeleteModalOpen(false)
            }}
            open={deleteModalOpen}
            handleClose={() => setDeleteModalOpen(false)}
          />
        </TableCell>

        {/* Expand/Collapse arrow */}
        <TableCell align="right">
          <IconButton size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      {/* Collapsible categories */}
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                mx: 2,
                my: 2,
                backgroundColor: '#FAFAFA',
                borderRadius: 1,
              }}
            >
              <Table size="small">
                <TableBody>
                  <CategoryRow
                    categoryName="materials"
                    categoryItems={materialsData}
                    stepTotalEmissions={stepTotalEmissions}
                    stepIndex={index}
                    handleModify={handleModify}
                  />
                  <CategoryRow
                    categoryName="logistics"
                    categoryItems={logisticsData}
                    stepTotalEmissions={stepTotalEmissions}
                    stepIndex={index}
                    handleModify={handleModify}
                  />
                  <CategoryRow
                    categoryName="production"
                    categoryItems={productionData}
                    stepTotalEmissions={stepTotalEmissions}
                    stepIndex={index}
                    handleModify={handleModify}
                  />
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box
                        sx={{
                          border: '2px dashed #fa951d',
                          color: '#fa951d',
                          p: 2,
                          textAlign: 'center',
                          cursor: 'pointer',
                          borderRadius: '4px',
                          mt: 2,
                          ':hover': {
                            backgroundColor: 'rgba(250,149,29, 0.1)',
                          },
                        }}
                        onClick={() => {
                          setCo2CalculatorOpen(true)
                          setCalculatorMode('add')
                          setEditItem(undefined)
                        }}
                      >
                        + Add a Component
                      </Box>
                      {/* CO2 Calculator Modal */}
                      <AssemblerCo2Calculator
                        key={modalKey}
                        title="CO2e Calculator"
                        open={co2CalculatorOpen}
                        handleClose={() => setCo2CalculatorOpen(false)}
                        mode={calculatorMode}
                        initialItem={editItem}
                        // Only define addOption in 'add' mode
                        addOption={
                          calculatorMode === 'add'
                            ? (co2Item) => {
                                dispatch(
                                  addCategoryItem({
                                    stepIndex: index,
                                    category: co2Item.category,
                                    subCategory: co2Item.subCategory,
                                    source: co2Item.source,
                                    item: co2Item,
                                  })
                                )
                                dispatch(sagaBuildAssemblyAction())
                                setCo2CalculatorOpen(false)
                              }
                            : undefined
                        }
                        // Only define saveOption in 'edit' mode
                        saveOption={
                          calculatorMode === 'edit'
                            ? (co2Item) => {
                                console.log('co2item', co2Item)
                                dispatch(
                                  updateCategoryItem({
                                    stepIndex: index,
                                    category: co2Item.category,
                                    subCategory: co2Item.subCategory,
                                    source: co2Item.source,
                                    itemIndex: editItemIndex,
                                    item: co2Item,
                                  })
                                )
                                dispatch(sagaBuildAssemblyAction())
                                setCo2CalculatorOpen(false)
                              }
                            : undefined
                        }
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {/* Rename Modal */}
      <ModalRename
        open={renameOpen}
        handleClose={() => setRenameOpen(false)}
        title="Rename Step"
        message="Enter a new name for this step."
        currentName={step.name}
        yesOption={(newName) => {
          dispatch(
            renameCalculationProcessStep({
              stepIndex: index,
              newName,
            })
          )
          dispatch(sagaBuildAssemblyAction())
          setRenameOpen(false)
        }}
      />
    </>
  )
}

// ----- CollapsibleTableAssembler (the main)
export const CollapsibleTableAssembler: React.FC<CollapsibleTableProps> = ({
  data,
  totalCo2,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  if (!data || data.length === 0) return <EmptyData />
  const numberOfSteps = data.length
  const grandTotalEmissions = totalCo2

  return (
    <TableContainer>
      <Table aria-label="multi-level-collapsible-table">
        {/* 
          Top-level TableHead can be very minimal if each row 
          (ProcessStepRow) handles its own display. 
        */}
        <TableHead>
          <TableRow>
            {/* We now have 6 columns at the top level:
                1) Step Name
                2) CO2 numeric
                3) Progress bar
                4) Percentage
                5) Actions
                6) Collapse arrow
             */}
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell align="right" />
            <TableCell align="right" />
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((step, index) => (
            <ProcessStepRow
              key={index}
              step={step}
              grandTotalEmissions={grandTotalEmissions}
              index={index}
            />
          ))}
          <TableRow>
            <TableCell colSpan={6}>
              <Box
                sx={{
                  border: '2px dashed #fa951d',
                  color: '#fa951d',
                  p: 2,
                  textAlign: 'center',
                  cursor: 'pointer',
                  borderRadius: '4px',
                  mt: 2,
                  transition: 'background-color 0.2s ease-in-out',
                  ':hover': { backgroundColor: 'rgba(250,149,29, 0.1)' },
                }}
                onClick={() => {
                  dispatch(
                    addCalculationProcessStep({
                      stepName: `Process Step ${numberOfSteps + 1}`,
                    })
                  )
                  dispatch(sagaBuildAssemblyAction())
                }}
              >
                + Add a Calculation Step
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={6} align="right" sx={{ pt: 3 }}>
              <Box
                sx={{
                  // inline-block so it doesn't fill the entire cell,
                  // but we give it at least 320px so space-between can spread items.
                  display: 'inline-block',
                  textAlign: 'left',
                  minWidth: 320,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid #ccc',
                    pb: 1,
                    mb: 2,
                  }}
                >
                  {/* LEFT SIDE: Icon + Label */}
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Cloud fontSize="medium" sx={{ mr: 1 }} />
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{ fontSize: '1.0rem' }}
                    >
                      {t('assembler.totalEmissions')}
                    </Typography>
                  </Box>

                  {/* RIGHT SIDE: Value */}
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ fontSize: '1.0rem' }}
                    align="right"
                  >
                    {(grandTotalEmissions ?? 0).toFixed(2)} kgCO2e
                  </Typography>
                </Box>

                {/* ----- BREAKDOWN ROWS: FUEL, LULUC, BIOGENIC, AIRPLANE ----- */}
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    columnGap: 2,
                    rowGap: 1,
                  }}
                >
                  {/* Fuel */}
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <LocalGasStation fontSize="medium" sx={{ mr: 1 }} />
                    <Typography variant="body1">Fuel</Typography>
                  </Box>
                  <Typography variant="body1" align="right">
                    {((grandTotalEmissions ?? 0) * 0.975).toFixed(2)} kgCO2e
                  </Typography>

                  {/* LULUC */}
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Forest fontSize="medium" sx={{ mr: 1 }} />
                    <Typography variant="body1">LULUC</Typography>
                  </Box>
                  <Typography variant="body1" align="right">
                    {((grandTotalEmissions ?? 0) * 0.01).toFixed(2)} kgCO2e
                  </Typography>

                  {/* Biogenic (with Biotech icon) */}
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <EmojiNature fontSize="medium" sx={{ mr: 1 }} />
                    <Typography variant="body1">Biogenic</Typography>
                  </Box>
                  <Typography variant="body1" align="right">
                    {((grandTotalEmissions ?? 0) * 0.015).toFixed(2)} kgCO2e
                  </Typography>

                  {/* Airplane */}
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FlightTakeoff fontSize="medium" sx={{ mr: 1 }} />
                    <Typography variant="body1">Airplane</Typography>
                  </Box>
                  <Typography variant="body1" align="right">
                    0.00 kgCO2e
                  </Typography>
                </Box>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
