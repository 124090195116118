import { ApiResponse } from "./generalResponse";

export interface iAssemblyResponse extends ApiResponse {
  // You could add errName, errMsg, etc., if your server returns them
  data: iAssembly;
}

export interface iAssemblyResponseState {
  assemblyResponse: iAssemblyResponse | null
}


export interface iAssembly {
  id?: string
  name?: string
  category?: string | null,
  creationDate?: Date
  lastUpdatedOn?: Date
  createdBy?: string
  result: AssemblyResult
  }
  
export interface iAssemblyModel {
  name: string,
  id: string,
  category: string,
  assemblyModel: {
  calculationProcessChain: CalculationProcessStep[]
}
}

export interface iAssemblyModelState {
  assemblyModel: iAssemblyModel
}


  export interface SubAssembly {
    id: string,
    quantity: number
    emissions?: number  | null,
    emissionSplitByType?: EmissionTypes  | null
    emissionSplitByCategory?: EmissionsCategorySplit  | null
    emissionOriginComposition?: AssemblyEmissionOriginComposition  | null
  }
  
  export type EmissionTypes = { [key in EmissionType]: number }
  
  export interface AssemblyResult {
    emissions: number,
    emissionSplitByType: EmissionTypes
    emissionSplitByCategory: EmissionsCategorySplit
    emissionOriginComposition: AssemblyEmissionOriginComposition
    calculationProcessChain: CalculationProcessStep[]
  }
  
  export interface AssemblyEmissionOriginComposition {
    fuel?: number | null
    biogenic?: number | null
    luluc?: number | null
    airplane?: number | null
  }
  
  export interface BaseEmission {
    emissions?: number  | null
    emissionOriginComposition?: EmissionOriginComposition | null
  }
  
  export interface CalculationProcessStep extends BaseEmission, CalculationProcessTargets {
    name: string
    position: number
    subAssemblies: SubAssembly[],
  }
  
  export interface EmissionsCategorySplit {
      materials: {
        totalEmissions?: number  | null,
        emissionsByCategory: {
          metals: number
          elements: number,
          polymers: number,
          sinters: number,
          composites: number
        }
      },
      logistics: {
        totalEmissions?: number  | null,
        emissionsByCategory: {
          transportation: number,
          packaging: number,
        }
      },
      production:{
        totalEmissions?: number  | null,
        emissionsByCategory: {
          manufacturing: number,
          employees: number,
          equipment: number
        }
      }
  }
  
  export interface CalculationProcessTargets {
    emissionSplitByCategory?: EmissionsCategorySplit  | null
    sustamizeTargets: {
      materials?: MaterialsProcessTarget,
      logistics?: LogisticsProcessTarget,
      production?: ProductionProcessTarget 
    }
    customTargets: {
      materials?: MaterialsProcessTarget,
      logistics?: LogisticsProcessTarget,
      production?: ProductionProcessTarget 
    }
  }
  
  export interface MaterialsProcessTarget extends BaseEmission {
    metals?: Co2ReferenceObject[],
    polymers?: Co2ReferenceObject[],
    composites?: Co2ReferenceObject[],
    sinters?: Co2ReferenceObject[],
    elements?: Co2ReferenceObject[],
    other?: Co2ReferenceObject[]
  }
  
  export interface MaterialsProcessTargetParams extends CustomFootPrintParams {
    netWeight?: number | null,
    countryCode?: string | null,
    countryName?: string | null,
    yield?: number | null
    calculationVariant?: string | null,
    recyclingRatio?: number | null
  }
  
  export interface MetalsProcessTargetParams extends MaterialsProcessTargetParams {
    shape?: string | null,
  }
  
  export interface ManufacturingProcessTargetParams extends CustomFootPrintParams {
    consumption?: number | null
    countryCode?: string | null
    countryName?: string | null
  }
  
  export interface LogisticsProcessTarget extends BaseEmission {
    transportation?: Co2ReferenceObject[]
    packaging?: Co2ReferenceObject[]
  }
  
  export interface CustomFootPrintParams {
    customFootprint?: number | null
    customOriginComposition?: EmissionOriginComposition | null
  }
  
  export interface TransportationProcessTargetParams extends CustomFootPrintParams {
    distance?: number | null
    transportGrossWeight?: number | null
    partDensity?: number | null
  }
  
  export interface PackagingProcessTargetParams extends CustomFootPrintParams {
    quantity?: number | null
  }
  
  export interface ProductionProcessBaseTargetParams {
    cavities?: number | null
    reject?: number | null
    cycleTime?: number | null
  }
  
  export interface ProductionProcessTarget extends BaseEmission {
    baseParams?: ProductionProcessBaseTargetParams,
    name?: string,
    comment?: string,
    unit?: string,
    calculatedEntityEmission?: number,
    manufacturing?: Co2ReferenceObject[],
    equipment?: [],
    employees?: [],
    energy?: []
  }
  
  export interface EquipmentProcessTargetParams extends CustomFootPrintParams {
    equipmentFloorSpace?: number | null
  }
  
  export interface EmployeeProcessTargetParams extends CustomFootPrintParams {
    numberOfWorkers?: number | null
  }
  
  export interface EnergyProcessTargetParams extends CustomFootPrintParams {
    consumption?: number | null,
    countryCode?: number | null
  }
  
  export interface EmissionVariantsReferenceObject {
    co2Primary: {
    common: number,
    min: number,
    max: number,
    median: number}
    ,
    co2Recycling: {
      common: number,
      min: number,
      max: number,
      median:number
    }
  }
  
  export interface EmissionVariantsObject {
    common: number,
    min: number,
    max: number,
    median: number
  }
  
  export interface Co2ReferenceObject {
    sid: string | undefined
    name?: string,
    context?: string,
    comment?: string | null,
    source?: string
    documentationLink?: string| null,
    systemBoundaries?: string | null,
    unit?: string
    entityEmissionReference?: number,
    calculatedEntityEmission?: number,
    entityEmissionReferenceVariants?: EmissionVariantsReferenceObject,
    calculatedEntityEmissionVariants?: EmissionVariantsObject,
    emissionType?: string,
    entityEmissionReferenceOriginComposition?: EmissionOriginComposition | null
    calculatedEntityEmissionOriginComposition?: EmissionOriginComposition | null
    params: MetalsProcessTargetParams
      | MaterialsProcessTargetParams
      | TransportationProcessTargetParams
      | PackagingProcessTargetParams
      | ManufacturingProcessTargetParams
      | EmployeeProcessTargetParams
      | EquipmentProcessTargetParams
      | EnergyProcessTargetParams,
    baseParams?: ProductionProcessBaseTargetParams,
    employees?: [],
    equipment?: [],
    energy?: []
  }

  export interface AssemblyCo2ReferenceObject extends Co2ReferenceObject {
    category: string
    subCategory: string
    description?: string
  }
  
  export interface EmissionOriginComposition {
    fuel?: number | null
    biogenic?: number | null
    luluc?: number | null
    airplane?: number | null
  }
  
  export enum EmissionType {
    DIRECT = 'direct',
    INDIRECT = 'indirect'
  }
  
  export enum CalculationVariant {
    MIN = 'min',
    COMMON = 'common',
    MAX = 'max'
  }
  
  export enum AssemblyCategories {
    MATERIALS = 'materials',
    LOGISTICS = 'logistics',
    PRODUCTION = 'production'
  }


  export interface iAssemblyListingResponse extends ApiResponse {
    // You could add errName, errMsg, etc., if your server returns them
    data: AssemblyListing[];
  }
  
  export interface AssemblyListing {
    id: string,
    name: string,
    customerId: string,
    organizationId: number,
    sharedWithOrganizations: [],
    lifecycleStage: number,
    assemblyModel: CalculationProcessStep[],
    createdOn: Date,
    lastUpdatedOn: Date
  }
  
  export interface iAssemblyListingState {
    assemblyListing: iAssemblyListingResponse | null
  }
  
  