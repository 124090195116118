import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RoutePaths } from 'routes/paths'
import { Typography, TextField, MenuItem, Tabs, Box, Tab } from '@mui/material'
import PaperWrapper from 'components/custom/PaperWrapper'
import ContentBodyWrapper from 'components/custom/ContentBodyWrapper'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import { eColor } from 'components/types'
import { ModalYesNo } from 'pages/Assembler/components/AssemblerModals'
import { sagaBuildAssemblyAction } from 'saga/actions/assembler'
import { sagaGetSearchResultsAction } from 'saga/actions/assembler'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import LinkWrapper from 'components/custom/LinkWrapper'
import { sagaGetSingleEntityAction } from 'saga/actions/assembler'

const groups = [
  { value: 'Group A', label: 'Group A' },
  { value: 'Group B', label: 'Group B' },
  { value: 'Group C', label: 'Group C' },
]

const assembliesList = [
  { value: 'Assembly 1', label: 'Assembly 1' },
  { value: 'Assembly 2', label: 'Assembly 2' },
]

const versionList = [{ value: 'V3.0.0', label: 'V3.0.0' }]

const Collector: FC = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState(groups[0].value)
  const [selectedAssembly, setSelectedAssembly] = useState(
    assembliesList[0].value
  )
  const [selectedVersion, setSelectedVersion] = useState(versionList[0].value)

  const dispatch = useDispatch()

  // Fetch assembly data from Redux
  useEffect(() => {
    dispatch(sagaBuildAssemblyAction())
  }, [dispatch])

  useEffect(() => {
    dispatch(sagaGetSearchResultsAction())
  }, [dispatch])

  // Get only `assembly` data from Redux state
  const currentAssemblyData = useSelector(
    (state: RootState) => state.assemblyResult.assemblyResponse?.data
  )
  const searchResults = useSelector(
    (state: RootState) => state.searchResults.searchResults?.data
  )

  // Get only `assembly` data from Redux state
  const currentAssemblyModel = useSelector(
    (state: RootState) => state.assemblyModel?.assemblyModel
  )

  return (
    <ContentBodyWrapper title={t('assembler.title')}>
      <PaperWrapper>
        {/* ---- BUILDER UI (same as before) ---- */}
        <div className="flex justify-between gap-x-3 mb-4">
          {/* Left Side: Group & Assembly Selection */}
          <div className="flex gap-x-3">
            <TextField
              id="outlined-select-group"
              select
              label="Select a Group"
              value={selectedGroup}
              onChange={(e) => setSelectedGroup(e.target.value)}
              helperText={<LinkWrapper>or Create a new group</LinkWrapper>}
            >
              {groups.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              id="outlined-select-assembly"
              select
              label="Select an Assembly"
              value={selectedAssembly}
              onChange={(e) => setSelectedAssembly(e.target.value)}
              helperText={<LinkWrapper>or Create a new assembly</LinkWrapper>}
            >
              {assembliesList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>

          {/* Right Side: Version Selection */}
          <div>
            <TextField
              id="outlined-select-version"
              select
              label="Version"
              value={selectedVersion}
              onChange={(e) => setSelectedVersion(e.target.value)}
            >
              {versionList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>

        <Typography
          variant="h4"
          className="h-full px-4"
          fontSize="1.5rem"
          fontWeight="500"
        >
          {t('assembler.summary')}
        </Typography>

        <div className="flex flex-wrap gap-y-2 gap-x-3 mt-12 mb-2 justify-end">
          <ButtonWrapper onClick={() => setOpen(true)} color={eColor.secondary}>
            {t('assembler.reset')}
          </ButtonWrapper>

          <ModalYesNo
            title="assembler.reset"
            message="assembler.modal.resetMessage"
            yesOption={() => dispatch(sagaBuildAssemblyAction())}
            open={open}
            handleClose={() => setOpen(false)}
          />

          <ButtonWrapper>{t('assembler.save')}</ButtonWrapper>
        </div>
      </PaperWrapper>
    </ContentBodyWrapper>
  )
}

const exportObj = {
  routeProps: {
    path: RoutePaths.toolboxCollector,
    title: 'toolbox.collector',
    element: <Collector />,
    roles: componentPermissions[componentNames.pageAssembler],
    requiresAuthentication: true,
  },
  name: 'Collector',
}

export default exportObj
