/**
 * Convert user's selection in "database" & "type" to a valid contextName.
 */
export const getContextNameFromDatabaseType = (database: string, type: string): string => {
    if (database === 'Materials') {
      switch (type) {
        case 'Metals': return '.materials.metals';
        case 'Elements': return '.materials.elements';
        case 'Polymers': return '.materials.polymers';
        case 'Composites': return '.materials.composites';
        case 'Sinters': return '.materials.sinters';
        case 'Construction': return '.materials.construction';
        case 'Others': return '.materials.others';
        default: return '.materials';
      }
    }
    if (database === 'Logistics') {
      switch (type) {
        case 'Transportation': return '.logistics.transportation';
        case 'Packaging': return '.logistics.packaging';
        default: return '.logistics';
      }
    }
    if (database === 'Energy') {
      // e.g. type: "Electricity Grid" => .energy, or you can refine further if needed
      return '.energy';
    }
    if (database === 'Production') {
      // If you want separate contexts for "Manufacturing", etc., do so here:
      if (type === 'Manufacturing') return '.production.manufacturing';
      return '.production';
    }
  
    // Default fallback:
    return '';
  }

