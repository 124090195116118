import React, { SyntheticEvent, useState, useEffect } from 'react'
import { put } from 'redux-saga/effects'
import { setSingleEntity } from 'store/slices/singleEntity'
import {
  Modal,
  Box,
  Tabs,
  Tab,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  InputAdornment,
  Autocomplete,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import PaperWrapper from 'components/custom/PaperWrapper'
import TitleWrapper from 'components/custom/TitleWrapper'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import { eColor, eTypographyVariant } from 'components/types'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store'
import {
  ContextSearchResults,
  ContextSearchResult,
  ContextName,
} from 'store/types/searchResults'
import { getContextNameFromDatabaseType } from '../../helpers'
import { sagaGetSingleEntityAction } from 'saga/actions/assembler'
import { Co2ReferenceObject } from 'store/types/assembler'
import { AssemblyCo2ReferenceObject } from 'store/types/assembler'
import {
  getDatabaseName,
  getDatabaseType,
  getQuantity,
} from '../CollapsibleTableAssembler/helpers'
import { capitaliseString } from 'config/lib'
import { calculateCo2eForSingleEntity } from './helpers'
import { init } from 'i18next'

interface iProps {
  title: string
  addOption?: (co2RefObj: {
    category: 'materials' | 'logistics' | 'production'
    subCategory: string
    source: 'sustamize' | 'custom' | 'collector'
    sid: string | undefined
    comment: string
    params: {
      netWeight?: number
      recyclingRatio?: number
      shape?: string
      countryCode?: string
      yield?: number
    }
  }) => void
  saveOption?: (co2RefObj: {
    category: 'materials' | 'logistics' | 'production'
    subCategory: string
    source: 'sustamize' | 'custom' | 'collector'
    sid: string | undefined
    comment: string
    params: {
      netWeight?: number
      recyclingRatio?: number
      shape?: string
      countryCode?: string
      yield?: number
    }
  }) => void
  open: boolean
  handleClose: () => void
  mode?: 'add' | 'edit'
  initialItem?: AssemblyCo2ReferenceObject
}
// For Production => custom items
interface IEnergyItem {
  source: string // 'sustamize' | 'custom'
  entity: string // if sustamize => dropdown, if custom => text
  customCo2e?: number // only if source=custom
  consumption: number // numeric
  emissionType: string // 'Indirect' | 'Direct'
}

interface IEquipmentItem {
  source: string // 'sustamize' | 'custom'
  entity: string // if sustamize => dropdown, if custom => text
  customCo2e?: number // only if source=custom
  floorSpace?: number // unit: sqm
}

interface IEmployeesItem {
  source: string // 'sustamize' | 'custom'
  entity: string // if sustamize => dropdown, if custom => text
  customCo2e?: number // only if source=custom
  numberOfWorkers: number // unit: pcs
}

export const AssemblerCo2Calculator: React.FC<iProps> = ({
  title,
  addOption,
  open,
  handleClose,
  saveOption,
  mode,
  initialItem,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // --- TAB STATE ---
  const [activeTab, setActiveTab] = useState<number>(0)

  // --- SIMPLE INPUT STATES ---
  const [database, setDatabase] = useState<string>('')
  const [type, setType] = useState<string>('')
  const [source, setSource] = useState<string>('')
  // For Entity: store the full search result object when selected; also keep a text value for free typing.
  const [selectedEntity, setSelectedEntity] =
    useState<ContextSearchResult | null>(null)
  const [entityText, setEntityText] = useState<string>('')

  // For Country selection (from energy context), store the full object.
  const [selectedCountry, setSelectedCountry] =
    useState<ContextSearchResult | null>(null)

  // Delivery Shape (only for Metals)
  const [deliveryShape, setDeliveryShape] = useState<string>('')
  const [comment, setComment] = useState<string>('')
  const [emissionType, setEmissionType] = useState<string>('')
  const [customFootprint, setCustomFootprint] = useState<number>(0)
  const [netWeight, setNetWeight] = useState<number>(1)
  const [yieldValue, setYieldValue] = useState<number>(100)
  const [recyclingRatio, setRecyclingRatio] = useState<number>(0)
  const [distance, setDistance] = useState<number>(1)
  const [transportGrossWeight, setTransportGrossWeight] = useState<number>(5000)
  const [partDensity, setPartDensity] = useState<number>(5000)
  const [packagingQuantity, setPackagingQuantity] = useState<number>(1)
  const [energyConsumption, setEnergyConsumption] = useState<number>(1)
  const [productionConsumption, setProductionConsumption] = useState<number>(1)
  const [customProductionItem, setCustomProductionItem] = useState<any>()
  const [energyItems, setEnergyItems] = useState<any[]>([])
  const [equipmentItems, setEquipmentItems] = useState<any[]>([])
  const [employeesItems, setEmployeesItems] = useState<any[]>([])
  const [cycleTime, setCycleTime] = useState<number>(3600)
  const [rejectionRate, setRejectionRate] = useState<number>(0.01)
  const [cavities, setCavities] = useState<number>(1)
  const [unit, setUnit] = useState<string>('kg')
  const [calculatedCo2e, setCalculatedCo2e] = useState<number>(0.0)
  const [co2eRefValue, setCo2eRefValue] = useState<number>(0.0)
  useEffect(() => {
    if (mode === 'edit' && initialItem) {
      console.log(initialItem)
      const countryName = (initialItem.params as any)?.countryName
      const entityName = (initialItem.params as any)?.name
      // If you want a full object:
      const matchingObjCountry =
        energyResults.find((opt) => opt.name === countryName) || null

      const matchingObjEntity =
        matchedResults.find((opt) => opt.name === entityName) || null
      // e.g. fill your local states
      setDatabase(
        initialItem.context
          ? capitaliseString(getDatabaseName(initialItem))
          : 'Materials'
      )
      setType(
        initialItem.context
          ? capitaliseString(getDatabaseType(initialItem))
          : 'Others'
      )
      setSource(initialItem.source ?? '')
      setComment(initialItem.comment ?? '')
      setEnergyItems(
        (initialItem.energy ?? []).map((energyItem: any) => ({
          // Only keep these properties
          comment: energyItem.comment ?? '',
          context: energyItem.context ?? '',
          emissionType: energyItem.emissionType ?? '',
          name: energyItem.name ?? '',
          params: {
            // For example, only keep consumption from the params object
            consumption: energyItem.params?.consumption ?? 0,
          },
          sid: energyItem.sid ?? '',
          source: energyItem.source ?? '',
        }))
      )
      setEquipmentItems(initialItem.equipment ?? [])
      setEmployeesItems(initialItem.employees ?? [])
      setEntityText(initialItem.name ?? '')
      setCo2eRefValue(initialItem.entityEmissionReference ?? 0)
      setNetWeight((initialItem.params as any)?.netWeight ?? 1)
      setDistance((initialItem.params as any)?.distance ?? 1)
      setPartDensity((initialItem.params as any)?.partDensity ?? 5000)
      setRecyclingRatio((initialItem.params as any)?.recyclingRatio * 100)
      setTransportGrossWeight(
        (initialItem.params as any)?.transportGrossWeight ?? 5000
      )
      setUnit(initialItem.unit ?? '')
      setCalculatedCo2e(initialItem.calculatedEntityEmission ?? 0)
      setSelectedEntity(matchingObjEntity)
      setSelectedCountry(matchingObjCountry)
      setProductionConsumption((initialItem.params as any)?.consumption ?? 1)
      setEnergyConsumption((initialItem.params as any)?.consumption ?? 1)
      setRejectionRate((initialItem.params as any)?.rejectionRate ?? 0.01)
      setCavities((initialItem.params as any)?.rejectionRate ?? 1)
      setCycleTime((initialItem.params as any)?.cycleTime ?? 3600)
      setDeliveryShape(
        (initialItem.params as any)?.shape
          ? capitaliseString((initialItem.params as any).shape)
          : ''
      )
      setCustomFootprint(
        Number((initialItem.params as any)?.customFootprint)
          ? Number((initialItem.params as any).customFootprint)
          : 0
      )
      // ...
    } else if (mode === 'add') {
      // Clear everything
      setEnergyItems([])
      setEquipmentItems([])
      setEmployeesItems([])
      setCo2eRefValue(0)
      setCalculatedCo2e(0)
      setDatabase('')
      setType('')
      setSource('')
      dispatch(setSingleEntity(null))
      setComment('')
      setUnit('')
      setNetWeight(1)
      setDeliveryShape('')
      setEmployeesItems([])
      setEquipmentItems([])
      setEmployeesItems([])
      setCycleTime(3600)
      setRejectionRate(0.01)
      setCavities(1)
      setRecyclingRatio(0)
      setSelectedEntity(null)
      setDistance(1)
      setPackagingQuantity(1)
      setYieldValue(100)
      setPartDensity(5000)
      setTransportGrossWeight(5000)
      setProductionConsumption(1)
      setEntityText('')
      setSelectedCountry(null)
      setCustomFootprint(0)
      // ...
    }
  }, [mode, open, initialItem])

  // --- TAB SWITCH ---
  const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }
  // --- Access Search Results from Redux ---
  const searchResults: ContextSearchResults | undefined = useSelector(
    (state: RootState) => state.searchResults.searchResults?.data
  )

  // Options for the Country Autocomplete: use search results with contextName === '.energy'
  const energyResults: ContextSearchResult[] =
    searchResults?.results.filter((item) => item.contextName === '.energy') ||
    []
  const energyCarriersResults: ContextSearchResult[] =
    searchResults?.results.filter(
      (item) => item.contextName === '.energy.carriers'
    ) || []
  // (We will use the full object so we can later extract shortName)

  // For the Entity field: compute contextName from database & type, then filter results.
  const computedContextName = getContextNameFromDatabaseType(database, type)
  const matchedResults: ContextSearchResult[] =
    searchResults?.results.filter(
      (item) => item.contextName === computedContextName
    ) || []

  let entityOptions = matchedResults

  // Override entityOptions for "Energy" database:
  if (
    database === 'Energy' &&
    type === 'Electricity Grid' &&
    source === 'sustamize'
  ) {
    entityOptions = energyResults
  } else if (
    database === 'Energy' &&
    type === 'Direct Energy' &&
    source === 'sustamize'
  ) {
    entityOptions = energyCarriersResults
  }

  useEffect(() => {
    if (mode !== 'edit' || !initialItem) {
      return
    }

    const found =
      matchedResults.find((opt) => opt.sid === initialItem.sid) || null

    if (found) {
      // If we found a match by sid
      if (found.sid !== selectedEntity?.sid) {
        setSelectedEntity(found)
        setEntityText(found.name)
      }
    } else {
      // No match found
      // Only set a "fake" object if it’s actually different
      if (selectedEntity?.sid !== initialItem.sid) {
        setSelectedEntity({
          sid: initialItem.sid ?? '',
          contextName: initialItem.context ?? '',
          name: initialItem.name ?? '',
          database: initialItem.category ?? '',
          type: initialItem.subCategory ?? '',
          description: initialItem.description ?? '',
        })
        setEntityText(initialItem.name ?? '')
      }
    }
  }, [mode, initialItem])

  // --- Access Single Entity from Redux ---
  const singleEntity = useSelector(
    (state: RootState) => state.singleEntityData.singleEntity?.data
  )
  // Extract the CO2e value and documentation link from singleEntity (if available)
  useEffect(() => {
    const newCo2eValue =
      singleEntity?.co2Emission?.[0]?.co2Base?.common ?? customFootprint

    // Only update state if the value has changed
    if (newCo2eValue !== co2eRefValue) {
      setCo2eRefValue(newCo2eValue)
    }
  }, [singleEntity, customFootprint, co2eRefValue])
  const documentationLink =
    singleEntity?.documentation?.documentationUrl ?? 'https://www.sustamize.com'

  // --- Conditions for triggering the backend call ---
  useEffect(() => {
    if (source !== 'sustamize' || !selectedEntity) return

    if (database === 'Materials' && type === 'Metals') {
      if (selectedCountry && deliveryShape.trim() !== '') {
        dispatch(
          sagaGetSingleEntityAction({
            sid: selectedEntity.sid,
            country: selectedCountry?.fullContext?.shortName,
            deliveryShapes: deliveryShape.toLowerCase(),
          })
        )
      }
    } else if (
      (database === 'Materials' && type !== 'Metals') ||
      (database === 'Production' && type === 'Manufacturing')
    ) {
      if (selectedCountry) {
        dispatch(
          sagaGetSingleEntityAction({
            sid: selectedEntity.sid,
            country: selectedCountry?.fullContext?.shortName,
          })
        )
      }
    } else if (database === 'Logistics') {
      dispatch(
        sagaGetSingleEntityAction({
          sid: selectedEntity.sid,
        })
      )
    }
    // ADD THESE CASES:
    else if (database === 'Energy' && type === 'Electricity Grid') {
      dispatch(
        sagaGetSingleEntityAction({
          sid: selectedEntity.sid,
        })
      )
    } else if (database === 'Energy' && type === 'Direct Energy') {
      dispatch(
        sagaGetSingleEntityAction({
          sid: selectedEntity.sid,
        })
      )
    }
  }, [
    selectedEntity,
    selectedCountry,
    deliveryShape,
    source,
    database,
    type,
    dispatch,
  ])

  useEffect(() => {
    // If no entity is selected, reset calculated CO2e to 0
    if (!selectedEntity && customFootprint === 0) {
      setCalculatedCo2e(0)
      return
    }

    // Decide consumption based on database
    const relevantConsumption =
      database === 'Energy' ? energyConsumption : productionConsumption

    let calculatedValue = calculatedCo2e

    // Construct the parameters required for calculation
    const calculationParams = {
      contextName: computedContextName, // Compute based on user selection
      netWeight,
      yield: yieldValue / 100, // Convert percentage to decimal
      recyclingRatio: recyclingRatio / 100, // Convert percentage to decimal
      emissionReferencePrimary:
        singleEntity?.co2Emission[0]?.emissionFactorPrimary || 0, // Use stored reference value
      emissionReferenceRecycling:
        singleEntity?.co2Emission[0]?.emissionFactorRecycling || 0,
      distance,
      transportGrossWeight,
      partDensity,
      quantity: packagingQuantity,
      consumption: relevantConsumption,
      equipmentFloorSpace: equipmentItems.reduce(
        (sum, eq) => sum + (eq.floorSpace ?? 0),
        0
      ),
      numberOfWorkers: employeesItems.reduce(
        (sum, emp) => sum + (emp.numberOfWorkers ?? 0),
        0
      ),
    }
    console.warn('calculation params', calculationParams)
    // Call the helper function to calculate CO₂e
    calculatedValue = calculateCo2eForSingleEntity(calculationParams)

    // Update the calculated CO₂e value
    setCalculatedCo2e(calculatedValue)
  }, [
    selectedEntity, // Triggers recalculation when entity is changed
    co2eRefValue, // CO₂e reference value changes when a new entity is selected
    customFootprint, // Custom CO₂e reference change
    netWeight, // Calculation parameters
    yieldValue,
    recyclingRatio,
    distance,
    transportGrossWeight,
    partDensity,
    packagingQuantity,
    productionConsumption,
    energyConsumption,
    equipmentItems, // Listens to changes in energy, equipment, and employees
    employeesItems,
    computedContextName, // Triggers update when filters change
  ])

  // --- Helper for CO2e Label ---
  const getCo2eLabel = (): string => {
    const unit =
      singleEntity?.co2Emission?.[0]?.co2Base?.co2ValueEntries?.[0]
        ?.referenceValueUnit?.symbol
    return unit ?? 'kgCO2e'
  }

  const getCo2eLabelUnit = (): string => {
    const label = getCo2eLabel()
    const slashIndex = label.indexOf('/')
    return slashIndex !== -1
      ? label.substring(slashIndex + 1) // everything after the slash
      : label // fallback: entire label
  }

  const getCo2eLabelReference = (): string => {
    const label = getCo2eLabel()
    const slashIndex = label.indexOf('/')
    return slashIndex !== -1
      ? label.substring(0, slashIndex) // everything before slash
      : label // fallback: entire label
  }

  // --- Dropdown Options ---
  const databaseOptions = ['Materials', 'Logistics', 'Energy', 'Production']
  const getTypeOptions = (): string[] => {
    if (database === 'Materials') {
      return [
        'Metals',
        'Elements',
        'Polymers',
        'Composites',
        'Sinters',
        'Construction',
        'Others',
      ]
    }
    if (database === 'Logistics') {
      return ['Transportation', 'Packaging']
    }
    if (database === 'Energy') {
      return ['Electricity Grid', 'Direct Energy']
    }
    if (database === 'Production') {
      return ['Manufacturing']
    }
    return []
  }
  const getSourceOptions = (): string[] => {
    if (database === 'Materials' && type === 'Others') {
      return ['custom', 'collector']
    }
    return ['sustamize', 'custom', 'collector']
  }

  const showEntityAsDropdown =
    (source === 'sustamize' || source === 'collector') &&
    !(database === 'Energy' && type === 'Electricity Grid')
  const showCountry =
    (database === 'Materials' || database === 'Production') &&
    source === 'sustamize'
  const showDeliveryShape =
    database === 'Materials' && type === 'Metals' && source !== 'custom'
  const showEmissionType = database === 'Logistics' || database === 'Energy'
  const showCo2eReference = !(type === 'Manufacturing' && source === 'custom')
  // Handlers for removing items (for energy, equipment, employees)
  const handleRemoveEnergyItem = (index: number) => {
    setEnergyItems((prev) => prev.filter((_, i) => i !== index))
  }
  const handleRemoveEquipmentItem = (index: number) => {
    setEquipmentItems((prev) => prev.filter((_, i) => i !== index))
  }
  const handleRemoveEmployeesItem = (index: number) => {
    setEmployeesItems((prev) => prev.filter((_, i) => i !== index))
  }

  // --- Render the Simple Input Tab ---
  const renderSimpleInputTab = () => {
    return (
      <div className="mt-4">
        {/* First row: Database, Type, Source, Entity */}
        <Box display="flex" flexWrap="wrap" gap={2} className="mb-4">
          {/* Database */}
          <TextField
            select
            label="Database"
            value={database}
            onChange={(e) => {
              setDatabase(e.target.value)
              setType('')
              setSource('')
              setSelectedEntity(null)
              setEntityText('')
            }}
            style={{ flex: '1 1 200px' }}
          >
            {databaseOptions.map((db) => (
              <MenuItem key={db} value={db}>
                {db}
              </MenuItem>
            ))}
          </TextField>

          {/* Type */}
          {database && (
            <TextField
              select
              label="Type"
              value={type}
              onChange={(e) => {
                setType(e.target.value)
                setSource('')
                setSelectedEntity(null)
                setEntityText('')
              }}
              style={{ flex: '1 1 200px' }}
            >
              {getTypeOptions().map((opt) => (
                <MenuItem key={opt} value={opt}>
                  {opt}
                </MenuItem>
              ))}
            </TextField>
          )}

          {/* Source */}
          {type && (
            <TextField
              select
              label="Source"
              value={source}
              onChange={(e) => {
                setSource(e.target.value)
                setSelectedEntity(null)
                setEntityText('')
              }}
              style={{ flex: '1 1 200px' }}
            >
              {getSourceOptions().map((src) => (
                <MenuItem key={src} value={src}>
                  {src}
                </MenuItem>
              ))}
            </TextField>
          )}

          {/* Entity Field */}
          {source === 'sustamize' ? (
            <Autocomplete
              freeSolo
              options={entityOptions}
              getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.name
              }
              value={
                selectedEntity ||
                (entityText
                  ? ({ name: entityText } as ContextSearchResult)
                  : null)
              }
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  setSelectedEntity(null)
                } else {
                  setSelectedEntity(newValue)
                  setEntityText(newValue?.name || '')
                }
              }}
              onInputChange={(event, newInputValue) => {
                setEntityText(newInputValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Entity Selection"
                  variant="outlined"
                />
              )}
              sx={{ flex: '1 1 200px' }}
            />
          ) : (
            <TextField
              label="Entity Name"
              value={entityText}
              onChange={(e) => setEntityText(e.target.value)}
              style={{ flex: '1 1 200px' }}
            />
          )}
        </Box>

        {/* Second row: Country, Delivery Shape, Comment, Emission Type */}
        <Box display="flex" flexWrap="wrap" gap={2} className="mb-4">
          {showCountry && (
            <Autocomplete
              options={energyResults}
              getOptionLabel={(option) => option.name}
              value={selectedCountry}
              onChange={(event, newValue) => setSelectedCountry(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Country" variant="outlined" />
              )}
              sx={{ flex: '1 1 200px' }}
            />
          )}

          {showDeliveryShape && (
            <TextField
              select
              label="Delivery Shape"
              value={deliveryShape}
              onChange={(e) => setDeliveryShape(e.target.value)}
              style={{ flex: '1 1 200px' }}
            >
              {['Raw', 'Coil', 'Extruded', 'Block'].map((shape) => (
                <MenuItem key={shape} value={shape}>
                  {shape}
                </MenuItem>
              ))}
            </TextField>
          )}

          {source === 'custom' && (
            <TextField
              label="Comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              style={{ flex: '1 1 200px' }}
            />
          )}

          {source === 'custom' &&
            !(database === 'Production' && type === 'Manufacturing') && (
              <TextField
                label="Unit"
                value={unit ?? 'kg'}
                onChange={(e) => setUnit(e.target.value)}
                style={{ flex: '1 1 200px' }}
              />
            )}

          {showEmissionType && (
            <TextField
              select
              label="Emission Type"
              value={emissionType}
              onChange={(e) => setEmissionType(e.target.value)}
              style={{ flex: '1 1 200px' }}
            >
              {['Indirect', 'Direct'].map((et) => (
                <MenuItem key={et} value={et}>
                  {et}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Box>

        {source === 'custom' &&
          !(database === 'Production' && type === 'Manufacturing') && (
            <TextField
              label="Custom CO2e Ref"
              type="number"
              value={customFootprint}
              onChange={(e) => setCustomFootprint(Number(e.target.value))}
              style={{ flex: '1 1 200px', marginBottom: '1rem' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">kgCO2e/{unit}</InputAdornment>
                ),
              }}
            />
          )}

        {showCo2eReference && (
          <Box className="p-2 mb-4 bg-gray-100 rounded">
            <Typography variant="body2">CO2e Reference</Typography>
            <TextField
              label={getCo2eLabel()}
              value={co2eRefValue}
              disabled
              fullWidth
              InputProps={{
                endAdornment:
                  source === 'sustamize' ? (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => window.open(documentationLink, '_blank')}
                        edge="end"
                        size="small"
                      >
                        <HelpOutlineIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ) : undefined,
              }}
            />
          </Box>
        )}

        {/* Calculation Parameters Section */}
        <Typography variant="h6" className="mb-2">
          Calculation Parameters
        </Typography>
        {database === 'Materials' && (
          <Box display="flex" flexWrap="wrap" gap={2} className="mb-4">
            <TextField
              label="Net Weight"
              type="number"
              value={netWeight}
              onChange={(e) => setNetWeight(Number(e.target.value))}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">kg</InputAdornment>
                ),
              }}
              style={{ flex: '1 1 200px' }}
            />
            <TextField
              label="Yield"
              type="number"
              value={yieldValue}
              onChange={(e) => setYieldValue(Number(e.target.value))}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              inputProps={{
                min: 0,
                max: 100,
              }}
              style={{ flex: '1 1 200px' }}
            />
            {source === 'sustamize' &&
              (type === 'Metals' || type === 'Composites') && (
                <TextField
                  label="Recycling Ratio"
                  type="number"
                  value={recyclingRatio}
                  onChange={(e) => setRecyclingRatio(Number(e.target.value))}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  style={{ flex: '1 1 200px' }}
                />
              )}
          </Box>
        )}

        {database === 'Logistics' && type === 'Transportation' && (
          <Box display="flex" flexWrap="wrap" gap={2} className="mb-4">
            <TextField
              label="Distance"
              type="number"
              value={distance}
              onChange={(e) => setDistance(Number(e.target.value))}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {getCo2eLabelUnit()}
                  </InputAdornment>
                ),
              }}
              style={{ flex: '1 1 200px' }}
            />
            <TextField
              label="Transport Gross Weight"
              type="number"
              value={transportGrossWeight}
              onChange={(e) => setTransportGrossWeight(Number(e.target.value))}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">kg</InputAdornment>
                ),
              }}
              style={{ flex: '1 1 200px' }}
            />
            <TextField
              label="Part Density"
              type="number"
              value={partDensity}
              onChange={(e) => setPartDensity(Number(e.target.value))}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">pcs</InputAdornment>
                ),
              }}
              style={{ flex: '1 1 200px' }}
            />
          </Box>
        )}

        {database === 'Logistics' && type === 'Packaging' && (
          <TextField
            label="Quantity"
            type="number"
            className="mb-4"
            value={packagingQuantity}
            onChange={(e) => setPackagingQuantity(Number(e.target.value))}
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
          />
        )}

        {database === 'Energy' && (
          <TextField
            label="Consumption"
            type="number"
            className="mb-4"
            value={energyConsumption}
            onChange={(e) => setEnergyConsumption(Number(e.target.value))}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {getCo2eLabelUnit()}
                </InputAdornment>
              ),
            }}
          />
        )}

        {database === 'Production' && type === 'Manufacturing' && (
          <>
            {source === 'sustamize' && (
              <TextField
                label="Consumption"
                type="number"
                className="mb-4"
                value={productionConsumption}
                onChange={(e) =>
                  setProductionConsumption(Number(e.target.value))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">kWh</InputAdornment>
                  ),
                }}
              />
            )}
            {source === 'custom' && (
              <>
                <Typography variant="subtitle1" className="mb-2">
                  Energy
                </Typography>
                <ButtonWrapper
                  aria-label="Add Energy Item"
                  onClick={() =>
                    setEnergyItems((prev) => [
                      ...prev,
                      {
                        sid: '',
                        name: '',
                        context: '',
                        comment: '',
                        source: '',
                        params: {
                          consumption: '',
                        },
                        emissionType: '',
                      },
                    ])
                  }
                  className="mb-4"
                >
                  Add Item
                </ButtonWrapper>
                {energyItems.map((item, index) => (
                  <Box key={index} className="mb-4 border rounded p-2">
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      gap={2}
                      alignItems="center"
                    >
                      {/* Step A: Energy Type dropdown */}
                      <TextField
                        select
                        label="Energy Type"
                        value={item.energyType ?? getDatabaseType(item)}
                        onChange={(e) => {
                          setEnergyItems((prev) => {
                            const updated = [...prev]
                            updated[index] = {
                              ...updated[index],
                              energyType: e.target.value,
                            }
                            return updated
                          })
                        }}
                        style={{ flex: '1 1 200px' }}
                      >
                        <MenuItem value="Electricity Grid">
                          Electricity Grid
                        </MenuItem>
                        <MenuItem value="Direct Energy">Direct Energy</MenuItem>
                      </TextField>
                      {/* Source */}
                      <TextField
                        select
                        label="Source"
                        value={item.source}
                        onChange={(e) =>
                          setEnergyItems((prev) => {
                            const updated = [...prev]
                            updated[index] = {
                              ...updated[index],
                              source: e.target.value,
                            }
                            return updated
                          })
                        }
                        style={{ flex: '1 1 140px' }}
                      >
                        {['sustamize', 'custom'].map((src) => (
                          <MenuItem key={src} value={src}>
                            {src}
                          </MenuItem>
                        ))}
                      </TextField>
                      {/* Step B: Conditionally show the Autocomplete for entity selection */}
                      {(item.energyType === 'Electricity Grid' ||
                        getDatabaseType(item) === 'Electricity Grid') &&
                        item.source !== 'custom' && (
                          <Autocomplete
                            options={energyResults} // e.g. from Redux, same as your code
                            getOptionLabel={(option) => option.name}
                            value={
                              energyResults.find(
                                (opt) => opt.name === item.name
                              ) || null
                            }
                            onChange={(event, newValue) => {
                              setEnergyItems((prev) => {
                                const updated = [...prev]
                                updated[index] = {
                                  ...updated[index],
                                  name: newValue?.name ?? '',
                                  context: newValue?.contextName ?? '',
                                  comment: updated[index].comment ?? '',
                                  sid: newValue?.sid ?? '',
                                }
                                return updated
                              })
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Select Grid" />
                            )}
                            sx={{ flex: '1 1 200px' }}
                          />
                        )}
                      {item.energyType === 'Direct Energy' &&
                        item.source !== 'custom' && (
                          <Autocomplete
                            options={energyCarriersResults} // from Redux
                            getOptionLabel={(option) => option.name}
                            value={
                              energyCarriersResults.find(
                                (opt) => opt.name === item.name
                              ) || null
                            }
                            onChange={(event, newValue) => {
                              setEnergyItems((prev) => {
                                const updated = [...prev]
                                updated[index] = {
                                  ...updated[index],
                                  name: newValue?.name ?? '',
                                  context: newValue?.contextName ?? '',
                                  comment: updated[index].comment ?? '',
                                  sid: newValue?.sid ?? '',
                                }
                                return updated
                              })
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Direct Energy"
                              />
                            )}
                            sx={{ flex: '1 1 200px' }}
                          />
                        )}
                      {/* Custom CO2e Reference only if source=custom */}
                      {item.source === 'custom' && (
                        <TextField
                          label="Custom CO2e Ref"
                          type="number"
                          value={item.params.customFootprint ?? ''}
                          onChange={(e) =>
                            setEnergyItems((prev) => {
                              const updated = [...prev]
                              updated[index].params = {
                                ...updated[index].params,
                                customFootprint: Number(e.target.value),
                              }
                              console.log(
                                energyItems,
                                employeesItems,
                                equipmentItems
                              )
                              return updated
                            })
                          }
                          style={{ flex: '1 1 140px' }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                kgCO2e/kWh
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      {/* Consumption */}
                      <TextField
                        label={
                          item.entity === 'Oil'
                            ? 'Consumption (cbm/h)'
                            : item.entity === 'Natural Gas'
                            ? 'Consumption (l/h)'
                            : 'Consumption (kWh)'
                        }
                        type="number"
                        value={item.params.consumption}
                        onChange={(e) =>
                          setEnergyItems((prev) => {
                            const updated = [...prev]
                            updated[index].params = {
                              ...updated[index].params,
                              consumption: Number(e.target.value),
                            }
                            return updated
                          })
                        }
                        style={{ flex: '1 1 140px' }}
                      />
                      {/* Emission Type */}
                      <TextField
                        select
                        label="Emission Type"
                        value={item.emissionType}
                        onChange={(e) =>
                          setEnergyItems((prev) => {
                            const updated = [...prev]
                            updated[index] = {
                              ...updated[index],
                              emissionType: e.target.value,
                            }
                            return updated
                          })
                        }
                        style={{ flex: '1 1 140px' }}
                      >
                        {['Indirect', 'Direct'].map((et) => (
                          <MenuItem key={et} value={et}>
                            {et}
                          </MenuItem>
                        ))}
                      </TextField>
                      {/* Remove button (trash icon) */}
                      <IconButton
                        aria-label="remove item"
                        onClick={() => handleRemoveEnergyItem(index)}
                        color="error"
                        style={{ flex: '0 0 auto' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>

                    {/* CO2e Reference (highlighted, read-only) */}
                    {/* <Box className="mt-2 bg-gray-100 p-2 rounded">
                      <Typography variant="body2">
                        CO2e Reference (
                        {item.entity === 'Oil'
                          ? 'gCO2e/cbm'
                          : item.entity === 'Natural Gas'
                          ? 'gCO2e/l'
                          : 'gCO2e/kWh'}
                        )
                      </Typography>
                      <TextField value="20.0" disabled fullWidth />
                    </Box> */}
                  </Box>
                ))}

                {/* EQUIPMENT SECTION */}
                <Typography variant="subtitle1" className="mb-2">
                  Equipment
                </Typography>
                <ButtonWrapper
                  aria-label="Add Equipment Item"
                  onClick={() =>
                    setEquipmentItems((prev) => [
                      ...prev,
                      {
                        //sid: '',
                        name: '',
                        context: '',
                        comment: '',
                        source: '',
                        params: {
                          equipmentFloorSpace: '',
                          customFootprint: 0,
                        },
                        emissionType: '',
                      },
                    ])
                  }
                  className="mb-4"
                >
                  Add Item
                </ButtonWrapper>

                {equipmentItems.map((item, index) => (
                  <Box key={index} className="mb-4 border rounded p-2">
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      gap={2}
                      alignItems="center"
                    >
                      {/* Source */}
                      <TextField
                        select
                        label="Source"
                        value={item.source}
                        onChange={(e) =>
                          setEquipmentItems((prev) => {
                            const updated = [...prev]
                            updated[index] = {
                              ...updated[index],
                              source: e.target.value,
                            }
                            return updated
                          })
                        }
                        style={{ flex: '1 1 140px' }}
                      >
                        {['sustamize', 'custom'].map((src) => (
                          <MenuItem key={src} value={src}>
                            {src}
                          </MenuItem>
                        ))}
                      </TextField>

                      {/* Entity */}
                      {item.source === 'sustamize' ? (
                        <TextField
                          select
                          label="Entity"
                          value={item.entity}
                          onChange={(e) =>
                            setEquipmentItems((prev) => {
                              const updated = [...prev]
                              updated[index] = {
                                ...updated[index],
                                entity: e.target.value,
                              }
                              return updated
                            })
                          }
                          style={{ flex: '1 1 140px' }}
                        >
                          {['Default Equipment 1', 'Default Equipment 2'].map(
                            (ent) => (
                              <MenuItem key={ent} value={ent}>
                                {ent}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                      ) : (
                        <TextField
                          label="Entity (custom)"
                          value={item.entity}
                          onChange={(e) =>
                            setEquipmentItems((prev) => {
                              const updated = [...prev]
                              updated[index] = {
                                ...updated[index],
                                entity: e.target.value,
                              }
                              return updated
                            })
                          }
                          style={{ flex: '1 1 140px' }}
                        />
                      )}

                      {/* Custom CO2e Reference only if source=custom */}
                      {item.source === 'custom' && (
                        <TextField
                          label="Custom CO2e Ref"
                          type="number"
                          value={item.params.customFootprint ?? ''}
                          onChange={(e) =>
                            setEquipmentItems((prev) => {
                              const updated = [...prev]
                              updated[index].params = {
                                ...updated[index].params,
                                customFootprint: Number(e.target.value),
                              }
                              return updated
                            })
                          }
                          style={{ flex: '1 1 140px' }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                kgCO2e/sqm
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}

                      {/* Floor Space (sqm) */}
                      <TextField
                        label="Floor Space"
                        type="number"
                        value={item.params.equipmentFloorSpace ?? 0}
                        onChange={(e) =>
                          setEquipmentItems((prev) => {
                            const updated = [...prev]
                            updated[index].params = {
                              ...updated[index].params,
                              equipmentFloorSpace: Number(e.target.value),
                            }
                            return updated
                          })
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">sqm</InputAdornment>
                          ),
                        }}
                        style={{ flex: '1 1 140px' }}
                      />

                      {/* Remove button (trash icon) */}
                      <IconButton
                        aria-label="remove item"
                        onClick={() => handleRemoveEquipmentItem(index)}
                        color="error"
                        style={{ flex: '0 0 auto' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>

                    {/* CO2e Reference (highlighted, read-only) => kgCO2e/sqm */}
                    {/* <Box className="mt-2 bg-gray-100 p-2 rounded">
                      <Typography variant="body2">
                        CO2e Reference (kgCO2e/sqm)
                      </Typography>
                      <TextField value="5.0" disabled fullWidth />
                    </Box> */}
                  </Box>
                ))}

                {/* EMPLOYEES SECTION */}
                <Typography variant="subtitle1" className="mb-2">
                  Employees
                </Typography>
                <ButtonWrapper
                  aria-label="Add Employees Item"
                  onClick={() =>
                    setEmployeesItems((prev) => [
                      ...prev,
                      {
                        //sid: '',
                        name: '',
                        context: '',
                        comment: '',
                        source: '',
                        params: {
                          numberOfWorkers: '',
                          customFootprint: 0,
                        },
                        emissionType: '',
                      },
                    ])
                  }
                  className="mb-4"
                >
                  Add Item
                </ButtonWrapper>

                {employeesItems.map((item, index) => (
                  <Box key={index} className="mb-4 border rounded p-2">
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      gap={2}
                      alignItems="center"
                    >
                      {/* Source */}
                      <TextField
                        select
                        label="Source"
                        value={item.source}
                        onChange={(e) =>
                          setEmployeesItems((prev) => {
                            const updated = [...prev]
                            updated[index] = {
                              ...updated[index],
                              source: e.target.value,
                            }
                            return updated
                          })
                        }
                        style={{ flex: '1 1 140px' }}
                      >
                        {['sustamize', 'custom'].map((src) => (
                          <MenuItem key={src} value={src}>
                            {src}
                          </MenuItem>
                        ))}
                      </TextField>

                      {/* Entity */}
                      {item.source === 'sustamize' ? (
                        <TextField
                          select
                          label="Entity"
                          value={item.entity}
                          onChange={(e) =>
                            setEmployeesItems((prev) => {
                              const updated = [...prev]
                              updated[index] = {
                                ...updated[index],
                                entity: e.target.value,
                              }
                              return updated
                            })
                          }
                          style={{ flex: '1 1 140px' }}
                        >
                          {['Employee Type 1', 'Employee Type 2'].map((ent) => (
                            <MenuItem key={ent} value={ent}>
                              {ent}
                            </MenuItem>
                          ))}
                        </TextField>
                      ) : (
                        <TextField
                          label="Entity (custom)"
                          value={item.entity}
                          onChange={(e) =>
                            setEmployeesItems((prev) => {
                              const updated = [...prev]
                              updated[index] = {
                                ...updated[index],
                                entity: e.target.value,
                              }
                              return updated
                            })
                          }
                          style={{ flex: '1 1 140px' }}
                        />
                      )}

                      {/* Custom CO2e Reference if source=custom */}
                      {item.source === 'custom' && (
                        <TextField
                          label="Custom CO2e Ref"
                          type="number"
                          value={item.customFootprint ?? ''}
                          onChange={(e) =>
                            setEmployeesItems((prev) => {
                              const updated = [...prev]
                              updated[index].params = {
                                ...updated[index].params,
                                customFootprint: Number(e.target.value),
                              }
                              return updated
                            })
                          }
                          style={{ flex: '1 1 140px' }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                kgCO2e/worker
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}

                      {/* Number of Workers (pcs) */}
                      <TextField
                        label="Number of Workers"
                        type="number"
                        value={item.numberOfWorkers}
                        onChange={(e) =>
                          setEmployeesItems((prev) => {
                            const updated = [...prev]
                            updated[index].params = {
                              ...updated[index].params,
                              numberOfWorkers: Number(e.target.value),
                            }
                            return updated
                          })
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">pcs</InputAdornment>
                          ),
                        }}
                        style={{ flex: '1 1 140px' }}
                      />

                      {/* Remove button (trash icon) */}
                      <IconButton
                        aria-label="remove item"
                        onClick={() => handleRemoveEmployeesItem(index)}
                        color="error"
                        style={{ flex: '0 0 auto' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>

                    {/* CO2e Reference (highlighted, read-only) */}
                    {/* <Box className="mt-2 bg-gray-100 p-2 rounded">
                      <Typography variant="body2">
                        CO2e Reference (kgCO2e/worker)
                      </Typography>
                      <TextField value="8.0" disabled fullWidth />
                    </Box> */}
                  </Box>
                ))}
                <Typography variant="subtitle1" className="mb-2">
                  Extra Calculation Parameters
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={2} className="mb-4">
                  <TextField
                    label="Cycle Time"
                    type="number"
                    value={cycleTime}
                    onChange={(e) => setCycleTime(Number(e.target.value))}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">sec</InputAdornment>
                      ),
                    }}
                    style={{ flex: '1 1 140px' }}
                  />
                  <TextField
                    label="Rejection Rate"
                    type="number"
                    value={rejectionRate}
                    onChange={(e) => setRejectionRate(Number(e.target.value))}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    style={{ flex: '1 1 140px' }}
                  />
                  <TextField
                    label="Cavities"
                    type="number"
                    value={cavities}
                    onChange={(e) => setCavities(Number(e.target.value))}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">pcs</InputAdornment>
                      ),
                    }}
                    style={{ flex: '1 1 140px' }}
                  />
                </Box>
              </>
            )}
          </>
        )}
        <Box className="p-2 mb-4 bg-gray-100 rounded">
          <Typography variant="body2">Calculated CO2e</Typography>
          <TextField
            label={'kgCO2e'}
            value={calculatedCo2e.toFixed(5)}
            disabled
            fullWidth
          />
        </Box>
      </div>
    )
  }

  const renderSubAssemblyTab = () => {
    return <div className="mt-4">Sub-assembly Tab Content</div>
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[90%] md:w-[70%] lg:w-[60%]"
        sx={{ maxHeight: '80vh', overflowY: 'auto' }}
      >
        <PaperWrapper className="p-4">
          <TitleWrapper
            id="modal-modal-title"
            variant={eTypographyVariant.h6}
            className="2xl:text-xl"
          >
            {t(title)}
          </TitleWrapper>
          <Tabs value={activeTab} onChange={handleTabChange} className="mt-2">
            <Tab label="Simple Input" />
            <Tab label="Sub-assembly" />
          </Tabs>
          {activeTab === 0 && renderSimpleInputTab()}
          {activeTab === 1 && renderSubAssemblyTab()}
          <div className="flex flex-wrap gap-2 mt-4" aria-hidden="true">
            {mode === 'add' && (
              <>
                {/* Cancel or Close */}
                <ButtonWrapper
                  aria-label={t('general.cancel')}
                  onClick={handleClose}
                  color={eColor.secondary}
                >
                  {t('general.cancel')}
                </ButtonWrapper>
                <ButtonWrapper
                  aria-label={t('general.add')}
                  color={eColor.primary}
                  onClick={() => {
                    // We decide the 'category' from 'database'
                    // We only allow these three for addOption, so we force them:
                    let finalCategory: 'materials' | 'logistics' | 'production'

                    if (database === 'Materials') {
                      finalCategory = 'materials'
                    } else if (database === 'Logistics') {
                      finalCategory = 'logistics'
                    } else {
                      // If your UI also has 'Production' or 'Energy', pick one or default
                      finalCategory = 'production'
                    }

                    // We decide the subCategory from 'type':
                    let subCategory = ''
                    if (database === 'Materials') {
                      // e.g. 'Metals' => 'metals', 'Polymers' => 'polymers', 'Others' => 'other', etc.
                      if (type === 'Metals') subCategory = 'metals'
                      else if (type === 'Polymers') subCategory = 'polymers'
                      else if (type === 'Sinters') subCategory = 'sinters'
                      else if (type === 'Elements') subCategory = 'elements'
                      else if (type === 'Composites') subCategory = 'composites'
                      else if (type === 'Others') subCategory = 'other'
                    } else if (database === 'Logistics') {
                      // 'Transportation' => 'transportation', 'Packaging' => 'packaging'
                      if (type === 'Transportation')
                        subCategory = 'transportation'
                      else if (type === 'Packaging') subCategory = 'packaging'
                    } else if (database === 'Production') {
                      // For now, we only coded 'Manufacturing' => 'manufacturing'
                      if (type === 'Manufacturing')
                        subCategory = 'manufacturing'
                      // If you had additional, e.g. 'Employees' => 'employees', etc.
                      // or for your custom approach you might break it out further.
                    }
                    // etc. Possibly do the same for 'Energy' => ??? You may decide
                    // to treat Energy as category= 'production' with subCategory= 'energy',
                    // or keep it separate if you have a separate system.
                    // For example:
                    if (database === 'Energy') {
                      // Could either treat it as part of 'production', or 'logistics'?
                      // Implementation detail depends on your domain model
                      subCategory = 'energy' // or 'electricityGrid'
                    }

                    // Decide if it goes into sustamize or custom:
                    let finalSource: 'sustamize' | 'custom' | 'collector' =
                      'sustamize'
                    if (source === 'custom') finalSource = 'custom'
                    else if (source === 'collector') finalSource = 'collector'

                    // Build a minimal Co2ReferenceObject
                    // See store/types/assembler => Co2ReferenceObject
                    const newItem = {
                      category: finalCategory, // for convenience, not part of Co2ReferenceObject
                      subCategory, // for convenience
                      source: finalSource,
                      name: selectedEntity?.name || entityText,
                      // Required Co2ReferenceObject fields:
                      sid: selectedEntity?.sid,
                      context:
                        selectedEntity?.contextName || computedContextName,
                      comment: comment,
                      unit: unit,
                      entityEmissionReference: Number(co2eRefValue) || 0,
                      calculatedEntityEmission: 0,
                      emissionType: emissionType.toLowerCase() || 'indirect',
                      params: {} as Record<string, unknown>,
                      baseParams: {
                        cavities: cavities,
                        cycleTime: cycleTime,
                        reject: rejectionRate,
                      },
                      equipment: equipmentItems,
                      employees: employeesItems,
                      energy: energyItems,
                    }

                    // ------------------ MATERIALS ------------------
                    if (database === 'Materials') {
                      // Common to all Materials:
                      newItem.params.netWeight = netWeight
                      newItem.params.yield = yieldValue / 100
                      newItem.params.countryCode =
                        selectedCountry?.fullContext?.shortName

                      if (type === 'Metals' || type === 'Composites') {
                        newItem.params.recyclingRatio = recyclingRatio / 100
                      }

                      // For metals only:
                      if (type === 'Metals') {
                        newItem.params.shape = deliveryShape
                      }
                      // If you have other sub-types (Polymers, Elements, etc.):
                      // else if (type === 'Polymers') { ... }
                      // else if (type === 'Elements') { ... }
                      // etc.
                    }

                    // ------------------ LOGISTICS ------------------
                    else if (database === 'Logistics') {
                      if (type === 'Transportation') {
                        newItem.params.distance = distance
                        newItem.params.transportGrossWeight =
                          transportGrossWeight
                        newItem.params.partDensity = partDensity
                      } else if (type === 'Packaging') {
                        newItem.params.quantity = packagingQuantity
                      }
                    }

                    // ------------------ PRODUCTION ------------------
                    else if (database === 'Production') {
                      if (type === 'Manufacturing') {
                        newItem.params.consumption = productionConsumption
                      }
                      // If you also treat subCategories like "energy", "equipment", "employees" here,
                      // add them. For example:
                      // else if (type === 'Equipment') { ... }

                      // These custom injection molding params might apply regardless of subCategory:
                      // if (source === 'custom') {
                      //   newItem.baseParams.cycleTime = cycleTime
                      //   newItem.baseParams.cavities = cavities
                      //   newItem.baseParams.reject = rejectionRate
                      // }
                    }

                    // ------------------ ENERGY ------------------
                    else if (database === 'Energy') {
                      // If you keep "Energy" as a separate database, store consumption here:
                      newItem.params.consumption = energyConsumption
                    }

                    // ------------------ CUSTOM FOOTPRINT ------------------
                    // If user typed a custom CO2 value, store it in params for any category
                    if (customFootprint && Number(customFootprint) !== 0) {
                      newItem.params.customFootprint = Number(customFootprint)
                    }

                    // 3) call addOption with that object
                    if (addOption) addOption(newItem)
                    console.log('new Item', newItem)
                    // 4) (Optionally) close the modal or do any local cleanup
                  }}
                >
                  {t('general.add')}
                </ButtonWrapper>
              </>
            )}

            {mode === 'edit' && (
              <>
                {/* Cancel or Close */}
                <ButtonWrapper
                  aria-label={t('general.cancel')}
                  onClick={handleClose}
                  color={eColor.secondary}
                >
                  {t('general.cancel')}
                </ButtonWrapper>
                <ButtonWrapper
                  aria-label={t('button.saveChanges')}
                  color={eColor.primary}
                  onClick={() => {
                    // We decide the 'category' from 'database'
                    let finalCategory: 'materials' | 'logistics' | 'production'

                    if (database === 'Materials') {
                      finalCategory = 'materials'
                    } else if (database === 'Logistics') {
                      finalCategory = 'logistics'
                    } else {
                      finalCategory = 'production'
                    }

                    // Decide the subCategory from 'type'
                    let subCategory = ''
                    if (database === 'Materials') {
                      if (type === 'Metals') subCategory = 'metals'
                      else if (type === 'Polymers') subCategory = 'polymers'
                      else if (type === 'Sinters') subCategory = 'sinters'
                      else if (type === 'Elements') subCategory = 'elements'
                      else if (type === 'Composites') subCategory = 'composites'
                      else if (type === 'Others') subCategory = 'other'
                    } else if (database === 'Logistics') {
                      if (type === 'Transportation')
                        subCategory = 'transportation'
                      else if (type === 'Packaging') subCategory = 'packaging'
                    } else if (database === 'Production') {
                      if (type === 'Manufacturing' || type === undefined)
                        subCategory = 'manufacturing'
                    }

                    // If "Energy", treat as 'production' with 'energy' subCategory, etc.
                    if (database === 'Energy') {
                      subCategory = 'energy'
                    }

                    // Decide if it goes into sustamize or custom
                    let finalSource: 'sustamize' | 'custom' | 'collector' =
                      'sustamize'
                    if (source === 'custom') finalSource = 'custom'
                    else if (source === 'collector') finalSource = 'collector'

                    // Build the Co2ReferenceObject from local state
                    const updatedItem = {
                      category: finalCategory, // convenience property (not official field)
                      subCategory, // convenience property
                      source: finalSource,
                      name: selectedEntity?.name || entityText,
                      // Required Co2ReferenceObject fields:
                      sid: selectedEntity?.sid,
                      context:
                        selectedEntity?.contextName || computedContextName,
                      comment: comment,
                      unit: unit,
                      entityEmissionReference: Number(co2eRefValue) || 0,
                      calculatedEntityEmission: 0,
                      emissionType: emissionType.toLowerCase() || 'indirect',
                      params: {} as Record<string, unknown>,
                      energy: [] as any[],
                      equipment: [] as any[],
                      employees: [] as any[],
                      baseParams: {},
                    }

                    // ------------------ MATERIALS ------------------
                    if (database === 'Materials') {
                      // Common to all Materials:
                      updatedItem.params.netWeight = netWeight
                      updatedItem.params.yield = yieldValue / 100
                      updatedItem.params.countryCode =
                        selectedCountry?.fullContext?.shortName

                      if (
                        source === 'sustamize' &&
                        (type === 'Metals' || type === 'Composites')
                      ) {
                        updatedItem.params.recyclingRatio = recyclingRatio / 100
                      }

                      // For metals only:
                      if (type === 'Metals') {
                        updatedItem.params.shape = deliveryShape
                      }
                      // If you have other sub-types (Polymers, Elements, etc.):
                      // else if (type === 'Polymers') { ... }
                      // else if (type === 'Elements') { ... }
                      // etc.
                    }

                    // ------------------ LOGISTICS ------------------
                    else if (database === 'Logistics') {
                      if (type === 'Transportation') {
                        updatedItem.params.distance = distance
                        updatedItem.params.transportGrossWeight =
                          transportGrossWeight
                        updatedItem.params.partDensity = partDensity
                      } else if (type === 'Packaging') {
                        updatedItem.params.quantity = packagingQuantity
                      }
                    }

                    // ------------------ PRODUCTION ------------------
                    else if (database === 'Production') {
                      if (type === 'Manufacturing') {
                        updatedItem.params.consumption = productionConsumption
                      }
                      // If you also treat subCategories like "energy", "equipment", "employees" here,
                      // add them. For example:
                      // else if (type === 'Equipment') { ... }

                      // These custom injection molding params might apply regardless of subCategory:
                      if (source === 'custom') {
                        updatedItem.baseParams = {
                          cycleTime: cycleTime,
                          cavities: cavities,
                          reject: rejectionRate,
                        }
                        updatedItem.energy = energyItems
                        updatedItem.employees = employeesItems
                        updatedItem.equipment = equipmentItems
                      }
                    }

                    // ------------------ ENERGY ------------------
                    else if (database === 'Energy') {
                      // If you keep "Energy" as a separate database, store consumption here:
                      updatedItem.params.consumption = energyConsumption
                    }

                    // ------------------ CUSTOM FOOTPRINT ------------------
                    // If user typed a custom CO2 value, store it in params for any category
                    if (customFootprint && Number(customFootprint) !== 0) {
                      updatedItem.params.customFootprint =
                        Number(customFootprint)
                    }
                    if (saveOption) {
                      saveOption(updatedItem)
                    }

                    console.log('Edited Item =>', updatedItem)

                    // call the "saveOption" prop for editing
                  }}
                >
                  {t('button.saveChanges')}
                </ButtonWrapper>
              </>
            )}
          </div>
        </PaperWrapper>
      </Box>
    </Modal>
  )
}
