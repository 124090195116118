import React, { useState } from 'react'
import TextWrapper from 'components/custom/TextWrapper'
import { Modal, Box, TextField } from '@mui/material'
import PaperWrapper from 'components/custom/PaperWrapper'
import TitleWrapper from 'components/custom/TitleWrapper'
import { eColor, eTypographyVariant } from 'components/types'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

interface ModalYesNo {
  title: string
  message: string
  yesOption: () => void
  open: boolean
  handleClose: () => void
}

export const ModalYesNo: React.FC<ModalYesNo> = ({
  title,
  message,
  yesOption,
  open,
  handleClose,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className=" absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[40%]">
        <PaperWrapper className="p-4">
          <TitleWrapper
            id="modal-modal-title"
            variant={eTypographyVariant.h6}
            className="2xl:text-xl"
          >
            {t(title)}
          </TitleWrapper>
          <TextWrapper
            id="modal-modal-description"
            className="mb-4"
            sx={{ marginTop: 2 }}
          >
            {t(message)}
          </TextWrapper>
          <div className="flex flex-wrap gap-2" aria-hidden="true">
            <ButtonWrapper
              color={eColor.secondary}
              aria-label={t('general.yes')}
              onClick={(e) => {
                e.stopPropagation() // <--- crucial
                yesOption()
              }}
            >
              {t('general.yes')}
            </ButtonWrapper>
            <ButtonWrapper
              aria-label={t('general.no')}
              onClick={() => handleClose()}
            >
              {t('general.no')}
            </ButtonWrapper>
          </div>
        </PaperWrapper>
      </Box>
    </Modal>
  )
}

interface ModalRename {
  title: string
  message: string
  open: boolean
  handleClose: () => void
  // Our rename callback can receive the new name:
  yesOption?: (newName: string) => void
  // (Optional) prefill the text field with an existing name
  currentName?: string
  existingNames?: string[] // new prop
}

export const ModalRename: React.FC<ModalRename> = ({
  title,
  message,
  yesOption,
  open,
  handleClose,
  currentName,
  existingNames,
}) => {
  const { t } = useTranslation()

  const [newName, setNewName] = useState(currentName || '')
  const [errorMessage, setErrorMessage] = useState('')

  // Reset local fields when the modal opens or currentName changes
  useEffect(() => {
    setNewName(currentName || '')
    setErrorMessage('')
  }, [open, currentName])

  const handleConfirm = () => {
    // Trim the new name just for consistency
    const trimmedName = newName.trim()

    // 2) Check if the name is already in the list (case-insensitive)
    //    ignoring the currently selected assembly’s name if you want
    if (existingNames?.includes(trimmedName.toLowerCase())) {
      setErrorMessage('Entered name already exists.')
      return
    }

    // If no issues, call yesOption
    if (yesOption) {
      yesOption(trimmedName)
    }
    handleClose()
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[40%]">
        <PaperWrapper className="p-4">
          <TitleWrapper
            id="modal-modal-title"
            variant={eTypographyVariant.h6}
            className="2xl:text-xl"
          >
            {t(title)}
          </TitleWrapper>

          <TextWrapper
            id="modal-modal-description"
            className="mb-4"
            sx={{ marginTop: 2 }}
          >
            {t(message)}
          </TextWrapper>

          <TextField
            label={t('Enter new name')}
            variant="outlined"
            fullWidth
            value={newName}
            onChange={(e) => {
              setNewName(e.target.value)
              setErrorMessage('')
            }}
            sx={{ mb: 2 }}
            error={Boolean(errorMessage)}
            helperText={errorMessage || ''}
          />

          <div className="flex flex-wrap gap-2" aria-hidden="true">
            <ButtonWrapper
              color={eColor.secondary}
              aria-label={t('general.rename')}
              onClick={handleConfirm}
            >
              {t('general.rename')}
            </ButtonWrapper>
            <ButtonWrapper
              aria-label={t('general.cancel')}
              onClick={handleClose}
            >
              {t('general.cancel')}
            </ButtonWrapper>
          </div>
        </PaperWrapper>
      </Box>
    </Modal>
  )
}

export interface ModalNewNameProps {
  title: string
  message: string
  open: boolean
  handleClose: () => void
  yesOption: (nameEntered: string) => void
  existingNames: string[] // new prop for validation
}

export const ModalNewName: React.FC<ModalNewNameProps> = ({
  title,
  message,
  open,
  handleClose,
  yesOption,
  existingNames,
}) => {
  const { t } = useTranslation()

  // Local state for the new assembly name
  const [newName, setNewName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  // If the modal re-opens, clear the name & errors
  useEffect(() => {
    if (open) {
      setNewName('')
      setErrorMessage('')
    }
  }, [open])

  const handleConfirm = () => {
    const trimmedName = newName.trim()

    // Optional: disallow empty string
    if (!trimmedName) {
      setErrorMessage(
        t('assembler.modal.error.emptyName') || 'Name cannot be empty.'
      )
      return
    }

    // Check if name already exists
    if (existingNames.includes(trimmedName.toLowerCase())) {
      setErrorMessage(
        t('assembler.modal.error.nameExists') || 'Name already exists.'
      )
      return
    }

    // If all good, pass the name up
    yesOption(trimmedName)
    handleClose()
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[40%]">
        <PaperWrapper className="p-4">
          <TitleWrapper
            id="modal-modal-title"
            variant={eTypographyVariant.h6}
            className="2xl:text-xl"
          >
            {t(title)}
          </TitleWrapper>

          <TextWrapper
            id="modal-modal-description"
            className="mb-4"
            sx={{ marginTop: 2 }}
          >
            {t(message)}
          </TextWrapper>

          <TextField
            label={'Assembly Name'}
            variant="outlined"
            fullWidth
            value={newName}
            onChange={(e) => {
              setNewName(e.target.value)
              setErrorMessage('')
            }}
            sx={{ mb: 2 }}
            error={Boolean(errorMessage)}
            helperText={errorMessage || ''}
          />

          <div className="flex flex-wrap gap-2" aria-hidden="true">
            <ButtonWrapper
              color={eColor.secondary}
              aria-label={t('general.create')}
              onClick={handleConfirm}
            >
              {t('general.create')}
            </ButtonWrapper>
            <ButtonWrapper
              aria-label={t('general.cancel')}
              onClick={handleClose}
            >
              {t('general.cancel')}
            </ButtonWrapper>
          </div>
        </PaperWrapper>
      </Box>
    </Modal>
  )
}
