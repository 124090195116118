import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import apiRequests from 'config/services';
import { iSingleEntity } from 'store/types/singleEntity'; 
// ^ or wherever your iSingleEntity interface is
import { setSingleEntity } from 'store/slices/singleEntity';
import { sagaGetAssemblyAction,sagaGetAssemblyListingAction } from 'saga/actions/assembler';
import { assemblerConstants } from 'saga/actions/assembler';
import { takeLatest } from 'redux-saga/effects';
import { AssemblyListing, iAssemblyModel } from 'store/types/assembler';
import { setassemblyModel } from 'store/slices/assemblyModelSlice';

export function* createAssemblySaga(
  action: PayloadAction<{ name: string }>
): SagaIterator {
  try {
    // Destructure from the action payload
    const { name} = action.payload;

    // Call your API function with both arguments
    const data: any = yield call(apiRequests.createNewAssembly, name);

    console.warn('createAssemblySaga called', data);

    // Save to global state
    //yield put(setassemblyModel(data));
    yield put(sagaGetAssemblyListingAction())      // Refresh the list of assemblies

  } catch (e) {
    console.warn('Error in createAssemblySaga:', e);
  }
}

// Listener saga
export function* createAssemblyHook() {
  // on SAGA_GET_SEARCH_RESULTS action, run getSearchResultsSaga
  yield takeLatest(assemblerConstants.SAGA_CREATE_ASSEMBLY, createAssemblySaga)
}
