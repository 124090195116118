import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes/paths'
import PaperWrapper from 'components/custom/PaperWrapper'
import ContentBodyWrapper from 'components/custom/ContentBodyWrapper'
import { componentPermissions } from 'config/permissions'
import { componentNames, Roles } from 'config/roles'
import { userReducerState } from 'store/slices/user' // Import your selector

const Toolbox: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  // Use the selector to get user roles
  const userRoles = useSelector(userReducerState).roles

  useEffect(() => {
    // If the user does NOT have the required role, navigate to 403 page
    if (!userRoles.includes(Roles.ROLE_COMMERCIAL_DB_CONSUMER_PRODUCTS)) {
      navigate(RoutePaths.accessForbidden, { replace: true })
    }
  }, [userRoles, navigate])

  return (
    <ContentBodyWrapper title={t('toolbox.title')}>
      <PaperWrapper>
        <div className="flex justify-between gap-x-3 mb-4">
          {/* Your toolbox content here */}
        </div>
      </PaperWrapper>
    </ContentBodyWrapper>
  )
}

const exportObj = {
  routeProps: {
    path: RoutePaths.toolbox,
    title: 'toolbox.title',
    element: <Toolbox />,
    roles: componentPermissions[componentNames.pageToolbox],
    requiresAuthentication: true,
  },
  name: 'Toolbox',
}

export default exportObj
